import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment/moment';
import { useContext } from 'context/main';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { api_axios } from '../../api';
import { level_info_dict, pay_method_dict, priceHelper } from '../../helpers';
import FaQ from '../../component/Faq';
import { MODALS } from 'context/modal';
import { ModalContext } from 'context/modal';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: black;
    font-size: 35px;
  }

  @media screen and (max-width: 800px) {
    h1 {
      font-size: 25px;
    }
  }
`;

const OrderWrapper = styled.div`
  width: 800px;
  h2 {
    font-size: 25px;
    margin: 10px;
  }
  @media screen and (max-width: 800px) {
    width: calc(100% - 40px);

    h2 {
      font-size: 20px;
    }
  }
`;

const OrderBox = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 800px) {
    flex-direction: column;
  }
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;

  h3 {
    position: relative;
    margin-top: 20px;
    margin-bottom: 5px;

    span:first-child {
      font-weight: bold;
      background-color: white;
      padding-right: 15px;
      border-left: 5px solid #fdbb00;
      padding-left: 10px;
    }

    span:last-child {
      position: absolute;
      border-top: 1px solid #d0d0d0;
      width: 100%;
      left: 0px;
      top: 15px;
      z-index: -1;
    }
  }
  ul {
    padding: 0px;
    list-style-type: none;
    font-size: 13px;
    margin: 0px;
    li {
      color: gray;
    }
  }

  @media screen and (max-width: 800px) {
    width: auto;
  }
`;

const Expired = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-left: 10px;

  span {
    color: gray;
    font-size: 13px;
  }
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 10px;

  a:visited,
  a:link {
    color: #23327c;
    text-decoration: underline;
    font-size: 18px;
  }
  a:hover {
    cursor: pointer;
    color: #fddb00;
  }
`;
const ListLabel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: end;
  font-weight: 200;
  color: #3f3f3f;
  margin-top: 10px;

  span {
    font-size: 16px;
    color: gray;
    margin-bottom: 5px;
  }
`;

const NoticeBox = styled.div`
  background-color: #d6d6d636;
  padding-bottom: 10px;
`;

const DiconFaq = styled.div``;

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fddb00;
  border: none;
  height: 50px;
  border-radius: 4px;
  padding: 5px 20px;
  box-sizing: border-box;
  font-size: 15px;
  margin-top: 30px;
  margin-bottom: 20px;
  color: black;

  &:hover {
    cursor: pointer;
  }
`;

class FinishOrderDetail extends Component {
  static contextType = ModalContext;

  type_dict = {
    program: '프로그램',
    membership: '멤버십',
  };

  state = {
    order_type: this.props.match.params.order_type,
    order_no: '',
    order_name: '',
    order_date: '2019-10-26 12:45',
    order_status: '',
    order_price: 0,
    pay_method: 'card',
    vbank_name: '',
    vbank_num: '',
    vbank_date: '',
    vbank_completed_at: '',
  };

  componentDidMount() {
    window.ga('require', 'ecommerce');
    ReactGA.event(EVENTS.program04);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loading === this.props.loading) return;
    if (this.state.order_no && this.props.location.hash === '#moimPreSurvey') return;
    this.UNSAFE_componentWillMount();
  }

  getCouponName = (id) => {
    let couponInfo = this.props.userinfo.coupons.find(function (coupon) {
      return coupon.id === id;
    });

    if (couponInfo) {
      return couponInfo.coupon_name;
    }
  };

  getOrderInfo = () => {
    // let type = this.props.match.params.order_type;
    let muid = this.props.match.params.merchant_uid;
    api_axios('GET', 'users/' + this.props.userinfo.id + '/orders').then((data) => {
      let o_type = this.props.match.params.order_type;
      let selectedData;
      if (o_type === 'program') selectedData = 'participation';
      else if (o_type === 'membership') selectedData = 'membership';

      let this_order = data[selectedData].filter((order) => {
        return order.order.merchant_uid === muid;
      });
      if (this_order.length !== 0) {
        let this_order_name;
        let this_program_type;
        if (o_type === 'program') {
          this_order_name = this_order[0].program_name;
          this_program_type = this_order[0].program_type;
        } else {
          this_order_name = level_info_dict[this_order[0].type].korean + ' 멤버십';
        }

        this.setState({
          order_type: this.props.match.params.order_type,
          order_no: this_order[0].order.merchant_uid,
          order_name: this_order_name,
          program_type: this_program_type,
          order_date: moment(this_order[0].created_at).format('YYYY.MM.DD HH:mm'),
          order_price: this_order[0].order.price,
          pay_method: this_order[0].order.method,
          used_coupon: this_order[0].used_coupon,
          order_status: this_order[0].order.status,
          vbank: this_order[0].order.status,
          vbank_name: this_order[0].order.vbank_name,
          vbank_num: this_order[0].order.vbank_num,
          vbank_date: this_order[0].order.vbank_date,
          vbank_completed_at: this_order[0].order.vbank_completed_at
        }, () => {
          if (window.ga && window.location.search.split('?')[1]) {
            // https://developers.google.com/analytics/devguides/collection/analyticsjs/ecommerce
            const transactionId = this.state.order_no;
            const [type, productId] = transactionId.split('_');
            const name = this.state.order_name;
            const price = this.state.order_price;
            window.ga('ecommerce:addTransaction', {
              id: transactionId,
              revenue: price,
            });
            window.ga('ecommerce:addItem', {
              id: transactionId,
              name,
              sku: `${type}_${productId}`,
              category: type,
              price,
              quantity: 1,
            });
            window.ga('ecommerce:send');
          }
        });

        if (window.location.search.split('?')[1] && window.location.search.split('?')[1].split('=')[1] === 'clubjoyce') {
          const [, dispatch] = this.context;
          dispatch({
            key: MODALS.moimPreSurvey,
            data: {
              userName: this.props.userinfo.username,
            },
          });
        }

      } else {
        this.setState({
          order_info: 'NO ORDER INFO',
        });
      }
    });
  };

  getDigitalcontentsDue() {
    const diconLayout = (date) => {
      return (
        <>
          <NoticeBox>
            <ListWrapper>
              <ListLabel>
                <span>
                  디지털콘텐츠는 온라인 강연장에서 시청하실 수 있어요! 디지털콘텐츠가 처음이라면? 아래 정보로 강연장에 로그인 하세요 🙂
                </span>
                <a href="http://bit.ly/3cYLjrA" target="_blank">
                  온라인 강연장으로 이동
                </a>
              </ListLabel>
            </ListWrapper>
            <ListWrapper>
              <ListLabel>로그인ID</ListLabel>
              <b>{this.props.userinfo.email}</b>
            </ListWrapper>
            <ListWrapper>
              <ListLabel>초기 비밀번호</ListLabel>
              <b>hey{this.props.userinfo.phone_number.slice(-4)}</b>
            </ListWrapper>
            <ListWrapper>
              <ListLabel>이용기한</ListLabel>
              <b>{date}</b>
            </ListWrapper>
            <ListWrapper>
              <ListLabel>유의사항</ListLabel>
              <span>① 디지털 콘텐츠는 인터넷 익스플로러(IE) 브라우저에서는 시청이 불가합니다.</span>
              <span>② 디지털 콘텐츠와 디콘 패스 환불은 결제일로부터 7일 이내, 온라인 강연장(teachable)에 미로그인 시에만 가능합니다.</span>
              <span>③ 영상 다운로드는 불가하며, 스트리밍(시청)만 가능합니다.</span>
              <span>④ 초기 비밀번호는 처음 온라인 강연장에 로그인하실 때 이용하실 정보입니다. 자세한 내용은 아래 FAQ를 참고해 주세요.</span>
            </ListWrapper>
          </NoticeBox>
        </>
      );
    };
    let orderDate = '';
    // orderDate
    if (this.state.vbank_completed_at === null) {
      orderDate = this.state.order_date;
    } else {
      orderDate = this.state.vbank_completed_at;
    }
    // member && order before 2021-01-01
    if (this.props.userinfo.membership_now && moment(orderDate).format('YYYY-MM-DD') < '2021-01-01') {
      return diconLayout(this.props.userinfo.membership_now.end_at.substring(0, 10) + ' 11:59pm');
    }
    // order after 2021-01-01 (180days -> 365days from 2021-04-30)
    else {
      if (
        moment(orderDate).format('YYYY-MM-DD') > '2021-01-01' &&
        moment(orderDate).add('365', 'd').format('YYYY.MM.DD') > moment().format('YYYY.MM.DD')
      ) {
        return diconLayout(moment(orderDate, 'YYYY.MM.DD').add(12, 'months').format('YYYY.MM.DD') + ' 11:59pm');
      }
      // expired
      return (
        <>
          <Expired>
            <b>이용 기간이 종료되었습니다.</b>
            <span>* 정확한 이용 기간에 대한 내용은 아래 FAQ에서 확인하실 수 있습니다.</span>
          </Expired>
        </>
      );
    }
  }

  UNSAFE_componentWillMount() {
    if (this.props.userinfo.id) {
      this.getOrderInfo();
    }
  }

  render() {
    return (
      <Wrapper>
        <h1>{this.type_dict[this.state.order_type]} 신청 내역</h1>
        <OrderWrapper className={this.state.order_info !== 'NO ORDER INFO' ? 'hide orderFinishPaper' : 'orderFinishPaper'}>
          {this.state.order_info === 'NO ORDER INFO' && (
            <h2
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
                textAlign: 'center',
                margin: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              주문 정보가 없습니다.
            </h2>
          )}
        </OrderWrapper>
        <OrderWrapper className={this.state.order_info === 'NO ORDER INFO' ? 'hide orderFinishPaper' : 'orderFinishPaper'}>
          <h2>
            감사합니다. <br />
            {this.props.userinfo.username ? this.props.userinfo.username : '고객'} 님
            {this.state.order_status === 'paid' && '의 주문이 결제되었습니다. '}
            {this.state.order_status === 'ready' && '의 주문이 접수되었습니다.'}
            {this.state.order_status === 'cancelled' && '의 주문이 취소되었습니다.'}
            <br />
            <span>
              {this.state.pay_method === 'vbank' &&
                this.state.order_status === 'ready' &&
                '아래의 가상계좌로 입금해주셔야 최종적으로 주문이 완료처리됩니다.'}
            </span>
          </h2>
          <OrderBox>
            <InfoBox>
              <h3>
                <span>주문자 정보</span>
                <span />
              </h3>
              <ListWrapper>
                <ListLabel>이름</ListLabel>
                <b>{this.props.userinfo.username}</b>
              </ListWrapper>
              <ListWrapper>
                <ListLabel>이메일</ListLabel>
                <b>{this.props.userinfo.email}</b>{' '}
              </ListWrapper>
              <ListWrapper>
                <ListLabel>연락처</ListLabel>
                <b>{this.props.userinfo.phone_number}</b>{' '}
              </ListWrapper>
            </InfoBox>
            <InfoBox>
              <h3>
                <span>주문 정보</span>
                <span />
              </h3>
              <ListWrapper>
                <ListLabel>주문번호</ListLabel>
                <b>{this.state.order_no}</b>{' '}
              </ListWrapper>
              <ListWrapper>
                <ListLabel>주문항목</ListLabel>
                <b>{this.state.order_name}</b>{' '}
              </ListWrapper>
              <ListWrapper>
                <ListLabel>주문일시</ListLabel>
                <b>{this.state.order_date}</b>{' '}
              </ListWrapper>
              <ListWrapper>
                <ListLabel>결제방법</ListLabel>
                <b>{pay_method_dict[this.state.pay_method].korean}</b>
              </ListWrapper>
              {this.state.vbank_completed_at !== null && (
                <ListWrapper>
                  <ListLabel>입금완료일시</ListLabel>
                  <b>{moment(this.state.vbank_completed_at).format('YYYY.MM.DD HH:mm')}</b>
                </ListWrapper>
              )}
              <ListWrapper>
                {this.state.pay_method === 'vbank' && this.state.order_status === 'ready' && (
                  <Fragment>
                    <ListWrapper>
                      <ListLabel>입금계좌</ListLabel>{' '}
                      <b>
                        {this.state.vbank_name} {this.state.vbank_num}
                      </b>
                    </ListWrapper>
                    <ListWrapper>
                      <ListLabel>입금기한</ListLabel> <b>{moment(this.state.vbank_date).format('YYYY.MM.DD HH:mm')}</b>
                    </ListWrapper>
                  </Fragment>
                )}
                {this.state.used_coupon && (
                  <ListWrapper>
                    <ListLabel>사용쿠폰</ListLabel>
                    <b>{this.getCouponName(this.state.used_coupon)}</b>
                  </ListWrapper>
                )}
              </ListWrapper>
              <ListWrapper>
                <ListLabel>총계</ListLabel>
                <b>{priceHelper(this.state.order_price)}원</b>{' '}
              </ListWrapper>
            </InfoBox>
          </OrderBox>
          {this.state.program_type === 'digitalcontents' && this.state.order_status === 'paid' && (
            <OrderBox>
              <InfoBox>
                <h3>
                  <span>디지털 콘텐츠 정보</span>
                  <span />
                </h3>
                {this.getDigitalcontentsDue()}
                {/** FAQ **/}
                <DiconFaq>
                  <h3>FAQ</h3>
                  <div>
                    <FaQ type="digitalcontents" />
                  </div>
                </DiconFaq>
              </InfoBox>
            </OrderBox>
          )}
          <Link to="/mypage">
            <Button>마이페이지로 가기</Button>
          </Link>
        </OrderWrapper>
      </Wrapper>
    );
  }
}

export default useContext(FinishOrderDetail);
