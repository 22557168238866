import React from 'react';
import styled from 'styled-components';
import { useModalContext } from 'context/modal';

const Wrapper = styled.div`
  background-color: #ffffff;
  width: 283px;
  padding: 24px 22px;
  whitespace: 'pre-wrap';
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  font-family: Noto Sans KR;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: #1a1a1a;
`;
const Button = styled.button`
  margin-top: 24px;
  width: 100%;
  height: 48px;

  font-size: 14px;
  font-weight: bold;
  color: #1a1a1a;
  border: 0px;
  border-radius: 4px;
  background: #fddb00;
  color: #000000;
  cursor: pointer;
`;
const SignInModal = () => {
  const [modalState, , hide] = useModalContext();
  const { errorText = '회원가입에 문제가 발생하였습니다. 관리자에게 문의하세요.' } = modalState.data;

  console.log('ERROR TEXT', errorText);
  return (
    <>
      <Wrapper>
        {errorText.split('&nbsp;').map((item) => {
          return (
            <div>
              {item}
              <br />
            </div>
          );
        })}
        <Button onClick={() => hide()}>확인</Button>
      </Wrapper>
    </>
  );
};

export default SignInModal;
