import React from 'react';
import styled from 'styled-components';
import { Avatar } from '@material-ui/core';
import ReactGA from 'react-ga';
import { EVENTS } from 'block/LinkGA';
import { base64 } from 'helpers';
import { useHistory } from 'react-router-dom';

const CardWrapper = styled.div`
  width: calc(100%);

  @media screen and (min-width: 900px) {
    width: 280px;
  }

  @media screen and (max-width: 900px) {
    width: 270px;
  }
`;

const Card = styled.article`
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 4px;
  background-color: #f8f8f8;

  @media screen and (max-width: 935px) {
  }
`;

const CategoryChip = styled.div`
  margin-bottom: 8px;
  color: #23327c;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 7px;
  margin-left: 1px;
`;

const Question = styled.header`
  margin-bottom: 3px;
  font-size: 16px;
  line-height: 1.6em;
  height: 4.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  color: #444444;
  span {
    font-size: 16px;
    font-weight: bold;
  }
`;

const AuthorWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: 9px;

  .MuiAvatar-root {
    width: 30px;
    height: 30px;
  }
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 12px 0px 0px;
  border-radius: 50%;
  transition: all 150ms ease-out;

  &:hover {
    cursor: pointer;
    background-color: #f0f0f0;
    border-radius: 4px;
  }
`;

const AuthorImage = styled(Avatar)`
  margin-right: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
`;

const AuthorName = styled.span`
  font-size: 16px;
  display: flex;

  span:first-child {
    font-weight: bold;
    font-size: 14px;
    margin: 0px 1px 0px 1px;
    color: #333333;
  }

  span:last-child {
    color: #333333;
    margin: 0px 3px 0px 3px;
    font-size: 14px;
  }
`;

const HeartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeartCount = styled.span`
  font-size: 11px;
  color: #a2a2a2;
  margin-top: -5px;
  font-weight: bold;
`;

const HeartButton = styled.span`
  font-size: 25px;
  color: #a2a2a2;
  line-height: 1.2em;

  &:hover {
    cursor: pointer;
  }
`;

const handleClickGoToQna = (id, history) => {
  const encodedString = base64.urlEncode(id);
  ReactGA.event({
    ...EVENTS.feed01,
    label: '메인',
  });
  history.push({
    pathname: '/profile/' + encodedString,
    search: '?tab=qna',
  });
};

const QnaCard = ({ item, categoryName }) => {
  const history = useHistory();
  const { data } = item;

  return (
    <CardWrapper key={item.id}>
      <Card>
        <CategoryChip>{categoryName}</CategoryChip>
        <Question>
          <span>Q. </span> {data.title}
        </Question>
        {data.author && (
          <AuthorWrapper>
            <Author onClick={() => handleClickGoToQna(data.author.id, history)}>
              <AuthorImage src={data.author.profile_url} alt="펠로우-사진" />
              <AuthorName>
                <span>{data.author.username}</span> <span> 님의 답변 보러가기</span>
              </AuthorName>
            </Author>
            {/* <HeartWrapper>
              <HeartButton>♡</HeartButton>
              <HeartCount>0</HeartCount>
            </HeartWrapper> */}
          </AuthorWrapper>
        )}
      </Card>
    </CardWrapper>
  );
};

export default QnaCard;
