import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FinishOrderDetail from 'component/FinishOrder/FinishOrderDetail';
import '../Home/Landing.scss';
import './FinishOrder.scss';

const FinishOrder = ({ match }) => {
    return (
      <main>
        <Switch>
            <Route exact path={`${match.url}`} component={FinishOrderDetail}/>
            <Route exact path={`${match.url}/:order_type/:merchant_uid`} component={FinishOrderDetail}/>
        </Switch>
      </main>
    );
  }
  export default FinishOrder;