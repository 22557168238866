import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import WistiaPlayer from 'component/WistiaPlayer';
import { useModalContext } from 'context/modal';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  width: 100%;
  padding: 12px;
  border-radius: 10px;

  .section {
    display: flex;
    flex-direction: column;
    padding-top: 12px;

    .top {
      margin-bottom: 20px;
      display: flex;

      h3 {
        margin: 0px;
        vertical-align: middle;
        flex: 1 1 auto;
      }

      .closeBtn {
        margin: 0px;
        color: grey;
        display: inline-flex;
        vertical-align: middle;
        background-color: transparent;
        width: 32px;
        height: 32px;
        flex: 0 0 auto;
      }
    }

    .desc {
      p {
        font-size: 14px;
        color: #666;
      }
    }
  }

  @media screen and (min-width: 768px) {
    width: 80%;
    .section {
      .top .closeBtn {
        color: black;
      }
    }
  }
`;

const LecturePreviewModal = () => {
  const [modalState, , hide] = useModalContext();
  const { hostId, name = '' } = modalState.data;

  return (
    <Wrapper>
      <div className="section">
        <div className="top">
          <h3>미리보기</h3>
          <IconButton edge="end" size="medium" aria-label="close" className="closeBtn" onClick={() => hide()}>
            <Close />
          </IconButton>
        </div>
        <WistiaPlayer className="player" hostId={hostId} autoPlay={true} />
        <div className="desc">
          <p>{name}</p> 
        </div>
      </div>
    </Wrapper>
  );
};

export default LecturePreviewModal;
