import React from 'react';
import styled from 'styled-components';
import { useModalContext } from 'context/modal';

const ButtonHj = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-weight: bold;
  color: #3c3c3c;
  background-color: ${(props) => {
    return props.type === 'yellow' ? '#fddd00' : '#ffffff';
  }};
  box-sizing: border-box;
  border: ${(props) => {
    return props.type === 'yellow' ? 'none' : '0.3px solid #ccc';
  }};
  border-radius: 4px;
  font-size: 15px;
  margin-top: 10px;
  &:hover {
    cursor: 'pointer';
  }
`;

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 30px;
  border-radius: 10px;
  width: 340px;
  height: auto;
  box-sizing: border-box;
  text-align: center;

  display: flex;
  flex-direction: column;

  .section {
    .content {
      h4 {
        margin: 0.5em 0;
        text-align: center;
      }
      p {
        font-size: 18px;
        margin: 0px 0px 20px 0px;
      }
      .desc {
        font-size: 15px;

        margin-bottom: 20px;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        b {
          color: #444444;
        }
      }
    }
  }
`;

const WebinarInModal = () => {
  const [modalState, , hide] = useModalContext();

  const handleClickOkay = () => {
    hide();
  };

  return (
    <Wrapper>
      <div className="section">
        <div className="content">
          <p>
            <b>웨비나 시작 안내</b>
          </p>
          <p className="desc">
            <div>
              입장은 웨비나 시작 1시간 전부터 가능해요. <br /> 💪 열심히 준비하고 있으니 조금만 기다려주세요!
            </div>
          </p>
        </div>

        <ButtonHj type="yellow" onClick={handleClickOkay}>
          확인
        </ButtonHj>
      </div>
    </Wrapper>
  );
};

export default WebinarInModal;
