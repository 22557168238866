import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import Flicking from '@egjs/react-flicking';
import SectionCard from './SectionCard';

const MIN_WIDTH = 900;

const Wrapper = styled.div`
  background-color: #fff;
  display: relative;
  padding: 0px 0px 0px 15px;

  .flexContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  @media screen and (min-width: 935px) {
    padding: 6px 0px 6px 20px;

    .flexContainer {
      padding-right: 20px;
    }

    .flexContainer > div {
      margin-bottom: 20px;
    }
  }
`;

const SectionList = ({ items = [] }) => {
  const isDesktop = useMediaQuery(`(min-width:${MIN_WIDTH}px)`, {
    noSsr: true,
  });

  if (items.length === 0) {
    return <Wrapper />
  }

  const listItems = items
    .map((item) => <SectionCard className="listItem" key={item.id} item={item} />);

  return (
    <Wrapper>
      {isDesktop ? (
        <div className="flexContainer">{listItems}</div>
      ) : (
        <Flicking moveType={{ type: 'snap', count: 1 }} hanger={'0%'} anchor={'0%'} gap={20} zIndex={0} collectStatistics={false}>
          {listItems}
        </Flicking>
      )}
    </Wrapper>
  );
};

export default SectionList;
