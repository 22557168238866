import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
  width: 100%;
  opacity: 1;
  outline: none;
  padding: 14px 20px;
  font-size: 14px;
  font-weight: bold;
  border: 0px;
  text-decoration: none;
  background: ${props => props.buttonBackground};
  color: ${props => props.buttonColor};
  cursor: pointer;

  :nth-child(1) {
    margin: 12px 6px 12px 12px;
    border: 1px solid #e6e6e6;
  }

  :nth-child(2) {
    margin: 12px 12px 12px 6px;
  }

  &:hover {
    background: ${props => props.buttonBackground};
    box-shadow: 2px 4px 20px #e9e9e9;
    transform: translateY(-1px);
  }
`;

const Container = styled.div`
  background-color: #fff;
  position: relative;
  overflow: visible;

  h4 {
    font-size: 15px;
    font-weight: 700;
    color: rgb(27, 28, 29);
    margin: 0px;
  }

  p {
    margin: 6px 0px 0px 0px;
    font-size: 13px;
    line-height: 1.3em;
    color: rgb(133, 138, 141);
  }
  
  .titleContainer {
    padding: 12px 12px 0px 12px;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    button {
      border-radius: 4px;
      box-sizing: border-box;
    }
  }
`;

const Wrapper = styled.div`
  padding: 12px;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 4px;
  }
`;

export default (props) => {
  const { title, desc, handlePastHistory, handleSubscribe } = props;

  return (
    <Wrapper>
      <Container>
        <div className="titleContainer">
          <h4>
            {title}
          </h4>
          <p>{desc}</p>
        </div>
        <div className="buttonContainer">
          <Button buttonBackground="#fff" buttonColor="#000" onClick={() => handlePastHistory()}>
            지난 뉴스레터 보기
          </Button>
          <Button buttonBackground="#fddd00" buttonColor="#000" onClick={() => handleSubscribe()}>
            가입하고 구독하기
          </Button>
        </div>
      </Container>
    </Wrapper>
  )
};