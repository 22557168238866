import React, { Component } from 'react';
import Iamport from 'react-iamport';
import { Button } from '@heyjoyce/vibe';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { api_axios } from '../api';

class MyIamport extends Component {
  constructor(props) {
    super(props);
    this.handleNext = this.handleNext.bind(this);
  }

  handleNext = async (impId, orderId) => {
    if (!impId) {
      window.alert('결제에 불편을 드려 죄송해요 contact@heyjoyce.com 으로 문의해주세요.');
      return this.props.handleClose();
    }
    if (this.props.handlePrev && typeof this.props.handlePrev === 'function') {
      this.props.handlePrev();
    }
    try {
      // after completion payment iamport of the details to validate and update the order status
      await api_axios('POST', 'pay/', {
        imp_uid: impId,
      });
      return this.props.handleNext(orderId);
    } catch (err) {
      console.log(err);
    }
    return window.alert('결제에 불편을 드려 죄송해요 contact@heyjoyce.com 으로 문의해주세요.');
  };

  componentDidMount() {
    window.payCallback = this.handleNext;
  }

  render() {
    const { type, programInfo: program, userinfo, price, coupon_id = null } = this.props;

    const name = program.name;
    const imp = process.env.REACT_APP_IMP_CODE;
    const param = {
      pg: this.props.pg,
      pay_method: this.props.payMethod,
      merchant_uid: this.props.transactionId,
      name: name,
      amount: price,
      buyer_email: userinfo.email,
      buyer_name: userinfo.username,
      buyer_tel: userinfo.phone_number,
      buyer_addr: '',
      buyer_postcode: '',
      custom_data: {
        user_id: userinfo.id,
        coupon_id,
      },
    };

    return (
      <Iamport
        identificationCode={imp}
        params={param}
        onFailed={(err) => {
          if (type === 'membership') {
            ReactGA.event({
              ...EVENTS.membership03,
              label: err && err.error_msg ? err.error_msg : '결제취소',
            });
          } else {
            if (this.props.handleClose) {
              this.props.handleClose();
            }
          }
        }}
        onSuccess={async (res) => {
          if (res.success) {
            if (type === 'membership') {
              ReactGA.event({
                ...EVENTS.membership04,
                label: param.name,
              });
            }
            return this.handleNext(res.imp_uid, res.merchant_uid);
          }
          return this.handleNext();
        }}
        jqueryLoaded={false}
        render={(renderProps) => {
          let onClick = renderProps.onClick;
          if (window.ReactNativeWebView) {
            onClick = () => {
              const params = {
                imp,
                param,
              };
              const paramsToString = JSON.stringify(params);
              window.ReactNativeWebView.postMessage(paramsToString);
            };
          }
          return (
            <Button
              className="buttonPay"
              appearance="secondary"
              label="결제하기"
              onClick={onClick}
              isDisabled={this.props.disabled}
            />
          );
        }}
      />
    );
  }
}

export default MyIamport;
