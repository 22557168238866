import { useState, useEffect } from 'react';
import { api_axios } from '../api';

export const useMerchantUid = ({ productId, productType }) => {
  const [loading, setLoading] = useState(true);
  const [merchantUid, setMerchantUid] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await api_axios('POST', 'orders/', {
          product_id: productId,
          product_type: productType,
        });
        const merchantUid = response.merchant_uid;
        setMerchantUid(merchantUid);
        setLoading(false);
      } catch (error) {
        if (error.response.status === 400) {
          return;
        }
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [loading, merchantUid];
};