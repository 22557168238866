import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons/faHeart';
import memberImg from 'images/emoji/clinking_glasses.png';
import fellowImg from 'images/emoji/woman_tipping_hand.png';
import boardImg from 'images/emoji/memo.png';
import coachingImg from 'images/emoji/briefcase.png';
import { ROUTES, EVENTS, LinkGA } from 'block/LinkGA';

const Wrapper = styled.div`
  padding: 32px 30px 18px;
  position: relative;
  background-color: #fff;

  p {
    margin: 0px;
    padding: 0px;

    span {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      vertical-align: top;
    }
  }
`;

const MenuArea = styled.article`
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    justify-content: space-around;
  }
`;

const Icon = styled(LinkGA)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px 0 0;
  border-radius: 8px;
  border: none;
  cursor: pointer;

  .emoji {
    font-size: 28px;
    height: 46px;
    img {
      width: 38px;
    }
  }

  span {
    color: #495056;
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    letter-spacing: -0.4px;
  }

  i {
    position: relative;
    top: -72px;
    right: -16px;
    width: 6px;
    height: 6px;
    svg {
      color: #ff4e36;
    }
  }
`;

const VerticalMenu = () => {
  return (
    <Wrapper>
      <MenuArea>
        <Icon path={ROUTES.member} event={{ ...EVENTS.community00, label: 'vertical' }}>
          <span className="emoji" role="img" aria-label="clinking_glasses">
            <img src={memberImg} alt="member" />
          </span>
          <span>멤버</span>
          <i />
        </Icon>
        <Icon path={ROUTES.fellow} event={{ ...EVENTS.fellow01, label: 'vertical' }}>
          <span className="emoji" role="img" aria-label="woman-tipping-hand">
            <img src={fellowImg} alt="fellow" />
          </span>
          <span>펠로우&nbsp;</span>
          <i />
        </Icon>
        <Icon path={ROUTES.board} event={{ ...EVENTS.community01, label: 'vertical' }}>
          <span className="emoji" role="img" aria-label="memo">
          <img src={boardImg} alt="board" />
          </span>
          <span>게시판</span>
          <i />
        </Icon>
        <Icon path={ROUTES.feed} event={{ ...EVENTS.feed02, label: 'vertical' }}>
          <span className="emoji" role="img" aria-label="briefcase">
            <img src={coachingImg} alt="coaching" />
          </span>
          <span>커리어상담</span>
          <i>
            <FontAwesomeIcon icon={faHeart} />
          </i>
        </Icon>
      </MenuArea>
    </Wrapper>
  );
};

export default VerticalMenu;
