import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';
import { Emoji } from 'block/Emoji';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 10px;

  .section {
    display: flex;
    flex-direction: column;
    #content {
      display: flex;
      flex-direction: column;
      #title {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        #welcome {
          display: flex;
        }
        .closeBtn {
          margin: 0px;
          padding: 0px;
          color: grey;
          display: inline-flex;
          vertical-align: middle;
          background-color: transparent;
          width: 30px;
          height: 30px;
          flex: 0 0 auto;
        }
      }
    }

    p {
      margin: 0px;
      flex: 1 1 auto;
    }

    #sub {
      color: grey;
      font-size: 13px;
    }

    .desc {
      margin-top: 20px;

      .confirmBtn {
        width: 100%;
        padding: 10px 0px;
        background: #fddd00;

        span {
          font-weight: bold;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 65%;
  }
`;

const JoinModal = () => {
  const history = useHistory();
  const [modalState, , hide] = useModalContext();
  const { username, goBack } = modalState.data;
  return (
    <Wrapper>
      <div className="section">
        <div id="content">
          <div id="title">
            <p id="welcome">
              <b>
                {username} 님,
                <br />
                회원가입을 축하합니다 <Emoji emoji={'tada'} size={20} />
              </b>
            </p>
            <IconButton
              edge="end"
              size="medium"
              aria-label="close"
              className="closeBtn"
              onClick={() => {
                history.push(goBack.location ? goBack.location : '/');
                return hide();
              }}
            >
              <Close />
            </IconButton>
          </div>
          <p id="sub">
            여자들의 커리어 문제해결 플랫폼, 헤이조이스에 오신 것을 환영합니다
            <br />
            이제 멤버에게 제공되는 재밌고 유용한 헤이조이스 뉴스레터를 한 달에 두 번 받아보실 수 있어요!
            <br />
            프로필 완성하고 커리어 문제 함께 해결해요!
          </p>
        </div>
        <div className="desc">
          <Button
            onClick={() => {
              ReactGA.event({
                ...EVENTS.profile02,
                label: 'join',
              });
              hide();
              return history.push({
                pathname: '/mypage/edit-profile',
                state: { isNewbie: true, goBack: goBack.location ? goBack.location : '/' },
              });
            }}
            className="confirmBtn"
          >
            내 프로필 보기
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default JoinModal;
