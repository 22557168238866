import React from 'react';
import { useParams } from "react-router-dom";
import useAxios from 'hooks/useAxios';
import { base64 } from 'helpers';
import Header from 'block/Header';
import FellowContainer from './FellowContainer';

const FellowPage = () => {
  const { fellow_id } = useParams();

  const { loading, data } = useAxios({
    method: 'GET',
    url: `fellows/${base64.urlDecode(fellow_id)}/`,
  });

  if (loading) {
    return <div />;
  }

  return (
    <>
      <Header
        title={`${data.username} | 헤이조이스`}
        description={`${data.company} ${data.job} ${data.username} ${data.introduce}`}
        image={data.profile_image}
      />
      <FellowContainer userinfo={data} />
    </>
  );
};

export default FellowPage;
