import React from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useSessionContext } from 'context/main';
import { Emoji } from 'block/Emoji';
import { useHistory } from 'react-router-dom';
import { EVENTS } from 'block/LinkGA';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import moment from 'moment';

const WelcomeThreadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  padding: 25px 0px;

  button:hover {
    cursor: pointer;
  }

  button:focus {
    outline: none;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    padding: 15px 0px 40px;
  }
`;

const WelcomeMessageWrapper = styled.div`
  padding: 0px 85px 0px 25px;
  font-size: 18px;
  font-weight: bold;

  p {
    margin: 0px 0px 0px 10px;
  }

  img {
    width: 200px;
  }

  @media screen and (max-width: 768px) {
    margin-bottom: 16px;
    padding: 0px 15px;

    p {
      font-size: 16px;
    }

    img {
      width: 180px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-right: 35px;

  @media screen and (max-width: 768px) {
    padding: 0px 25px;
  }

  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  bottom: 16px;
  left: 30px;
  background-color: transparent;
  border: none;
  color: gray;
  text-decoration: underline;

  @media screen and (max-width: 768px) {
    right: 20px;
    bottom: 10px;
    left: unset;
  }
`;

const MessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
`;

const Message = styled.span`
  color: #616161;
  margin-bottom: 12px;
`;

const ThreadButton = styled.button`
  display: flex;
  border: none;
  background-color: #fddb00;
  padding: 8px 12px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  width: 100%;
  font-size: 15px;
  color: #444444;
  border-radius: 4px;

  div:first-child {
    display: flex;
    align-items: center;
  }

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const ThreadButtonText = styled.span`
  margin: 0px 0px 1px 6px;
  font-weight: bold;
`;

const StyledArrowIcon = styled(ArrowForwardIosIcon)`
  color: white;
  width: 20px;
  height: 20px;
`;

const WelcomeThread = ({ handleClickClose }) => {
  const history = useHistory();
  const [, userinfo] = useSessionContext();
  const { nickname, coupons } = userinfo;
  const welcomeCoupon = coupons.find((i) => i.coupon_name.includes('헤이조이스 가입 환영'));

  const handleClickLanding = (path = '/', eventLabel = '') => {
    ReactGA.event({
      ...EVENTS.main12,
      label: eventLabel,
    });
    history.push(path);
  };

  const handleClickLink = (link = '/', eventLabel = '') => {
    ReactGA.event({
      ...EVENTS.main12,
      label: eventLabel,
    });
    window.open(link);
  };

  return (
    <WelcomeThreadWrapper>
      <CloseButton onClick={handleClickClose}>제가 직접 둘러볼게요</CloseButton>
      <WelcomeMessageWrapper>
        <p>
          <Emoji emoji="wave" size={26} style={{ verticalAlign: 'middle' }} animation /> {nickname} 님, 헤이조이스에 <br /> 조인하신 것을
          환영해요!
        </p>
        {/**<img src={WelcomeThreadImage} alt="its-hejo-time" />**/}
      </WelcomeMessageWrapper>
      <ButtonWrapper>
        <MessageWrapper>
          <>
            {welcomeCoupon && !welcomeCoupon.program_id && (
              <Message>{`${nickname} 님께 선물한 웰컴 쿠폰이 ${moment(welcomeCoupon.expire_at).diff(
                moment(),
                'days'
              )}일 후면 사라져요!`}</Message>
            )}
          </>
        </MessageWrapper>
        <ThreadButton onClick={() => handleClickLanding('/program/event', '이벤트')}>
          <div>
            <Emoji emoji="alarm_clock" size={20} /> <ThreadButtonText>지금 신청 가능한 프로그램은?</ThreadButtonText>
          </div>
          <StyledArrowIcon />
        </ThreadButton>
        <ThreadButton onClick={() => handleClickLanding('/board/free/post/353', '게시판')}>
          <div>
            <Emoji emoji="raised_hands" size={20} /> <ThreadButtonText>멤버 게시판에 첫 인사를 남겨 주세요</ThreadButtonText>
          </div>
          <StyledArrowIcon />
        </ThreadButton>
        <ThreadButton onClick={() => handleClickLanding('/community/member', '멤버프로필')}>
          <div>
            <Emoji emoji="women_holding_hands" size={20} /> <ThreadButtonText>다른 멤버들의 프로필을 확인해 보세요</ThreadButtonText>
          </div>
          <StyledArrowIcon />
        </ThreadButton>
        <ThreadButton style={{ marginBottom: 0 }} onClick={() => handleClickLink('https://bit.ly/2RGRQRX', '사용설명서')}>
          <div>
            <Emoji emoji="bulb" size={20} /> <ThreadButtonText>헤이조이스 사용 설명서로 200% 활용 하기</ThreadButtonText>
          </div>
          <StyledArrowIcon />
        </ThreadButton>
      </ButtonWrapper>
    </WelcomeThreadWrapper>
  );
};

export default WelcomeThread;
