import { Avatar, Paper } from '@material-ui/core/';
import moment from 'moment/moment';
import 'pure-react-carousel/dist/react-carousel.es.css';
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { api_axios } from '../../api';
import './Notice.scss';
import { youtubeParser } from '../../helpers';

class NoticeDetail extends Component {
    state = {
        notice_detail: {
            writer: '',
            subject: '',
            content: ''
        }
    }

    getHTML(content) {
        if(content){
            content = youtubeParser(content);
        }

        return (
            <div className="content" dangerouslySetInnerHTML={
                { __html: content }}>
            </div>
        );
    }

    componentDidMount() {
        api_axios('get', 'notices/' + this.props.match.params.notice_id + '/')
            .then(data => {
                this.setState(
                    {
                        notice_detail: data
                    }
                )
            })
            .catch(e => console.log("ERROR", e));
    }

    render() {
        return (
            <main className="NoticeDetail">
                
                <Paper className="NoticePaper">
                    <div className="contentHeader">
                        <Link to="/notice">
                            공지사항
                        </Link>
                        /                        
                        <span>{this.state.notice_detail.subject}</span>                        
                    </div>
                    
                    <h1>
                        {this.state.notice_detail.subject}
                    </h1>
                    <div className="writer">
                        <Avatar alt={this.state.notice_detail.writer}>{this.state.notice_detail.writer[0]}</Avatar>                        
                        <div style={{marginLeft:8}}>
                            {this.state.notice_detail.writer}
                            <div className="createdDate">
                                {moment(this.state.notice_detail.created_at).fromNow()}
                            </div>
                        </div>
                    </div>
                    

                    {this.getHTML(this.state.notice_detail.content)}

                    <div className="pagination">
                        <Link to="/notice">목록</Link>
                    </div>
                </Paper>
            </main>
        )
    }
}

export default NoticeDetail