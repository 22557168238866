((d, s, id, cb) => {
  const element = d.getElementsByTagName(s)[0];
  const fjs = element;
  let js = element;

  js = d.createElement(s);
  js.id = id;
  js.src = `https://code.jquery.com/jquery-1.12.4.min.js`;
  fjs.parentNode.insertBefore(js, fjs);
  js.onload = cb;
})(document, 'script', 'iamport-jquery', () => {
  ((d, s, id, cb) => {
    const element = d.getElementsByTagName(s)[0];
    const fjs = element;
    let js = element;

    js = d.createElement(s);
    js.id = id;
    js.src = `https://cdn.iamport.kr/js/iamport.payment-1.1.5.js`;
    fjs.parentNode.insertBefore(js, fjs);
    js.onload = cb;
  })(document, 'script', 'iamport-jssdk', () => {
    const impInstance = window.IMP;
    impInstance.init(process.env.REACT_APP_IMP_CODE);
  });
});

export default function (handler) {
  const impInstance = window.IMP;
  if (window.ReactNativeWebView) {
    const params = {
      imp: process.env.REACT_APP_IMP_CODE,
    };
    const paramsToString = JSON.stringify(params);
    window.ReactNativeWebView.postMessage(paramsToString);
  } else {
    // docs: https://docs.iamport.kr/tech/mobile-authentication#add-library
    // and admin console: https://admin.iamport.kr/certifications
    impInstance.certification({}, (rsp) => {
      if (rsp.success) {
        handler(rsp.imp_uid);
      } else {
        alert(rsp.error_code + ' - 본인 인증 시스템에 문제가 생겼어요. 헤이조이스 상담채널로 문의해주세요!');
      }
    });
  }
}
