import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Invitation from './Invitation';
import Invited from './Invited';

const InvitationRouter = ({ match }) => {
  return (
    <main role="main">
      <Switch>
        <Route exact path={`${match.url}`} component={Invitation} />
        <Route exact path={`${match.url}/:inviter_code`} component={Invited} />
      </Switch>
    </main>
  );
};
export default InvitationRouter;
