import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Avatar } from '@material-ui/core';
import { listFeeds as listFeedsQuery } from 'graphql/queries';
import { FEED_TYPE } from 'graphql/constants';
import styled from 'styled-components';
import FeedActions from 'container/Feed/FeedActions';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: auto;
  margin-top: 13px;

  .titleBox {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-size: 21px;
      font-weight: bold;
      margin: 4px 0px;
    }

    .subTitle {
      font-size: large;
      margin: 0px;
      padding: 10px 0 20px 0;
    }
  }

  .qnaList {
    width: 100%;
    .qnaBox {
      height: auto;
      width: 500;
      font-size: 17px;
      border-top: 1px solid #cccccc;
      margin-bottom: 20px;

      .q {
        display: flex;
        flex-direction: row;
        padding: 7px 7px 15px 5px;
        width: auto;
        color: #363636;

        span {
          font-weight: bold;
          font-size: 22px;
          color: #fddb00;
          margin-right: 10px;
        }

        div {
          margin-top: 5px;
          word-break: keep-all;
        }

        button {
          border: none;
          background-color: transparent;
          height: fit-content;
          margin-top: 3px;

          svg {
            width: 35px;
            height: 35px;
            color: gray;
          }

          &:hover {
            cursor: pointer;
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .titleBox {
      justify-content: center;

      .subTitle {
        padding: 10px;
      }
    }
    .qnaList {
      .qnaBox {
        height: auto;
        .q {
          font-size: medium;
        }
        .a {
          font-size: medium;
        }
      }
    }
  }
`;

const AnswerWrapper = styled.div`
  padding: 10px 16px 16px;
  background-color: #f7f7f7;
  border-radius: 4px;

  .text {
    position: relative;
    line-height: 1.6em;
    height: 1.6em;
    overflow: hidden;
    width: calc(100% - 40px);

    & > * {
      line-height: 1.6em;
    }

    & > *:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      word-wrap: break-word;
      line-height: 1.6em;
      height: 1.6em;
      margin-top: 0px;
      margin-right: 10px;
    }

    & > *:last-child {
      margin-bottom: 0px;
    }

    &.opened {
      height: auto;
      overflow: visible;
      line-height: 1.6em;
      width: calc(100%);

      & > *:first-child {
        display: block;
        overflow: visible;
        text-overflow: unset;
        line-height: 1.6em;
        margin-right: 0px;
        height: auto;
      }
    }
  }

  .dot {
    text-align: center;
  }

  @media screen and (max-width: 768px) {
    font-size: medium;
  }
`;

const AuthorContent = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 12px;
  justify-content: space-between;
`;

const Author = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 3px 12px 0px 0px;
  border-radius: 50%;
  height: 29px;
`;

const AuthorImage = styled(Avatar)`
  margin-right: 5px;
  border-radius: 50%;
  width: 25px;
  height: 25px;
`;

const AuthorName = styled.span`
  font-size: 16px;
  display: flex;

  span:first-child {
    font-weight: bold;
    font-size: 14px;
    margin: 0px 1px 0px 1px;
    color: #333333;
  }

  span:last-child {
    color: #333333;
    margin: 0px 3px 0px 3px;
    font-size: 14px;
  }
`;

const AnswerContent = styled.div`
  position: relative;
  display: flex;
`;

const TextButton = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: bold;
  color: gray;
  margin-top: 2px;
  width: 50px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    background-color: #0000000a;
  }
`;

const CloseTextButton = styled(TextButton)`
  font-size: 14px;
  padding: 3px 0px;
`;

const ShowMoreTextButton = styled(TextButton)`
  margin-top: 1px;
`;

const Answer = ({ userid }) => {
  const [answers, setAnswers] = useState({});

  const { data, loading, error } = useQuery(
    gql`
      ${listFeedsQuery}
    `,
    {
      variables: {
        filter: {
          type: FEED_TYPE.qna,
          user_id: userid,
        },
        page: 1,
        limit: 10,
      },
    }
  );

  useEffect(() => {
    if (data) {
      setAnswers(
        data.listFeeds.items.map((i) => {
          return { id: i.id, isOpened: false };
        })
      );
    }
  }, [data]);

  if (loading) {
    return <div />;
  }

  if (error) {
    return <div />;
  }

  const { listFeeds } = data;

  function openCloseToc(index) {
    let newArr = [...answers];
    answers[index].isOpened = !answers[index].isOpened;
    setAnswers(newArr);
  }

  return (
    <Wrapper>
      <div className="qnaList">
        {listFeeds.items &&
          listFeeds.items.map((qna, index) => {
            const { data, reactions } = qna;
            const isOpened = answers[index] && answers[index].isOpened;
            return (
              <div className="qnaBox" key={`qna${index}`}>
                <div className={'q'} id={'q' + index}>
                  <div>{data && data.title}</div>
                </div>
                <AnswerWrapper>
                  <AuthorContent>
                    <Author>
                      <AuthorImage src={data.author.profile_url} alt="펠로우-사진" />
                      <AuthorName>
                        <span>{data.author.username}</span> <span> 님의 답변</span>
                      </AuthorName>
                    </Author>
                    {isOpened && <CloseTextButton onClick={() => openCloseToc(index)}>접기</CloseTextButton>}
                  </AuthorContent>
                  <AnswerContent>
                    <div
                      className={`text ${isOpened ? 'opened' : ''}`}
                      id={'a' + index}
                      dangerouslySetInnerHTML={{ __html: data && data.body }}
                    />
                    {!isOpened && <ShowMoreTextButton onClick={() => openCloseToc(index)}>더보기</ShowMoreTextButton>}
                  </AnswerContent>
                  {reactions && <FeedActions id={qna.id} reactions={reactions} />}
                </AnswerWrapper>
              </div>
            );
          })}
      </div>
    </Wrapper>
  );
};

export default Answer;
