import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { CardMedia, useMediaQuery } from '@material-ui/core';
import { priceHelper, getDiscountRate } from '../../helpers';
import { Label, styles } from '@heyjoyce/vibe';
import { ReactGA, EVENTS } from 'block/LinkGA';

const DEFAULT_WIDTH = '270px';
const DEFAULT_HEIGHT = '160px';

const StyledMedia = styled(CardMedia)`
  height: ${(props) => (props.height ? props.height : DEFAULT_HEIGHT)};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
  overflow: hidden;
  transition: transform 0.3s ease;

  @media screen and (min-width: 935px) {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
  }
`;

const StyledActionArea = styled.div`
  width: 100%;
  display: block;
  text-align: inherit;
  background-color: #eee;
  position: relative;
  text-decoration: none;
  align-self: center;
  overflow: hidden;
  border-radius: 4px;

  @media screen and (min-width: 935px) {
    width: ${(props) => (props.width ? props.width : DEFAULT_WIDTH)};
    height: ${(props) => (props.height ? props.height : DEFAULT_HEIGHT)};
  }

  &:hover {
    ${StyledMedia} {
      transform: scale(1.05);
    }
  }

  span {
    margin-right: 6px;
    padding: 2px 6px;
    font-size: 14px;
    text-align: center;
    vertical-align: top;
    border-radius: 3px;
    font-weight: 700;
    white-space: nowrap;
    position: absolute;
    left: 8px;
    bottom: 8px;
  }
`;

const FlickerItem = styled.div`
  width: ${(props) => (props.width ? props.width : DEFAULT_WIDTH)};
  border: 0px;
  box-shadow: none;
  overflow: visible;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
  padding-bottom: 17px;

  .description {
    display: flex;
    flex-direction: column;
    h3 {
      max-height: 60px;
      overflow: hidden;
      margin: 14px 0px 6px 0px;
      white-space: break-spaces;
    }
    p {
      height: 50px;
      font-size: 16px;
      overflow: hidden;
      white-space: break-spaces;
      margin: 2px 0px 14px 0px;
      color: gray;
    }
  }

  @media screen and (max-width: 935px) {
    padding-bottom: 17px;

    .price,
    .description,
    .date {
      width: ${(props) => (props.width ? props.width : DEFAULT_WIDTH)};
      align-self: flex-start;
    }
  }
`;

const BlogCard = (props) => {
  const history = useHistory();
  const { item: blogDetail, width } = props;
  const { id, data } = blogDetail;
  const { title, body, thumbnail } = data;

  const responsiveWidth = width + 'px';
  const responsiveHeight = Math.floor(width * 0.59) + 'px';

  // move to each blog
  const handleClick = () => {
    const path = `/blog/${id}`;
    ReactGA.event({
      ...EVENTS.blog02,
      label: id,
    });
    return (window.location.href = path);
  };

  var bodyText = body.replace(/(<([^>]+)>)/gi, '');

  return (
    <FlickerItem key={id} width={responsiveWidth} onClick={() => handleClick()}>
      <StyledActionArea width={responsiveWidth} height={responsiveHeight}>
        <StyledMedia component="div" image={thumbnail} title={title} height={responsiveHeight} />
      </StyledActionArea>

      <div className="description">
        <h3>{title}</h3>
        <p>{bodyText}</p>
      </div>
    </FlickerItem>
  );
};

export default BlogCard;
