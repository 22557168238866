import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { EVENTS, ReactGA } from 'block/LinkGA';

import { useModalContext } from 'context/modal';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 30px;
  border-radius: 10px;
  width: 270px;
  height: auto;

  .section {
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      flex-direction: column;
      p {
        font-size: 18px;
        margin: 0px 0px 20px 0px;
      }
      .desc {
        font-size: 15px;
        margin-bottom: 20px;
        text-align: center;
      }
      .button {
        background-color: #fddb00;
        font-size: 15px;
        font-weight: bold;
        padding: 10px 20px;
      }
    }
  }
  @media screen and (max-width: 320px) {
    padding: 20px;
  }
`;

const PackageOrderModal = () => {
  const history = useHistory();

  const [modalState, , hide] = useModalContext();
  const { productName = '성장 패키지' } = modalState.data;

  return (
    <Wrapper>
      <div className="section">
        <div className="content">
          <p>
            <b>{`<${productName}>`} 구매가 완료되었습니다!</b>
          </p>
          <p className="desc">
            결제내역은 이메일과 카카오톡을 통해,
            <br />
            쿠폰은 마이페이지에서 확인하실 수 있습니다.
          </p>
          <Button
            className="button"
            onClick={() => {
              hide();
              ReactGA.event(productName.includes('콘조이스') ? EVENTS.conjoyce10 : EVENTS.package03);
              return history.push('/program/event');
            }}
          >
            쿠폰 사용하러 가기
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default PackageOrderModal;
