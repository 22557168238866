import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSessionContext } from 'context/main';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';
import KakaoImage from '../../images/icon-share-kakaotalk.png';
import TextImage from '../../images/icon-share-textcopy.png';
import CheckImage from '../../images/check.png';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 24px;
  border-radius: 10px;

  .section {
    display: flex;
    flex-direction: column;

    .top {
      margin-bottom: 12px;
      display: flex;

      h3 {
        margin: 0px;
        flex: 1 1 auto;
      }

      .closeBtn {
        margin-left: 6px;
        color: grey;
        display: inline-flex;
        vertical-align: middle;
        background-color: transparent;
        width: 32px;
        height: 32px;
        flex: 0 0 auto;
      }
    }
  }
`;

const Icon = styled.img`
  width: ${({ size }) => (size ? `${size}px` : '18px')};
  height: ${({ size }) => (size ? `${size}px` : '18px')};
  object-fit: scale-down;
  margin-right: 8px;
`;

const Button = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-width: 150px;
  border: 1px solid black;
  padding: 10px 12px;
  background-color: white;
  width: 100%;

  &:hover {
    cursor: pointer;
  }

  &:not(:first-child) {
    margin-left: 15px;
  }

  @media screen and (max-width: 768px) {
    bottom: 23px;
  }
`;

const Label = styled.span`
  font-size: 1em;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => (props.hasDesc ? '20px' : '0px')};
  justify-content: space-between;
  margin-bottom: 3px;
`;

const ShareModal = () => {
  const [isCopied, setIsCopied] = useState(false);
  const [modalState, , hide] = useModalContext();
  const [, userinfo] = useSessionContext();

  const { parentContainer, copiedText, kakaoInfo, Description } = modalState.data;

  useEffect(() => {
    if (!window.Kakao.isInitialized()) {
      window.Kakao.init('15f3748d5d6e9101168402871567efc0');
    }
    return function cleanUp() {
      window.Kakao.cleanup();
    };
  }, []);

  const handleClickTextCopy = () => {
    setIsCopied(true);

    if (parentContainer) {
      ReactGA.event({
        ...(parentContainer === 'MAIN' ? EVENTS.main09 : EVENTS.program06),
        label: '텍스트',
      });
    }

    var tempElem = document.createElement('textarea');
    tempElem.value = copiedText;
    document.body.appendChild(tempElem);
    tempElem.select();
    document.execCommand('copy');
    document.body.removeChild(tempElem);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleClickKakaoShare = () => {
    if (parentContainer) {
      ReactGA.event({
        ...(parentContainer === 'MAIN' ? EVENTS.main09 : EVENTS.program06),
        label: '카카오톡',
      });
    }

    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      ...kakaoInfo,
    });
  };

  return (
    <Wrapper>
      <div className="section">
        <div className="top">
          <h3>{parentContainer && parentContainer === 'PROGRAM' ? '지금 보고있는 프로그램' : ''} 공유하기</h3>
          <IconButton edge="end" size="medium" aria-label="close" className="closeBtn" onClick={() => hide()}>
            <Close />
          </IconButton>
        </div>
        {Description && <Description />}
        <Buttons hasDesc={Description !== undefined && Description !== null}>
          {!userinfo.isNative && (
            <Button onClick={handleClickKakaoShare}>
              <Icon src={KakaoImage} alt="kakao-share-icon" />
              <Label>카카오톡 공유하기</Label>
            </Button>
          )}
          <Button onClick={handleClickTextCopy}>
            {isCopied ? (
              <>
                <Icon src={CheckImage} alt="copy-complete-icon" size={15} />
                <Label>복사되었어요</Label>
              </>
            ) : (
              <>
                <Icon src={TextImage} alt="text-copy-icon" />
                <Label>링크 복사</Label>
              </>
            )}
          </Button>
        </Buttons>
      </div>
    </Wrapper>
  );
};

export default ShareModal;
