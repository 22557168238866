import React from 'react';
import { useHistory } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { CarouselProvider, DotGroup, Slide, Slider, ButtonBack, ButtonNext } from 'pure-react-carousel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import styled, { css } from 'styled-components';
import { EVENTS, ReactGA } from 'block/LinkGA';
import './dot.scss';

const MIN_WIDTH = 680;
const MIN_HEIGHT = 86;

const BANNER_WIDTH = {
  mobile: MIN_WIDTH,
  pc: MIN_WIDTH + 255, // 935px * 420px
};

const BANNER_HEIGHT = {
  mobile: MIN_HEIGHT + 14,
  pc: MIN_HEIGHT + 44,
};

const ARROW_BUTTON_CSS = `
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  top: calc(50% - 17px);
  width: 35px;
  height: 35px;
  border-radius: 4px;
  visibility: visible;
  opacity: 1;
  transition: all 0.1s ease-in-out;

  svg {
    color: #ffffffcf;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.4);

    svg {
      color: #ffffff;
    }
  }

  &:disabled {
    visibility: hidden;
    opacity: 0;
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledButtonBack = styled(ButtonBack)`
  left: 20px;
  ${css`
    ${ARROW_BUTTON_CSS}
  `};
`;

const StyledButtonNext = styled(ButtonNext)`
  right: 20px;
  ${css`
    ${ARROW_BUTTON_CSS}
  `};
`;

const StyledBackIcon = styled(ChevronLeftIcon)`
  color: white;
  width: 35px;
  height: 35px;
`;

const StyledNextIcon = styled(ChevronRightIcon)`
  color: white;
  width: 35px;
  height: 35px;
`;

const ItemWrapper = styled.div`
  width: 100%;
  padding: ${(props) => (props.backgroundColor ? '36px' : '0px')};
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'transparent')};
  height: ${(props) => (props.height ? `${props.height}px` : `${BANNER_HEIGHT.mobile}px`)};

  .bannerContents {
    display: ${(props) => (props.hide ? 'none' : 'block')};

    h4 {
      margin: 0px;
      padding: 0px;
      color: #fff;
      max-width: 320px;
      font-size: 26px;
      font-weight: bold;
    }
  }

  @media screen and (max-width: 500px) {
    padding: ${(props) => (props.backgroundColor ? '26px' : '0px')};
  }

  @media screen and (max-width: 420px) {
    padding: ${(props) => (props.backgroundColor ? '20px' : '0px')};
  }

  @media screen and (max-width: 330px) {
    padding: ${(props) => (props.backgroundColor ? '10px' : '0px')};
  }
`;

const BannerItem = styled.a`
  position: relative;
  color: rgb(255, 255, 255);
  text-decoration: none;

  &:hover {
    cursor: ${({ isHasLink }) => (isHasLink ? 'pointer' : 'default')};
  }

  .backgroundWrapper {
    position: relative;
    background-image: ${(props) => (props.backgroundImage ? `url(${props.backgroundImage})` : 'none')};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
  }

  @media screen and (max-width: 900px) {
    .backgroundWrapper {
      background-size: auto 100%;
      background-position: 50% 0%;
    }
  }

  @media screen and (max-width: 360px) {
    .backgroundWrapper {
      background-size: auto 100%;
      background-position: 50% -7%;
    }
  }
`;

const BannerCarlousel = (props) => {
  const { items, type } = props;
  const history = useHistory();
  const isDesktop = useMediaQuery(`(min-width:${MIN_WIDTH}px)`, { noSsr: true });
  const isMiniScreen = useMediaQuery(`(max-width:${360}px)`, { noSsr: true });

  const listItems = items.map((item, index) => {
    const { image_url, link, title } = item;
    const editedLink = link.replace(/^(http)s?:\/\/((.*\.[a-z]{2,3})|(.*)[0-9]{1,4})\//g, '/');

    return (
      <Slide key={index}>
        <BannerItem
          backgroundImage={image_url}
          onClick={() => {
            if (!link) {
              return;
            }
            ReactGA.event({
              ...EVENTS.main02,
              label: editedLink,
            });
            return history.push(editedLink);
          }}
          isHasLink={link && link !== ''}
          alt={title}
        >
          <div className="backgroundWrapper">
            <ItemWrapper
              hide={image_url ? true : false}
              height={isDesktop ? BANNER_HEIGHT.pc : isMiniScreen ? MIN_HEIGHT : BANNER_HEIGHT.mobile}
            >
              <div className="bannerContents">
                <h4 className="bannerTitle">{title}</h4>
              </div>
            </ItemWrapper>
          </div>
        </BannerItem>
      </Slide>
    );
  });

  return (
    <>
      <CarouselProvider
        naturalSlideWidth={isDesktop ? BANNER_WIDTH.pc : BANNER_WIDTH.mobile}
        naturalSlideHeight={isDesktop ? BANNER_HEIGHT.pc : isMiniScreen ? MIN_HEIGHT : BANNER_HEIGHT.mobile}
        isPlaying={true}
        interval={3000}
        totalSlides={listItems.length}
        visibleSlides={1}
        orientation="horizontal"
        isIntrinsicHeight={true}
        infinite={true}
        style={{
          margin: 0,
          width: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        {listItems.length !== 1 && (
          <>
            <StyledButtonBack>
              <StyledBackIcon />
            </StyledButtonBack>
            <StyledButtonNext>
              <StyledNextIcon />
            </StyledButtonNext>
          </>
        )}

        <Slider>{listItems}</Slider>

        {listItems.length !== 1 && (
          <>
            <DotGroup
              style={{
                position: 'absolute',
                bottom: '-5px',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            />
          </>
        )}
      </CarouselProvider>
    </>
  );
};

export default BannerCarlousel;
