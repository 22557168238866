import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useModalContext } from 'context/modal';
import { pay_method_dict } from 'helpers';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 30px;
  border-radius: 10px;
  width: 400px;
  height: auto;
  box-sizing: border-box;

  .section {
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      flex-direction: column;
      h4 {
        margin: 0.5em 0;
        text-align: center;
      }
      p {
        font-size: 18px;
        margin: 0px 0px 20px 0px;
        text-align: center;
      }
      .desc {
        font-size: 15px;
        margin-bottom: 20px;
        word-break: keep-all;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        b {
          color: #444444;
        }
        button {
          border: none;
          background-color: transparent;
          text-decoration: underline;
          color: #2a3384;
          font-weight: bold;
          font-size: 1em;
          &:hover {
            cursor: pointer;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .buttons {
        display: flex;
        flex-direction: row;
        .button {
          background-color: #fddb00;
          font-size: 15px;
          font-weight: bold;
          padding: 8px 20px;
        }
        .button.sub {
          margin-right: 15px;
          background-color: #e7e7e7;
        }
        .button.main {
          flex: 1;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: calc(100% - 40px);
    padding: 22px;
  }
`;

const MyProgramReceiptModal = () => {
  const history = useHistory();
  const [modalState, , hide] = useModalContext();
  const { order } = modalState.data;
  const isCardOrder = order.method === 'card';

  const handleClickAsk = (type) => {
    if (type === 'email') {
      window.location.href = 'mailto:contact@heyjoyce.com';
    } else if (type === 'kakao') {
      window.open('https://pf.kakao.com/_xixmxhLC/chat');
    }
  };

  return (
    <Wrapper>
      <div className="section">
        <div className="content">
          <p>
            <b>{`${pay_method_dict[order.method].emoji} ${pay_method_dict[order.method].korean} 결제 영수증 조회`}</b>
          </p>
          <p className="desc">
            {isCardOrder ? (
              <>
                결제 시 입력하신 이메일로 매출 전표가 발송된 상태입니다. 재확인이 필요한 경우
                <b> 결제 플랫폼 에서 직접 조회 및 출력이 </b>
                가능합니다.
              </>
            ) : (
              <>
                {pay_method_dict[order.method].korean}로 결제하신 경우,
                <button onClick={() => handleClickAsk('email')}>이메일(contact@heyjoyce.com)</button> 혹은{' '}
                <button onClick={() => handleClickAsk('kakao')}>헤이조이스 상담 채널</button>로 문의 주세요
              </>
            )}
          </p>
          <div className="buttons">
            <Button
              className={`button ${isCardOrder ? 'sub' : 'main'}`}
              onClick={() => {
                hide();
                return history.push('/mypage');
              }}
            >
              닫기
            </Button>
            {isCardOrder && (
              <Button
                className="button main"
                onClick={() => window.open('https://danalpay.com/Customer_Support/views_payments_step1.aspx')}
              >
                직접 조회하러 가기
              </Button>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default MyProgramReceiptModal;
