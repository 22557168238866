import React, { useState } from 'react';
import styled from 'styled-components';
import { useSessionContext } from 'context/main';
import { Chip } from '@heyjoyce/vibe';

import Header from 'block/Header';
import BlogCardSection from './BlogCardSection';

const Wrapper = styled.div`
  max-width: 975px;
  padding: 0px 11px;
  margin: 0 auto;
  min-height: 400px;

  background-color: #fff;
  padding: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;

  .flexContainer {
    width: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const DescriptionWrapper = styled.div`
  max-width: 100%;
  padding: 0px;
  margin: 0;
  .pageDescription {
    padding-left: 21px;
    p {
      color: #666666;
    }
  }
`;
const Section = styled.div`
  background-color: #fff;
  padding: 0px 11px;
  position: relative;
  max-width: 935px;
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  align-items: center;

  article {
    display: flex;
    justify-content: center;
    width: 100%;
    flex-wrap: wrap;
    margin: 10px auto 20px 10px;
    paddin-left: 20px;
    justify-content: start;
  }
`;

const StyledChip = styled(Chip)`
  margin-right: 10px;
  margin-bottom: 8px;
`;

const BlogList = ({ match }) => {
  const [, userinfo] = useSessionContext();
  const [selectedTypeId, setSelecteTypeId] = useState(0);

  const blogTypes = ['전체', '브랜드 스토리', '가이드', '인터뷰'];

  const handleClickType = (id) => {
    setSelecteTypeId(id);
  };

  const getBlogTypes = () => {
    return blogTypes.map((type, index) => {
      return (
        <StyledChip
          label={type}
          activeColor={'#393939'}
          borderColor={'rgb(234, 234, 234)'}
          isActive={selectedTypeId === index}
          onClick={() => handleClickType(index)}
        />
      );
    });
  };

  return (
    <Wrapper>
      <Header title={'블로그 | 헤이조이스'} description={'헤이조이스 블로그: 브랜드 스토리, 가이드, 인터뷰'} />
      <DescriptionWrapper>
        <div className="pageDescription">
          <h2>헤이조이스 블로그</h2>
        </div>
      </DescriptionWrapper>
      <Section>
        <article>{getBlogTypes()}</article>
        <div className="flexContainer">
          <BlogCardSection selectedTypeId={selectedTypeId} />
        </div>
      </Section>
    </Wrapper>
  );
};

export default BlogList;
