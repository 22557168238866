import React from 'react';
import styled from 'styled-components';
import moment from 'moment/moment';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useModalContext } from 'context/modal';
import { PDFViewer } from '@react-pdf/renderer';
import { isMobile } from 'react-device-detect';
import ProgramCertificate from 'component/Certificate/ProgramCertificate';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 10px;
  width: calc(80%);
  max-width: 1000px;
  height: auto;
  max-height: calc(80%);
  box-sizing: border-box;
  overflow: scroll;
  display: flex;
`;

const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;

  h3 {
    position: relative;
    margin-top: 0px;
    margin-bottom: 5px;

    span:first-child {
      font-weight: bold;
      background-color: white;
      padding-right: 15px;
      border-left: 5px solid #fdbb00;
      padding-left: 10px;
    }

    span:last-child {
      position: absolute;
      border-top: 1px solid #d0d0d0;
      width: 100%;
      left: 0px;
      top: 15px;
      z-index: -1;
    }
  }
  ul {
    padding: 0px;
    list-style-type: none;
    font-size: 13px;
    margin: 0px;
    li {
      color: gray;
    }
  }
`;

const NoticeBox = styled.div`
  background-color: #d6d6d636;
  padding-bottom: 10px;
`;

const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0px 10px;

  a:visited,
  a:link {
    color: #23327c;
    text-decoration: underline;
    font-size: 18px;
  }
  a:hover {
    cursor: pointer;
    color: #fddb00;
  }
`;
const ListLabel = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: end;
  font-weight: 200;
  color: #3f3f3f;
  margin-top: 10px;

  span {
    font-size: 16px;
    color: gray;
    margin-bottom: 5px;
  }
`;

const DiconInfoModal = () => {
  const history = useHistory();

  const [modalState, , hide] = useModalContext();
  const { userinfo, item } = modalState.data;
  let orderDate = item.order.vbank_completed_at === null ? item.order.created_at : item.order.vbank_completed_at;
  const date =
    // member && order before 2021-01-01
    userinfo.membership_now && moment(orderDate).format('YYYY-MM-DD') < '2021-01-01'
      ? userinfo.membership_now.end_at.substring(0, 10) + ' 11:59pm'
      : // order after 2021-01-01 (180days -> 365days from 2021-04-30)
      moment(orderDate).format('YYYY-MM-DD') > '2021-01-01' &&
        moment(orderDate).add('365', 'd').format('YYYY.MM.DD') > moment().format('YYYY.MM.DD')
      ? moment(orderDate).add(12, 'months').format('YYYY.MM.DD') + ' 11:59pm'
      : moment(orderDate).add(12, 'months').format('YYYY.MM.DD') + ' 11:59pm' + ' (이용 기간이 종료되었습니다.)';

  return (
    <Wrapper>
      <InfoBox>
        <h3>
          <span>디지털 콘텐츠 시청 정보</span>
          <span />
        </h3>
        <NoticeBox>
          <ListWrapper>
            <ListLabel>
              <span>
                VOD는 온라인 강연장에서 시청하실 수 있어요! VOD가 처음이라면? 아래 정보로 강연장에 로그인 하세요 🙂
              </span>
              <a href="http://bit.ly/3cYLjrA" target="_blank">
                온라인 강연장으로 이동
              </a>
            </ListLabel>
          </ListWrapper>
          <ListWrapper>
            <ListLabel>로그인ID</ListLabel>
            <b>{userinfo.email}</b>
          </ListWrapper>
          <ListWrapper>
            <ListLabel>초기 비밀번호</ListLabel>
            <b>hey{userinfo.phone_number.slice(-4)}</b>
          </ListWrapper>
          <ListWrapper>
            <ListLabel>이용기한</ListLabel>
            <b>{date}</b>
          </ListWrapper>
          <ListWrapper>
            <ListLabel>유의사항</ListLabel>
            <span>① 디지털 콘텐츠는 인터넷 익스플로러(IE) 브라우저에서는 시청이 불가합니다.</span>
            <span>② 디지털 콘텐츠와 디콘 패스 환불은 결제일로부터 7일 이내, 온라인 강연장(teachable)에 미로그인 시에만 가능합니다.</span>
            <span>③ 영상 다운로드는 불가하며, 스트리밍(시청)만 가능합니다.</span>
            <span>④ 초기 비밀번호는 처음 온라인 강연장에 로그인하실 때 이용하실 정보입니다.</span>{' '}
            <a
              href="https://heyjoyce.com/policy/faq"
              target="_blank"
              style={{ marginRight: '10px', alignSelf: 'flex-end', color: 'gray', fontSize: '16px' }}
            >
              * 자세한 내용은 FAQ를 참고해 주세요.
            </a>
          </ListWrapper>
        </NoticeBox>
      </InfoBox>
    </Wrapper>
  );
};

export default DiconInfoModal;
