import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import TagManager from 'react-gtm-module';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';

const Wrapper = styled.div`
  display: flex;
  width: 320px;
  background: #ffffff;
  outline: none;
  border-radius: 10px 10px 10px;
  position: absolute;

  .section {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 24px 24px 16px 24px;

    .highlight {
      margin-bottom: 6px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .closeBtn {
        margin-bottom: 2px;
        color: grey;
        background-color: transparent;
        width: 20px;
        height: 20px;
      }
      .title {
        margin-top: 2px;
        width: 100%;
        text-align: center;
        color: #333;
        font-size: 16px;
        font-weight: bold;
      }
    }

    .desc {
      text-align: center;
      p {
        margin: 0px;
        font-size: 12px;
        color: #666;
      }
    }

    .joinBtn {
      padding: 10px 40px;
      background: #fddd00;
      box-shadow: rgba(0, 0, 0, 0.42) 0px 2px 7px 0px;
      font-size: 16px;
      span {
        font-weight: bold;
      }
    }

    .donotBtn {
      margin-top: 10px;
      align-self: center;
      text-decoration: underline;
      color: #666;
      font-size: 13px;
    }
  }
`;

const CouponBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 0px;
`;

const LeftCoupon = styled.div`
  border-radius: 4px 0 0 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 21, 81, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  max-width: 265px;
  height: 100px;
  padding: 12px 26px;
  margin: 0 0 36px 0;
  .name {
    margin: 20px 0 5px;
    color: #333;
    font-weight: bold;
    font-size: 20px;
  }
`;

const RightCoupon = styled.div`
  border-radius: 4px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.05), 0 0 1px 0 rgba(0, 21, 81, 0.05);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  background-color: #f04e44;
  border-left: 3px dashed #ffffff;
  height: 98px;
  padding: 0px 20px;
  margin: 0px;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  writing-mode: vertical-lr;
  text-align: center;
`;

const PromotionModal = () => {
  const history = useHistory();
  const [, , hide] = useModalContext();

  useEffect(() => {
    TagManager.dataLayer({ event: 'optimize.activate' });
  }, []);

  return (
    <Wrapper>
      <div className="section">
        <div>
          <div className="highlight">
            <div className="title">
              <>
                지금 놓치면 사라져요!
                <br />
                <h2>첫 구매 할인 쿠폰</h2>
              </>
            </div>
          </div>
          <div className="desc">
            <CouponBox>
              <LeftCoupon>
                <div className="name">10,000원 할인</div>
              </LeftCoupon>
              <RightCoupon>C O U P O N</RightCoupon>
            </CouponBox>
          </div>
        </div>
        <Button
          onClick={() => {
            ReactGA.event({
              ...EVENTS.signup01,
              label: 'promotion',
            });
            hide();
            return history.push('/join');
          }}
          className="joinBtn"
        >
          가입하고 쿠폰 받기
        </Button>
        <Button
          onClick={() => {
            hide();
            return localStorage.setItem('@main-promotion-disable-modal', true);
          }}
          className="donotBtn"
        >
          괜찮아요 :)
        </Button>
        <div className="desc">
          <p>* 발급일로부터 1개월간 사용 가능합니다</p>
        </div>
      </div>
    </Wrapper>
  );
};

export default PromotionModal;
