import React from 'react';
import { Helmet } from 'react-helmet';
import { Grid, useMediaQuery } from '@material-ui/core';
import '../NewsLetter/NewsLetter.scss';

export default function NewsLetter() {
  const isMobile = useMediaQuery(`(max-width:768px)`);

  return (
    <main>
      <Helmet>
        <title>헤이조이스 - 뉴스레터 구독</title>
        <meta name="description" content="나답게 사는 여자들의 이야기! 한 달에 2번, 헤이조이스 뉴스레터에서 만나 보세요." />
        <meta property="og:title" content="헤이조이스 - 뉴스레터 구독" />
        <meta property="og:description" content="나답게 사는 여자들의 이야기! 한 달에 2번, 헤이조이스 뉴스레터에서 만나 보세요." />
        <script
          type="text/javascript"
          src="https://s3.ap-northeast-2.amazonaws.com/resource.stibee.com/subscribe/stb_subscribe_form.js"
        ></script>
        <link
          rel="stylesheet"
          href="https://s3.ap-northeast-2.amazonaws.com/resource.stibee.com/subscribe/stb_subscribe_form_style.css"
        ></link>
      </Helmet>
      <Grid container spacing={2} justify="center">
        <Grid item xs={12} sm={10}>
          <iframe
            title="뉴스레터"
            className="newsletterIframe"
            src="https://page.stibee.com/subscriptions/50041?groupIds=45698"
            width={isMobile ? '100%' : '640'}
            height={isMobile ? '580' : '820'}
            frameBorder="0"
            marginHeight="0"
            marginWidth="0"
          />
        </Grid>
      </Grid>
    </main>
  );
}
