import Button from "@material-ui/core/Button";
import React from "react";
import { Link } from "react-router-dom";
import Img404 from "../images/404.jpg";

const component404 = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', margin: '0 0 64px' }}>
        <img src={Img404} alt="404" style={{ display: 'block', maxWidth: 320, margin: '32px auto 0' }} />
        <h3 style={{ textAlign: 'center' }}>
            입력하신 URL을 찾을 수 없습니다. <br />
        </h3>
        <Link to="/">
            <Button variant="contained" size="large" color="primary" >
                홈으로
            </Button>
        </Link>
    </div>
);

export default component404;
