import React from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { SEARCH_TYPE } from 'graphql/constants';
import { listFreePrograms as listFreeProgramsQuery } from 'graphql/queries';
import SectionList from './SectionList';

const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 26px;
  background-color: #fff;
  display: relative;

  @media screen and (max-width: 935px) {
    padding-top: 28px;
  }
`;

const SliderTitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
  color: rgb(27, 28, 29);
  margin: 0px 0px 18px 0px;
  padding-left: 20px;

  @media screen and (max-width: 935px) {
    font-size: 18px;
    padding-left: 16px;
  }
`;

const SectionListFreeContainer = () => {
  const { loading, data } = useQuery(
    gql`
      ${listFreeProgramsQuery}
    `,
    {
      variables: {
        filter: {
          type: SEARCH_TYPE.program,
        },
        page: 1,
        limit: 6,
      },
    }
  );

  if (loading || !data) {
    return <div />
  }

  return (
    <Wrapper>
      <SliderTitle>지금 가입하면 무료!</SliderTitle>
      <SectionList items={data.listFreePrograms.items} />
    </Wrapper>
  );
};

export default SectionListFreeContainer;
