import { Avatar, Button, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useContext } from 'context/main';
import { EVENTS, LinkGA } from 'block/LinkGA';
import Header from './Header';
import Signup from './Signup';
import { postMessage } from '../../ReactNativeWebView';
import SearchIcon from '@material-ui/icons/Search';
import SearchBar from 'component/Search/SearchBar';
import styled from 'styled-components';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { SearchBarConsumer } from 'context/searchBar';

import './Nav.scss';

const NAV = {
  COMMUNITY: 'community',
  PROGRAM: 'program',
  USER: 'user',
};

const SearchButton = styled.button`
  border: none;
  background-color: transparent;
  margin-right: 3px;
  margin-top: 8px;

  .MuiSvgIcon-root {
    color: #333;
    font-size: 30px;
  }

  @media screen and (max-width: 768px) {
    margin-right: 1px;
    margin-top: 7px;
    .MuiSvgIcon-root {
      font-size: 29px;
    }
  }
`;

const TriangleBorder = styled.div`
  margin: 0 0 0 -4px;
  border-width: 0 5px 10px;
  border-color: transparent transparent #dbdbdb;
  position: absolute;
  display: block;
  left: 60%;
  bottom: 100%;
  border-style: solid;
  transform: translateX(-0.5px);
  @media screen and (max-width: 1024px) {
    left: 90%;
  }
`;

const Triangle = styled.div`
  margin: 0 0 -1px -4px;
  border-width: 0 4.5px 9px;
  border-color: transparent transparent #fff;
  position: absolute;
  display: block;
  left: 60%;
  bottom: 100%;
  border-style: solid;
  @media screen and (max-width: 1024px) {
    left: 90%;
  }
`;

class MenuListComposition extends React.Component {
  constuctor() {
    this.routeChange = this.routeChange.bind(this);
  }

  routeChange() {
    let path = `newPath`;
    this.props.history.push(path);
  }

  state = {
    open: false,
    left: false,
    selectedMenu: '',
  };

  toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    this.setState({ left: open });
    if (window.sbWidget.widget) {
      if (!this.state.left) {
        window.sbWidget.widget.style.pointerEvents = 'none';
      } else {
        window.sbWidget.widget.style.pointerEvents = '';
      }
    }
  };

  handleClickMenu = (value) => {
    this.setState((state) => ({ selectedMenu: state.selectedMenu === value ? '' : value }));
  };

  handleCloseMenu = (e) => {
    // 클릭한 곳이 메뉴일 경우 return
    if (
      (this.anchorEl2 && this.anchorEl2.contains(e.target)) ||
      (this.anchorEl3 && this.anchorEl3.contains(e.target)) ||
      (this.anchorEl4 && this.anchorEl4.contains(e.target))
    ) {
      return;
    }

    this.setState((state) => ({ selectedMenu: '' }));
  };

  render = () => {
    const { userinfo, updateLevel, history } = this.props;
    const { pathname } = history.location;
    const isSignArea = ['/login', '/join', '/signin', '/signup'].includes(pathname);
    const { selectedMenu } = this.state;

    const logout = async () => {
      postMessage({
        logOut: {
          access: localStorage.getItem('access'),
        },
      });
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      await updateLevel();
      window.location = '/';
    };

    return (
      <>
        <SearchBarConsumer>
          {({ isShowSearchBar, setIsShowSearchBarHandler }) => (
            <>
              <SearchBar />
              <div className="navWrapper">
                <div className="nav">
                  <Header />
                  <nav className="navMenu">
                    {!this.props.loading && !isSignArea && (
                      <SearchButton onClick={() => setIsShowSearchBarHandler(true)}>
                        <SearchIcon />
                      </SearchButton>
                    )}
                    {!this.props.loading && userinfo.level === 'guest' && !isSignArea && <Signup />}
                    {!this.props.loading && userinfo.level !== 'guest' && (
                      <>
                        <LinkGA className="wishlistBtn" path={'/mypage/wishlist'} event={{ ...EVENTS.wishlist01, label: 'top' }}>
                          <FavoriteBorderIcon />
                        </LinkGA>
                        <Button
                          className="navProfile"
                          disableRipple
                          buttonRef={(node) => {
                            this.anchorEl3 = node;
                          }}
                          aria-owns={selectedMenu === NAV.USER ? 'menu-list-grow3' : undefined}
                          aria-haspopup="true"
                          onClick={() => this.handleClickMenu(NAV.USER)}
                        >
                          {userinfo.profile_image !== undefined ? (
                            <Avatar className="avatar" alt={userinfo.username} src={userinfo.profile_image}>
                              {userinfo.username[0]}
                            </Avatar>
                          ) : (
                            <Avatar className="avatar" alt={userinfo.username} src={userinfo.profile_image}>
                              {userinfo.username}
                            </Avatar>
                          )}
                          {selectedMenu === NAV.USER ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
                        </Button>

                        <Popper open={selectedMenu === NAV.USER} anchorEl={this.anchorEl3} transition disablePortal>
                          {({ TransitionProps, placement }) => (
                            <Grow
                              {...TransitionProps}
                              id="menu-list-grow3"
                              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                            >
                              <div>
                                <TriangleBorder />
                                <Triangle />
                                <Paper
                                  elevation={0}
                                  variant="outlined"
                                  square
                                  style={{ padding: '0px 8px', borderRadius: '4px', boxShadow: '0 4px 5px 0 rgb(0 0 0 / 15%)' }}
                                >
                                  <ClickAwayListener onClickAway={this.handleCloseMenu}>
                                    <MenuList className="menu">
                                      <LinkGA path="/mypage" event={{ ...EVENTS.profile01, label: '드롭다운메뉴 - 마이페이지 진입' }}>
                                        <MenuItem className="menuText" onClick={this.handleCloseMenu}>
                                          마이페이지
                                        </MenuItem>
                                      </LinkGA>
                                      <LinkGA path="/mypage/wishlist" event={{ ...EVENTS.wishlist01, label: '드롭다운메뉴 - 위시리스트' }}>
                                        <MenuItem className="menuText" onClick={this.handleCloseMenu}>
                                          위시리스트
                                        </MenuItem>
                                      </LinkGA>
                                      <LinkGA path="/notice" event={{ ...EVENTS.mypage02, label: '드롭다운메뉴 - 공지사항' }}>
                                        <MenuItem className="menuText" onClick={this.handleCloseMenu}>
                                          공지사항
                                        </MenuItem>
                                      </LinkGA>
                                      <LinkGA path="/invitation" event={{ ...EVENTS.invitation01, label: '드롭다운메뉴 - 친구초대' }}>
                                        <MenuItem className="menuText" onClick={this.handleCloseMenu}>
                                          친구초대
                                        </MenuItem>
                                      </LinkGA>
                                      <LinkGA
                                        href="https://www.notion.so/heyjoyce/3a25f362cfa647808dffe3b2aa4685db"
                                        target="_blank"
                                        event={{ ...EVENTS.main11, label: '드롭다운메뉴 - 멤버혜택' }}
                                      >
                                        <MenuItem className="menuText" onClick={this.handleCloseMenu}>
                                          멤버혜택
                                        </MenuItem>
                                      </LinkGA>
                                      <Link to="">
                                        <MenuItem className="menuText" onClick={logout}>
                                          로그아웃
                                        </MenuItem>
                                      </Link>
                                    </MenuList>
                                  </ClickAwayListener>
                                </Paper>
                              </div>
                            </Grow>
                          )}
                        </Popper>
                      </>
                    )}
                  </nav>
                </div>
              </div>
            </>
          )}
        </SearchBarConsumer>
      </>
    );
  };
}

export default withRouter(useContext(MenuListComposition));
