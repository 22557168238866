import React from 'react';
import styled from 'styled-components';
import { LinearProgress } from '@material-ui/core';
import { Emoji } from 'block/Emoji';

const Wrapper = styled.main`
  display: flex;
  width: 300px;
  height: 300px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;

  .loadingDesc {
    span {
      font-size: 16px;
      font-weight: bold;
    }
  }
`;

const Loading = ({ emoji, title }) => {
  return (
    <Wrapper>
      <div className="loadingDesc">
        <Emoji emoji={emoji} size={23} style={{ verticalAlign: 'middle' }} />
        <span>{title}</span>
        <LinearProgress />
      </div>
    </Wrapper>
  )
};

export default Loading;
