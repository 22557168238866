import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { EVENTS } from 'block/LinkGA';

const Button = styled.button`
  font-size: 15px;
  position: absolute;
  height: 40px;
  overflow: hidden;
  border: none;
  padding: 0px 25px;
  margin-bottom: 5px;
  right: 25px;
  bottom: 19px;
  background-color: #fddd00;
  font-weight: bold;
  color: black;
  margin-top: 3px;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    position: relative;
    width: 100%;
    height: 40px;
    bottom: 0px;
    right: 0px;
  }
`;

const DescText = styled.span`
  margin-right: 3px;
`;

const Desc = styled.span`
  display: flex;
  align-items: center;
  font-size: 13px;
  color: rgb(125, 125, 125);
  margin: 6px 0px 6px 0px;
`;

const Title = styled.span`
  font-size: 15px;
  font-weight: 700;
  color: rgb(27, 28, 29);
  margin: 5px 0px 0px 0px;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 45px);

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: #fffdec;
  padding: 16px 25px;
`;

const Wrapper = styled.div``;

const BannerInvitation = ({ phrase }) => {
  const history = useHistory();

  const handleClickInvitation = () => {
    if (phrase) {
      ReactGA.event({
        ...EVENTS.invitation01,
        label: '마이페이지 하단',
      });
    } else {
      ReactGA.event({
        ...EVENTS.invitation01,
        label: '메인하단',
      });
    }
    return history.push('/invitation');
  };

  return (
    <Wrapper>
      <Container>
        <TextContainer>
          <Title>친구 초대하면 무제한 쿠폰을 드려요</Title>
          <Desc>
            <DescText>친구에게 10,000원 쿠폰 선물하고 나도 같이 10,000원 쿠폰 받기!</DescText>
          </Desc>
        </TextContainer>
        <Button onClick={handleClickInvitation}>{phrase || '쿠폰 받기'}</Button>
      </Container>
    </Wrapper>
  );
};

export default BannerInvitation;
