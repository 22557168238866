import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';
import { Emoji } from 'block/Emoji';
import { Button } from '@heyjoyce/vibe';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  padding: 30px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  .title {
    display: flex;
    align-self: center;
    span {
      font-size: larger;
      font-weight: bolder;
      word-break: keep-all;
      text-align: center;
    }
  }
  .desc {
    margin-top: 14px;
    display: flex;
    justify-content: center;
    text-align: center;
    word-break: keep-all;
  }

  @media screen and (max-width: 768px) {
    width: 75%;
  }
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const ComeBackModal = () => {
  const history = useHistory();
  const [modalState, , hide] = useModalContext();
  const { username, pathname = '/' } = modalState.data;
  return (
    <Wrapper>
      <div className="title">
        <span>다시 만난 {username} 님, 진심으로 환영합니다💛</span>
      </div>
      <div className="desc">
        <p>
          약 1년 동안 로그인 하지 않아 휴면 계정으로 전환되었으며,
          <br />
          지금 로그인을 통해 휴면 상태가 해지 되었어요!
          <br />
          헤이조이스의 모든 서비스를 정상적으로 이용하실 수 있어요.
        </p>
      </div>
      <StyledButton
        onClick={() => {
          hide();
          return history.push(pathname);
        }}
        appearance="primary"
        label="프로그램 둘러보기"
      />
    </Wrapper>
  );
};

export default ComeBackModal;
