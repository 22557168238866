/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getWebinar = /* GraphQL */ `
  query GetWebinar($id: ID!) {
    getWebinar(id: $id) {
      id
      type
      notice {
        title
        subtitle
        body
        program_name
        program_url
        prequestion_url
      }
      scheduled_at
      is_terminated
      speakers {
        profile_url
        title
        subtitle
        body
      }
      url
      ivs {
        id
        playback_url
      }
      pagecall {
        id
        type
        name
        is_terminated
      }
      sendbird {
        channel_url
        name
        participant_count
        custom_type
      }
      created_at
      updated_at
    }
  }
`;
export const listWebinars = /* GraphQL */ `
  query ListWebinars($filter: TableWebinarFilterInput, $limit: Int, $nextToken: String) {
    listWebinars(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        scheduled_at
        is_terminated
        url
        created_at
        updated_at
      }
      nextToken
    }
  }
`;
export const listPrograms = /* GraphQL */ `
  query ListPrograms($limit: Int) {
    listPrograms(limit: $limit) {
      items {
        id
        type
        name
        register_start_at
        register_end_at
        register_available
      }
    }
  }
`;
export const listFeeds = /* GraphQL */ `
  query ListFeedsQuery($filter: TableFeedFilterInput, $limit: Int, $page: Int) {
    listFeeds(filter: $filter, limit: $limit, page: $page) {
      items {
        id
        type
        tags
        created_at
        updated_at
        category_id
        reactions {
          total
          viewer_has_reacted
        }
        data {
          body
          title
          author {
            id
            profile_url
            username
            company
            job
          }
          thumbnail
        }
      }
      total
    }
  }
`;
export const getFeed = /* GraphQL */ `
  query GetFeed($id: ID!) {
    getFeed(id: $id) {
      id
      type
      tags
      category_id
      updated_at
      created_at
      reactions {
        items {
          id
          content
          author {
            id
            username
            profile_url
          }
        }
        total
        viewer_has_reacted
      }
      data {
        body
        title
        author {
          id
          profile_url
          username
          company
          job
        }
        thumbnail
      }
    }
  }
`;

export const searchFeeds = /* GraphQL */ `
  query SearchFeeds($filter: TableFeedSearchInput, $limit: Int, $page: Int) {
    searchFeeds(filter: $filter, limit: $limit, page: $page) {
      items {
        id
        type
        category_id
        is_pinned
        updated_at
        created_at
        data {
          body
          title
          author {
            id
            profile_url
            username
            company
            job
          }
        }
      }
      total
    }
  }
`;

export const getPreviewPromotion = /* GraphQL */ `
  query GetPreviewPromotion($code: String!, $stage: String!) {
    getPreviewPromotion(code: $code, stage: $stage) {
      id
      code
      stage
      title
      start_at
      end_at
      created_at
      programs {
        discount_price
        id
        name
        original_price
        register_available
        register_end_at
        register_start_at
        type
      }
      contents {
        background_color
        created_at
        id
        order
        snippet
        rich_text
      }
    }
  }
`;

export const getOpenedPromotion = /* GraphQL */ `
  query GetOpenedPromotion($code: String!) {
    getOpenedPromotion(code: $code) {
      id
      code
      stage
      title
      start_at
      end_at
      created_at
      programs {
        discount_price
        id
        name
        original_price
        register_available
        register_end_at
        register_start_at
        type
      }
      contents {
        background_color
        created_at
        id
        order
        snippet
        rich_text
      }
    }
  }
`;

export const getOpenedPromotionCode = /* GraphQL */ `
  query GetOpenedPromotionCode($keyword: String!) {
    getOpenedPromotionCode(keyword: $keyword) {
      code
    }
  }
`;

export const getProgram = /* GraphQL */ `
  query GetProgram($id: ID!) {
    getProgram(id: $id) {
      id
      is_enrolled
      reactions {
        total
        viewer_has_reacted
      }
    }
  }
`;

export const getProgramWithCourse = /* GraphQL */ `
  query GetProgramWithCourse($id: ID!) {
    getProgram(id: $id) {
      id
      is_enrolled
      reactions {
        total
        viewer_has_reacted
      }
      course {
        id
        name
        image_url
        lecture_sections {
          id
          name
          lectures {
            id
            name
          }
        }
        lectures {
          id
          name
          free_preview
          lecture_section_id
          attachments {
            host
            host_id
            audio_type
            thumbnail_url
            name
          }
        }
      }
    }
  }
`;

export const listUserReactions = /* GraphQL */ `
  query ListUserReactions($filter: TableUserReactionInput, $limit: Int) {
    listUserReactions(filter: $filter, limit: $limit) {
      items {
        id
        ... on Program {
          name
          type
          register_start_at
          register_end_at
          original_price
          discount_price
          associate_member_available
          thumbnail_url
        }
      }
    }
  }
`;

export const getProfile = /* GraphQL */ `
  query profile {
    profile {
      id
      username
      stage
      profile_url
      upcoming_webinars {
        items {
          id
          programs {
            id
          }
          scheduled_at
        }
      }
    }
  }
`;

export const search = /* GraphQL */ `
  query Search($filter: TableSearchFilterInput, $limit: Int, $page: Int) {
    search(filter: $filter, limit: $limit, page: $page) {
      items {
        ... on Program {
          id
          name
          associate_member_available
          discount_price
          original_price
          register_available
          register_end_at
          register_start_at
          thumbnail_url
          type
        }
      }
      programTotal
    }
  }
`;

export const listCategories = /* GraphQL */ `
  query ListCategories {
    listCategories {
      items {
        id
        name
      }
    }
  }
`;

export const listFreePrograms = /* GraphQL */ `
  query ListFreePrograms($filter: TableSearchFilterInput, $limit: Int, $page: Int) {
    listFreePrograms(filter: $filter, limit: $limit, page: $page) {
      items {
        ... on Program {
          id
          name
          associate_member_available
          discount_price
          original_price
          register_available
          register_end_at
          register_start_at
          thumbnail_url
          type
        }
      }
    }
  }
`;

export const listScoredPrograms = /* GraphQL */ `
  query ListScoredPrograms($filter: TableSearchFilterInput, $limit: Int, $page: Int) {
    listScoredPrograms(filter: $filter, limit: $limit, page: $page) {
      items {
        ... on Program {
          id
          name
          associate_member_available
          discount_price
          original_price
          register_available
          register_end_at
          register_start_at
          thumbnail_url
          type
        }
      }
    }
  }
`;

export const listInterestedPrograms = /* GraphQL */ `
  query ListInterestedPrograms($filter: TableSearchFilterInput, $limit: Int, $page: Int) {
    listInterestedPrograms(filter: $filter, limit: $limit, page: $page) {
      items {
        ... on Program {
          id
          name
          associate_member_available
          discount_price
          original_price
          register_available
          register_end_at
          register_start_at
          thumbnail_url
          type
        }
      }
    }
  }
`;

export const listFellows = /* GraphQL */ `
  query listFellows($filter: TableSearchFilterInput, $limit: Int, $page: Int) {
    listFellows(filter: $filter, limit: $limit, page: $page) {
      items {
        ... on User {
          id
          username
          email
          company
          job
          profile_url
        }
      }
      memberTotal
    }
  }
`;
