import React from 'react';
import styled from 'styled-components';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { MODALS, useModalContext } from 'context/modal';
import { api_axios } from '../../api';
import * as Sentry from '@sentry/react';

const ButtonHj = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-weight: bold;
  color: #3c3c3c;
  background-color: ${(props) => {
    return props.type === 'yellow' ? '#fddd00' : '#ffffff';
  }};
  box-sizing: border-box;
  border: ${(props) => {
    return props.type === 'yellow' ? 'none' : '0.3px solid #ccc';
  }};
  border-radius: 4px;
  font-size: 15px;
  margin-top: 10px;
  &:hover {
    cursor: 'pointer';
  }
`;

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 18px;
  border-radius: 10px;
  width: 320px;
  height: auto;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  .section {
    .content {
      h4 {
        margin: 0.5em 0;
        text-align: center;
      }
      p {
        font-size: 18px;
        margin: 0px 0px 20px 0px;
      }
      .desc {
        font-size: 15px;

        margin-bottom: 20px;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        b {
          color: #444444;
        }
      }
    }
  }
`;

const MarketingModal = () => {
  const [modalState, dispatch, hide] = useModalContext();
  const { userinfo, setIsMarketing } = modalState.data;

  const handleAgree = () => {
    const marketing = true;
    const marketing_update_date = new Date().toISOString();

    ReactGA.event(EVENTS.wishlist03);
    try {
      api_axios('PATCH', `users/${userinfo.id}/`, { marketing, marketing_update_date }).then(() => {
        setIsMarketing(true);
        hide();
        return dispatch({
          key: MODALS.marketingUpdate,
          data: {
            username: userinfo.username,
            marketing: true,
            marketingUpdateDate: new Date().toISOString(),
          },
        });
      });
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handelCancel = () => {
    hide();
  };

  return (
    <Wrapper>
      <div className="section">
        <div className="content">
          <b>위시리스트 알림 받기</b>
          <p className="desc">
            <div>
              위시리스트에 담긴 프로그램의 알림을 포함하여 헤이조이스가 멤버에게 제공하는 특별 할인 프로모션, 새 프로그램 오픈 알림 등을
              이메일과 문자로 받아보실 수 있어요! 마케팅 정보 수신에 동의하시겠어요?
            </div>
          </p>
        </div>
        <ButtonHj type="yellow" onClick={handleAgree}>
          좋아요
        </ButtonHj>
        <ButtonHj type="white" onClick={handelCancel}>
          싫어요
        </ButtonHj>
      </div>
    </Wrapper>
  );
};

export default MarketingModal;
