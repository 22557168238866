import React from "react";
import { Paper } from "@material-ui/core/";
import {refund_dict} from "./refund_dict";

const Refund = () => {
    return (
        <div>
            <div className="menuHeader">
                <h2>헤이조이스 유료 서비스 환불 규정</h2>
            </div>
            <Paper className="policyPaper">
                <div className="content" dangerouslySetInnerHTML={
                    { __html: refund_dict}}>
                </div>
            </Paper>
        </div>
    );
}

export default Refund;