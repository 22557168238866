import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { gql, useLazyQuery } from '@apollo/client';
import { listFeeds } from 'graphql/queries';
import Skeleton from '@material-ui/lab/Skeleton';
import Flicking from '@egjs/react-flicking';
import { EVENTS, LinkGA } from 'block/LinkGA';
import useCategory from 'hooks/useCategory';
import QnaCard from './QnaCard';
import { FEED_TYPE } from 'graphql/constants';

const MIN_WIDTH = 900;

const Wrapper = styled.div`
  background-color: #fff;
  padding: 12px 0px 38px 20px;
  display: relative;

  .flexContainer {
    display: grid;
    grid-template-rows: repeat(2, 175px);
    grid-template-columns: repeat(3, 1fr);
    row-gap: 40px;
    column-gap: 25px;
    justify-content: space-between;
    margin-top: 10px;
    margin-right: 20px;
  }

  @media screen and (max-width: 935px) {
    margin-bottom: 6px;
    padding: 1px 0px 32px 16px;
  }
`;

const SliderTitle = styled.h3`
  font-size: 22px;
  font-weight: bold;
  color: rgb(27, 28, 29) !important;
  margin: 20px 0px 20px 2px;
  display: flex;

  .emoji-mart-emoji {
    justify-content: center;
    margin-right: 5px;
    margin-top: 2px;
  }

  @media screen and (max-width: 935px) {
    font-size: 18px;

    .emoji-mart-emoji {
      justify-content: center;
      margin-right: 5px;
      margin-top: 0px;
    }
  }
`;

const QnaFlicking = (props) => {
  const isDesktop = useMediaQuery(`(min-width:${MIN_WIDTH}px)`, {
    noSsr: true,
  });
  const categories = useCategory();

  const { title } = props;

  const [queryListFeed, { loading, data, error }] = useLazyQuery(
    gql`
      ${listFeeds}
    `,
    {
      variables: {
        filter: {
          type: FEED_TYPE.qna,
          is_pinned: true,
        },
        limit: 6,
        page: 1,
      },
    }
  );

  useEffect(() => {
    if (categories.length > 0) {
      queryListFeed();
    }
  }, [categories, queryListFeed]);

  const ready = loading || !data;
  if (ready || error) {
    return (
      <Wrapper>
        <SliderTitle>
          {title} &nbsp;
          <span>
            <FontAwesomeIcon icon={faChevronRight} color="darkgray" />
          </span>
        </SliderTitle>
        <Skeleton variant="rect" width={300} height={150} />
      </Wrapper>
    );
  }

  const listItems = data.listFeeds.items.map((item) => {
    const category = categories.find((category) => parseInt(category.id) === item.category_id);
    return <QnaCard key={item.id} item={item} categoryName={category.name} width={isDesktop ? 280 : 270} />;
  });

  return (
    <Wrapper>
      {/** https://fontawesome.com/ */}
      <LinkGA
        path={'/feed/list'}
        event={{
          ...EVENTS.feed02,
          label: '메인섹션',
        }}
      >
        <SliderTitle>
          {title} &nbsp;
          <span>
            <FontAwesomeIcon icon={faChevronRight} color="darkgray" />
          </span>
        </SliderTitle>
      </LinkGA>
      {isDesktop ? (
        <div className="flexContainer">{listItems}</div>
      ) : (
        <Flicking hanger={'0'} anchor={'0'} zIndex={0} gap={20} collectStatistics={false} circular={true}>
          {listItems}
        </Flicking>
      )}
    </Wrapper>
  );
};

export default QnaFlicking;
