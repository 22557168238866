import React from 'react';
import moment from 'moment';
import { useMediaQuery } from '@material-ui/core';
import { useSessionContext, USER_LEVELS } from 'context/main';
import { useModalContext, MODALS } from 'context/modal';

export const PROMOTION_STORAGE_KEY = '@main-app-promotion-exposure-count-v1';

export const usePromotionModal = () => {
  const [, userinfo] = useSessionContext();
  const [, dispatch] = useModalContext();
  const isDesktop = useMediaQuery(`(min-width:640px)`, { noSsr: true });
  const isLoggedIn = userinfo.level === USER_LEVELS.associate;
  const isNewbie = moment() < moment(userinfo.date_joined).add(1, 'months');

  React.useEffect(() => {
    // catch a member's attention to engage
    const preference = localStorage.getItem(PROMOTION_STORAGE_KEY) || '0';
    if (isLoggedIn && !isDesktop && !userinfo.isNative && parseInt(preference) < 2) {
      return dispatch({
        key: MODALS.invitation,
      });
    }
    // please join
    if (!isLoggedIn && !localStorage.getItem('@main-promotion-disable-modal')) {
      return dispatch({
        key: MODALS.promotion,
      });
    }
    // eslint-disable-next-line
  }, []);

  return [isLoggedIn, isNewbie];
};

export const useLazyPromotionModal = () => {
  const [, dispatch, hide] = useModalContext();

  const loadModal = () =>
    dispatch({
      key: MODALS.promotion,
    });

  return [loadModal, hide];
};