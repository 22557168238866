import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import Purchase from './Purchase';
import TopMenu from 'container/Home/TopMenu';
import { MODALS, useModalContext } from 'context/modal';
import { useSessionContext } from 'context/main';
import { EVENTS, ReactGA } from 'block/LinkGA';
import Header from 'block/Header';
import ProgramFlicking from 'container/Program/ProgramFlicking';
import CouponPackageHeader from '../../images/package_header_coupon.png';
import BasicCouponPackageImage from '../../images/package2_coupon_basic_origin.png';
import BoostingCouponPackageImage from '../../images/package2_coupon_boosting_origin.png';
import BenefitDicon from '../../images/benefit-dicon.jpg';
import BenefitEvent from '../../images/benefit-event.jpg';
import BenefitConjoyce from '../../images/benefit-conjoyce.jpg';
import BenefitProject from '../../images/benefit-project.jpg';
import KakaoImage from '../../images/icon-share-kakaotalk.png';
import ChannelTalkImage from '../../images/channelTalk_icon.png';
import EmailImage from '../../images/email-ask-icon.png';
import ReceiptIcon from '../../images/receipt_icon.png';
import CheckImage from '../../images/check.png';
import TextImage from '../../images/icon-share-textcopy.png';
import PackageUseOne from '../../images/package_purchase.png';
import PackageUseTwo from '../../images/package_mypage.png';
import PackageUseThree from '../../images/package_use.png';
import CheckedBox from '../../images/isChecked.png';
import CheckBox from '../../images/isBox.png';

import ChannelService from 'channeltalk/ChannelService';

const DESC_FONT_SIZE = 22;
const SUB_DESC_FONT_SIZE = 20;
const SUB_SUB_DESC_FONT_SIZE = 18;

const HEADER_FONT_SIZE = 35;
const HEADER_DESC_SIZE = 23;

const TITLE_FONT_SIZE = 30;
const SECTTION_TITLE_FONT_SIZE = 25;

const ButtonWrapper = styled.div`
  width: 80%;
  margin: 20px 0px 10px;
`;

const BenefitImage = styled.img`
  max-height: 100%;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
`;
const HowToImageWrapper = styled(ImageWrapper)`
  overflow: initial;
  .image {
    max-height: 100%;
  }
`;
const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Price = styled.div`
  font-size: ${TITLE_FONT_SIZE}px;
  background-color: #ffffff;
  font-weight: normal;
  .del {
    font-weight: normal;
  }
  .salePercent {
    color: #f04e44;
    font-weight: normal;
  }

  @media screen and (max-width: 768px) {
    font-size: ${TITLE_FONT_SIZE - 2}px;
  }
  @media screen and (max-width: 540px) {
    font-size: ${TITLE_FONT_SIZE - 4}px;
  }
  @media screen and (max-width: 425px) {
    font-size: ${TITLE_FONT_SIZE - 6}px;
  }
  @media screen and (max-width: 320px) {
    font-size: ${TITLE_FONT_SIZE - 8}px;
  }
`;

const CouponContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;

  .titleWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    hr {
      position: absolute;
      width: 97%;
      border-style: dashed;
    }
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    &:first-child {
      margin-bottom: 60px;
    }
  }

  .packageTitle {
    font-size: ${TITLE_FONT_SIZE}px;
    font-weight: bold;

    @media screen and (max-width: 540px) {
      font-size: ${TITLE_FONT_SIZE - 2}px;
    }
  }

  .packageDesc {
    font-size: ${DESC_FONT_SIZE}px;
    margin: 20px 0px;
    width: 100%;
    text-align: center;
    flex-direction: column;
    display: flex;

    table {
      margin-left: 30px;
      margin-right: 30px;
    }

    td {
      padding: 3px;
      background-color: ${({ type }) => (type === 'basic' ? '#00595038' : '#2a338438')};

      :first-child {
        background-color: ${({ type }) => (type === 'basic' ? '#00595082' : '#2a33847d')};
        font-weight: bold;
      }
    }
    @media screen and (max-width: 925px) {
      font-size: ${DESC_FONT_SIZE - 2}px;
    }
    @media screen and (max-width: 540px) {
      font-size: ${DESC_FONT_SIZE - 4}px;

      @media screen and (max-width: 375px) {
        font-size: ${DESC_FONT_SIZE - 6}px;
      }
    }
  }
`;

const CouponImage = styled.img`
  width: 100%;
`;

const HeaderImage = styled.img`
  width: 30%;
  align-self: center;

  @media screen and (max-width: 768px) {
    position: absolute;
    width: 30%;
    right: 3%;
    top: 2%;
  }
  @media screen and (max-width: 425px) {
    right: 5%;
  }
  @media screen and (max-width: 375px) {
    top: 1.8%;
  }
`;

const HeaderDesc = styled.div`
  margin-top: 20px;
  font-size: ${HEADER_DESC_SIZE}px;
  p {
    margin-top: 20px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 768px) {
    font-size: ${HEADER_DESC_SIZE - 2}px;
  }
  @media screen and (max-width: 425px) {
    font-size: ${HEADER_DESC_SIZE - 4}px;
  }
  @media screen and (max-width: 375px) {
    font-size: ${HEADER_DESC_SIZE - 6}px;
  }
`;

const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  font-size: ${HEADER_FONT_SIZE}px;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: 33px;
  }
  @media screen and (max-width: 425px) {
    font-size: 30px;
  }
  @media screen and (max-width: 320px) {
    font-size: 23px;
  }
`;

const HeaderWrpper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Section = styled.div`
  max-width: 925px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 60px 0px;

  @media screen and (max-width: 925px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const HeaderSection = styled.div`
  max-width: 925px;
  margin: 0 auto;
  padding: 60px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (max-width: 925px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const CouponWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #ffffff;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Background = styled.div`
  width: 100%;
  background-color: ${({ color }) => (color ? color : '#FDDB00')};
`;

const SectionTitle = styled.div`
  font-size: ${SECTTION_TITLE_FONT_SIZE}px;
  font-weight: bold;

  @media screen and (max-width: 768px) {
    font-size: ${SECTTION_TITLE_FONT_SIZE - 2}px;
  }
  @media screen and (max-width: 425px) {
    font-size: ${SECTTION_TITLE_FONT_SIZE - 4}px;
  }
  @media screen and (max-width: 320px) {
    font-size: ${SECTTION_TITLE_FONT_SIZE - 6}px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const RecommendWrapper = styled(Wrapper)`
  .title {
    font-size: ${SECTTION_TITLE_FONT_SIZE + 10}px;
    font-weight: bold;
    align-self: center;
  }
  .list {
    display: flex;
    flex-direction: column;
    font-size: ${DESC_FONT_SIZE}px;
    margin: 0px auto;
    .image {
      width: 35px;
      height: 35px;
      margin-right: 70px;
    }

    p {
      display: flex;
      width: 100%;
      margin-bottom: 0px;
      align-items: center;
    }
    .dots {
      justify-content: center;
      font-weight: bold;
    }
    .endSentence {
      justify-content: center;
      text-align: center;
      font-size: ${DESC_FONT_SIZE + 4}px;
      display: table-column;
    }
  }

  @media screen and (max-width: 768px) {
    .title {
      font-size: ${SECTTION_TITLE_FONT_SIZE + 8}px;
    }
    .list {
      font-size: ${DESC_FONT_SIZE - 4}px;
      .image {
        width: 25px;
        height: 25px;
        margin-right: 30px;
      }
      .endSentence {
        font-size: ${DESC_FONT_SIZE + 2}px;
      }
    }
  }
  @media screen and (max-width: 425px) {
    .title {
      font-size: ${SECTTION_TITLE_FONT_SIZE + 4}px;
    }
  }
  @media screen and (max-width: 320px) {
    .title {
      font-size: ${SECTTION_TITLE_FONT_SIZE}px;
    }
    .list {
      font-size: ${DESC_FONT_SIZE - 6}px;
      .image {
        width: 25px;
        height: 25px;
        margin-right: 30px;
      }
      .endSentence {
        font-size: ${DESC_FONT_SIZE}px;
      }
    }
  }
`;

const FutureDesc = styled.div`
  margin-top: 10px;
  width: 130px;
  display: flex;
  flex-direction: column;
  font-size: ${SUB_DESC_FONT_SIZE}px;
  align-items: center;
  text-align: center;

  span {
    font-size: ${SUB_SUB_DESC_FONT_SIZE}px;
    color: #7d7d7d;
  }
  @media screen and (max-width: 768px) {
    font-weight: bold;
    font-size: ${SUB_DESC_FONT_SIZE - 2}px;
    span {
      font-size: ${SUB_SUB_DESC_FONT_SIZE - 2}px;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 425px) {
    font-size: ${SUB_DESC_FONT_SIZE - 4}px;
    span {
      font-size: ${SUB_SUB_DESC_FONT_SIZE - 4}px;
    }
  }
`;

const HowToDesc = styled(FutureDesc)`
  width: 250px;
  font-size: ${SUB_SUB_DESC_FONT_SIZE}px;
  font-weight: normal;

  @media screen and (max-width: 768px) {
    font-size: ${SUB_DESC_FONT_SIZE - 2}px;
    font-weight: normal;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 425px) {
    font-size: ${SUB_DESC_FONT_SIZE - 4}px;
    font-weight: normal;
  }
`;

const UseItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 20px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const HowToItem = styled(UseItem)`
  margin: 0px;
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const UseList = styled.div`
  display: flex;
  flex-direction: row;
  margin: 30px auto 0px;
  justify-content: center;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const HowToList = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0px auto 30px;
  justify-content: center;
  flex-wrap: wrap;
`;

const ListContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  font-size: ${SUB_DESC_FONT_SIZE}px;

  span:not(:last-child) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 425px) {
    font-size: ${SUB_DESC_FONT_SIZE - 4}px;
    .graphImage {
      width: 70%;
    }
  }
`;

const ShareButtons = styled.div`
  display: flex;
  flex-direction: row;
  margin: 20px auto 30px;

  @media screen and (max-width: 320px) {
    width: 100%;
  }
`;

const BulkContent = styled.div`
  span {
    margin-top: 15px;
    text-align: center;
  }
  display: flex;
  flex-direction: column;
`;

const BulkAskButtons = styled(ShareButtons)`
  margin-top: 20px;

  @media screen and (max-width: 1024px) {
    margin-top: 20px;
  }
  @media screen and (max-width: 670px) {
    flex-direction: column;
  }
`;

const AskButtons = styled(ShareButtons)`
  display: flex;
  justify-content: center;
`;

const ShareButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  min-width: 150px;
  border: 1px solid black;
  padding: 10px 12px;
  width: 170px;

  .label {
    margin: 0px;
  }

  &:hover {
    cursor: pointer;
  }

  &:not(:last-child) {
    margin-right: 15px;
  }

  @media screen and (max-width: 768px) {
    bottom: 23px;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    min-width: 150px;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
    padding: 8px;
    min-width: 100px;
    &:first-child {
      margin-right: 6px;
    }
  }
`;

const BulkButton = styled(ShareButton)`
  width: 200px;

  @media screen and (max-width: 670px) {
    width: 200px;
    &:not(:last-child) {
      margin-bottom: 15px;
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 200px;
    align-self: center;
  }
`;

const Icon = styled.img`
  width: ${({ size }) => (size ? `${size}px` : '18px')};
  height: ${({ size }) => (size ? `${size}px` : '18px')};
  object-fit: scale-down;
  margin-right: 8px;
`;

const COPIED_TEXT = `성장패키지\r\n6개월간 내 커리어를 위해 투자하겠다는 나와의 약속\r\n[베이직] 2만원 쿠폰 * 4장 = 68,000원 (15% 할인)\r\n[부스팅] 2만원 쿠폰 * 6장 = 99,600원 (17% 할인)\r\nhttps://heyjoyce.com/package`;

const CouponPackage = () => {
  const [, dispatch] = useModalContext();
  const [, userinfo] = useSessionContext();
  const [isCopied, setIsCopied] = useState(false);

  const handlePurchase = () => {
    // 구매완료 후 페이징 작업 부분
    dispatch({
      key: MODALS.packageOrder,
    });
  };

  useEffect(() => {
    window.ga('require', 'ecommerce');

    if (!window.Kakao.isInitialized()) {
      window.Kakao.init('15f3748d5d6e9101168402871567efc0');
    }
    return function cleanUp() {
      window.Kakao.cleanup();
    };
  }, []);

  const handleClickTextCopy = () => {
    setIsCopied(true);

    ReactGA.event({
      ...EVENTS.package04,
      label: '텍스트',
    });

    var tempElem = document.createElement('textarea');
    tempElem.value = COPIED_TEXT;
    document.body.appendChild(tempElem);
    tempElem.select();
    document.execCommand('copy');
    document.body.removeChild(tempElem);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleClickKakaoShare = () => {
    const currentUrl = window.location.href;

    ReactGA.event({
      ...EVENTS.package04,
      label: '카카오톡',
    });

    window.Kakao.Link.sendDefault({
      objectType: 'feed',
      content: {
        title: '성장패키지',
        description: '6개월간 내 커리어를 위해 투자하겠다는 나와의 약속',
        imageUrl: 'https://heyjoyce.com/image/package/package_kakao_thumbnail.png', // PROGRAM.allDays.imageUrl,
        link: {
          webUrl: currentUrl,
          mobileWebUrl: currentUrl,
        },
      },
      buttons: [
        {
          title: '더 알아보기',
          link: {
            mobileWebUrl: currentUrl,
            webUrl: currentUrl,
          },
        },
      ],
    });
  };

  const handleClickAskButton = (type) => {
    if (type === 'email') {
      window.location.href = 'mailto:contact@heyjoyce.com';
    } else if (type === 'talk') {
      ChannelService.showMessenger();
    } else if (type === 'receipt') {
      window.open('https://danalpay.com/Customer_Support/views_payments_step1.aspx', '_blank');
    }
  };

  return (
    <main>
      <Header title={'헤이조이스 - 성장패키지'} description={'6개월간 내 커리어를 위해 투자하겠다는 나와의 약속'} />
      <TopMenu />
      <Background>
        <HeaderSection>
          <HeaderWrpper>
            <HeaderTitle>성장패키지</HeaderTitle>
            <HeaderDesc>
              <p>커리어 성장도 근육 키우듯 꾸준히!</p>
              <p>
                헤이조이스 모든 프로그램에서 사용 가능한 쿠폰을
                <br />
                할인된 가격으로 만나보세요!
              </p>
              <p>6개월 간 헤이조이스와 함께 커리어에 부스터를 달 기회 🎉</p>
            </HeaderDesc>
          </HeaderWrpper>
          <HeaderImage src={CouponPackageHeader} alt="coupon-package-new-header-image" />
        </HeaderSection>
      </Background>
      <Background color={'#ffffff'}>
        <Section>
          <CouponWrapper>
            <CouponContent type={'basic'}>
              <div className="titleWrapper">
                <div className="packageTitle">🌱 베이직 패키지</div>
                <CouponImage src={BasicCouponPackageImage} alt="basic-coupon-package-image"></CouponImage>
                <PriceWrapper>
                  <Price>
                    <del>80,000원</del>
                    <br />
                    <b>
                      → 68,000원&nbsp; <span className="salePercent">(15% 할인)</span>
                    </b>
                  </Price>
                </PriceWrapper>
              </div>
              <div className="packageDesc">
                <table>
                  <tr>
                    <td>구성</td>
                    <td>2만원 할인 쿠폰 4장</td>
                  </tr>
                  <tr>
                    <td>기한</td>
                    <td>구매일 기준 6개월</td>
                  </tr>
                  <tr>
                    <td>방법</td>
                    <td>결제 한 건 당 한 장씩 사용</td>
                  </tr>
                </table>
              </div>
              <ButtonWrapper>
                <Purchase
                  productType={'coupon'}
                  productName={'베이직 패키지'}
                  productId={'P1004'}
                  amount={68000}
                  onPurchase={handlePurchase}
                />
              </ButtonWrapper>
            </CouponContent>
            <CouponContent type={'booster'}>
              <div className="titleWrapper">
                <div className="packageTitle">🚀 부스팅 패키지</div>
                <CouponImage src={BoostingCouponPackageImage} alt="boosting-coupon-package-image"></CouponImage>
                <PriceWrapper>
                  <Price>
                    <del>120,000원</del>
                    <br />
                    <b>
                      → 99,600원&nbsp; <span className="salePercent">(17% 할인)</span>
                    </b>
                  </Price>
                </PriceWrapper>
              </div>
              <div className="packageDesc">
                <table>
                  <tr>
                    <td>구성</td>
                    <td>2만원 할인 쿠폰 6장</td>
                  </tr>
                  <tr>
                    <td>기한</td>
                    <td>구매일 기준 6개월</td>
                  </tr>
                  <tr>
                    <td>방법</td>
                    <td>결제 한 건 당 한 장씩 사용</td>
                  </tr>
                </table>
              </div>
              <ButtonWrapper>
                <Purchase
                  productType={'coupon'}
                  productName={'부스팅 패키지'}
                  productId={'P1003'}
                  amount={99600}
                  onPurchase={handlePurchase}
                />
              </ButtonWrapper>
            </CouponContent>
          </CouponWrapper>
        </Section>
      </Background>
      <Background color={'#f3f3f3'}>
        <Section>
          <RecommendWrapper>
            <div className="title">이런 분들께 추천해요</div>
            <div className="list">
              <p>
                <img className="image" src={CheckedBox} alt="" />
                <span>자기 계발이 하고 싶은데 동기부여가 필요해요.</span>
              </p>
              <p>
                <img className="image" src={CheckedBox} alt="" />
                이미 할인 중인 프로그램도 좀 더 저렴하게 이용하고 싶어요.
              </p>
              <p>
                <img className="image" src={CheckBox} alt="" />
                헤이조이스를 6개월 동안 4번 이상 참여할 수 있을 것 같아요.
              </p>
              <p>
                <img className="image" src={CheckBox} alt="" />
                회사 교육 지원비로 자기 계발 교육을 들을 수 있어요.
              </p>
              <p className="dots">
                .<br />.<br />.
              </p>
              <p className="endSentence">
                하나라도 해당된다면?
                <br />
                <b>최대 20,400원 할인받고 헤조 이용하기!</b>
              </p>
            </div>
          </RecommendWrapper>
        </Section>
      </Background>
      <Background color={'#ffffff'}>
        <Section>
          <Wrapper>
            <SectionTitle>헤이조이스의 모든 프로그램에서 사용할 수 있어요!</SectionTitle>
            <UseList>
              <UseItem>
                <ImageWrapper>
                  <BenefitImage src={BenefitEvent} alt="" />
                </ImageWrapper>
                <FutureDesc>
                  라이브
                  <span>퇴근 후, 경험의 폭을 한 뼘 더 넓히는 시간</span>
                </FutureDesc>
              </UseItem>
              <UseItem>
                <ImageWrapper>
                  <BenefitImage src={BenefitConjoyce} alt="" />
                </ImageWrapper>
                <FutureDesc>
                  콘조이스
                  <span>일과 삶에 영감을 주는 여성들의 이야기</span>
                </FutureDesc>
              </UseItem>
              <UseItem>
                <ImageWrapper>
                  <BenefitImage src={BenefitProject} alt="" />
                </ImageWrapper>
                <FutureDesc>
                  모임
                  <span>각 분야 전문가와 함께 경험의 폭 넓히기</span>
                </FutureDesc>
              </UseItem>
              <UseItem>
                <ImageWrapper>
                  <BenefitImage src={BenefitDicon} alt="" />
                </ImageWrapper>
                <FutureDesc>
                  VOD
                  <span>놓쳐서 아쉬웠던 프로그램! 언제든 다시 보기</span>
                </FutureDesc>
              </UseItem>
            </UseList>
          </Wrapper>
          <br></br>
          <Wrapper>
            <SectionTitle>사용 방법 및 유의 사항</SectionTitle>
            <ListContent>
              <HowToList>
                <HowToItem>
                  <HowToImageWrapper>
                    <img className="image" src={PackageUseOne} alt="" />
                  </HowToImageWrapper>
                  <HowToDesc>
                    <b>①</b>
                    <b>결제 한 건 당 한 장씩 사용할 수 있는</b>
                    쿠폰 패키지를 결제
                  </HowToDesc>
                </HowToItem>
                <HowToItem>
                  <HowToImageWrapper>
                    <img className="image" src={PackageUseTwo} alt="" />
                  </HowToImageWrapper>
                  <HowToDesc>
                    <b>②</b>
                    <b>마이페이지 {'>'} 쿠폰 탭</b>
                    발급 확인
                  </HowToDesc>
                </HowToItem>
                <HowToItem>
                  <HowToImageWrapper>
                    <img className="image" src={PackageUseThree} alt="" />
                  </HowToImageWrapper>
                  <HowToDesc>
                    <b>③</b>
                    <b>쿠폰을 사용하여</b>
                    할인된 가격으로 프로그램 신청
                  </HowToDesc>
                </HowToItem>
              </HowToList>
              <span>✓ 성장패키지는 신용카드 결제만 가능합니다.</span>
              <span>
                ✓ 성장 패키지로 구매한 쿠폰은 온라인 컨퍼런스(콘조이스)부터 라이브, 디지털콘텐츠, 모임까지 모든 프로그램에서 사용하실 수
                있습니다.
              </span>
              <span>✓ 쿠폰 중복 사용은 불가 합니다.</span>
              <span>✓ 성장 패키지의 쿠폰은 유효 기간인 6개월이 지나면 자동 소멸됩니다.</span>
            </ListContent>
          </Wrapper>
        </Section>
      </Background>
      <Background color={'#f3f3f3'}>
        <Section>
          <Wrapper>
            <SectionTitle>환불 규정</SectionTitle>
            <ListContent>
              <span>
                ① 구매일 기준 <b>15</b>일 이내 쿠폰 미사용 시, <b>100%</b> 환불 가능합니다. <br />
                　(쿠폰을 사용했거나, 구매일 기준 15일 초과 시 환불 불가)
              </span>
              <span>② 쿠폰은 타인에게 양도할 수 없습니다.</span>
              <span>
                ③ 환불 문의: <b>contact@heyjoyce.com</b> 혹은 <b>1:1 문의하기</b> 버튼을 눌러 주세요
              </span>
              <AskButtons>
                <ShareButton onClick={() => handleClickAskButton('email')}>
                  <Icon src={EmailImage} alt="email-ask-icon" />
                  메일로 문의하기
                </ShareButton>
                <ShareButton onClick={() => handleClickAskButton('talk')}>
                  <Icon src={ChannelTalkImage} alt="kakao-share-icon" />
                  1:1 문의하기
                </ShareButton>
              </AskButtons>
            </ListContent>
          </Wrapper>
        </Section>
      </Background>
      <Background>
        <Section>
          <Wrapper>
            <SectionTitle style={{ alignSelf: 'center' }}>💸성장 패키지도 회돈내산으로!</SectionTitle>
            <BulkContent>
              <span>
                내 커리어 성장도 회사 돈으로🤭
                <br />
                💳 법인 카드 결제만 가능
              </span>
              <BulkAskButtons>
                <BulkButton onClick={() => handleClickAskButton('email')}>
                  <Icon src={EmailImage} alt="email-ask-icon" />
                  메일로 문의하기
                </BulkButton>
                <BulkButton onClick={() => handleClickAskButton('talk')}>
                  <Icon src={ChannelTalkImage} alt="kakao-share-icon" />
                  1:1 문의하기
                </BulkButton>
                <BulkButton onClick={() => handleClickAskButton('receipt')}>
                  <Icon src={ReceiptIcon} alt="receipt-icon" />
                  결제 영수증 조회하기
                </BulkButton>
              </BulkAskButtons>
            </BulkContent>
          </Wrapper>
        </Section>
      </Background>
      <Background color={'#ffffff'}>
        <Section>
          <SectionTitle>
            성장패키지 구매하고<br></br>
            지금 바로 커리어 성장 시작하자!
          </SectionTitle>
          <div style={{ marginBottom: '6px' }}>
            <ProgramFlicking title="라이브" desc="퇴근 후, 경험의 폭을 한 뼘 더 넓히는 시간" type="event" limit={3} target="_blank" />
          </div>
          <div style={{ marginBottom: '6px' }}>
            <ProgramFlicking
              title="VOD"
              desc="놓쳐서 아쉬웠던 프로그램! 언제든 다시보기"
              type="digitalcontents"
              limit={3}
              target="_blank"
            />
          </div>
          <div style={{ marginBottom: '6px' }}>
            <ProgramFlicking title="모임" desc="스터디와 네트워킹, 우리는 모일수록 잘한다!" type="moim" limit={3} target="_blank" />
          </div>
        </Section>
      </Background>
      <Background color={'#f3f3f3'}>
        <Section>
          <Wrapper>
            <SectionTitle style={{ alignSelf: 'center' }}>공유하기</SectionTitle>
            <ShareButtons>
              <ShareButton onClick={handleClickKakaoShare}>
                <Icon src={KakaoImage} alt="kakao-share-icon" />
                <span className="label">카카오톡 공유하기</span>
              </ShareButton>
              <ShareButton onClick={handleClickTextCopy}>
                {isCopied ? (
                  <>
                    <Icon src={CheckImage} alt="copy-complete-icon" size={15} />
                    <span className="label">복사되었어요</span>
                  </>
                ) : (
                  <>
                    <Icon src={TextImage} alt="text-copy-icon" />
                    <span className="label">텍스트 복사하기</span>
                  </>
                )}
              </ShareButton>
            </ShareButtons>
          </Wrapper>
        </Section>
      </Background>
    </main>
  );
};

export default CouponPackage;
