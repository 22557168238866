import React from 'react';
import styled from 'styled-components';
import { useModalContext } from 'context/modal';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';

const ButtonHj = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-weight: bold;
  color: #3c3c3c;
  background-color: ${(props) => {
    return props.type === 'yellow' ? '#fddd00' : '#ffffff';
  }};
  box-sizing: border-box;
  border: ${(props) => {
    return props.type === 'yellow' ? 'none' : '0.3px solid #ccc';
  }};
  border-radius: 4px;
  font-size: 15px;
  margin: 0;
  &:hover {
    cursor: 'pointer';
  }
`;

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 18px;
  border-radius: 10px;
  width: 300px;
  height: auto;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  .section {
    .content {
      h4 {
        margin: 0.5em 0;
        text-align: center;
      }
      p {
        font-size: 180px;
        margin: 0px 0px 20px 0px;
      }
      svg {
        width: 21px;
        height: 21px;
        padding: 0 3px;
        position: relative;
        top: 5px;
      }
      .desc {
        font-size: 15px;
        margin-top: 15px;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        b {
          color: #444444;
        }
      }
    }
  }
`;

const MarketingUpdateInfoModal = () => {
  const [, , hide] = useModalContext();

  const handleClose = () => {
    hide();
  };

  return (
    <Wrapper>
      <div className="section">
        <div className="content">
          <b>위시리스트에 추가되었습니다!</b>
          <p className="desc">
            <div>위시리스트에 프로그램을 저장했어요!</div>
            <span>상단의</span>
            <span className="img">
              <FavoriteBorderIcon />
            </span>
            <span>버튼을 눌러 언제든지 확인하실 수 있어요.</span>
            {/* <div>저장한 프로그램은 마감 하루 전에 알림을 드릴께요🙏</div> */}
          </p>
        </div>
        <ButtonHj type="yellow" onClick={handleClose}>
          확인
        </ButtonHj>
      </div>
    </Wrapper>
  );
};

export default MarketingUpdateInfoModal;
