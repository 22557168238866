import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSessionContext } from 'context/main';
import { api_axios } from 'api';
import { EVENTS, LinkGA, ReactGA } from 'block/LinkGA';
import { trackFinishedTransaction } from 'helpers';

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 23px;
  font-weight: 600;
  border-radius: 4px;
  background-color: #f04e44;
  color: white;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
  @media screen and (max-width: 320px) {
    font-size: 20px;
  }
`;

const Purchase = ({ className = '', productType, productId, productName, amount, onPurchase, pg = '', payMethod }) => {
  const [, userinfo] = useSessionContext();
  const imp = process.env.REACT_APP_IMP_CODE;

  // manage iamport life-cycle
  useEffect(() => {
    ((d, s, id, cb) => {
      const element = d.getElementsByTagName(s)[0];
      const fjs = element;
      let js = element;

      js = d.createElement(s);
      js.id = id;
      // tslint:disable-next-line:max-line-length
      js.src = `https://code.jquery.com/jquery-1.12.4.min.js`;
      fjs.parentNode.insertBefore(js, fjs);
      js.onload = cb;
    })(document, 'script', 'iamport-jquery', () => {
      ((d, s, id, cb) => {
        const element = d.getElementsByTagName(s)[0];
        const fjs = element;
        let js = element;

        js = d.createElement(s);
        js.id = id;
        // tslint:disable-next-line:max-line-length
        js.src = `https://cdn.iamport.kr/js/iamport.payment-1.1.5.js`;
        fjs.parentNode.insertBefore(js, fjs);
        js.onload = cb;
      })(document, 'script', 'iamport-jssdk', () => {
        const IMP = window.IMP;
        IMP.init(imp);
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (userinfo.level === 'guest') {
    const login_for_order_package = (
      <div className="pay_wrap">
        <LinkGA
          path={'/join'}
          event={{
            ...EVENTS.package02,
            label: '비로그인유저',
          }}
          location={{ pathname: '/package' }}
        >
          <Button className={className} variant="contained" size="large">
            {productName} 구매하기
          </Button>
        </LinkGA>
      </div>
    );
    return login_for_order_package;
  }

  const getMerchantUid = async () => {
    const response = await api_axios('POST', 'orders/', {
      product_id: productId,
      product_type: productType,
    });
    const merchantUid = response.merchant_uid;
    return merchantUid;
  };

  const handleClickSubmit = async () => {
    if (productName && productName.includes('콘조이스')) {
      ReactGA.event({ ...EVENTS.conjoyce09, label: '버튼' });
    }

    const IMP = window.IMP;

    const merchantUid = await getMerchantUid();
    const param = {
      merchant_uid: merchantUid,
      name: productName,
      amount,
      pg: pg,
      pay_method: payMethod,
      buyer_email: userinfo.email,
      buyer_name: userinfo.username,
      buyer_tel: userinfo.phone_number,
      custom_data: {
        user_id: userinfo.id,
      },
    };

    if (window.ReactNativeWebView) {
      if (!window.payCallback) {
        window.payCallback = handleNext;
      }
      const params = {
        imp,
        param,
      };
      const paramsToString = JSON.stringify(params);
      window.ReactNativeWebView.postMessage(paramsToString);
    } else {
      IMP.request_pay(param, handleNext);
    }
  };

  const handleNext = async ({ success, imp_uid, merchant_uid }) => {
    if (success) {
      // send fb event
      trackFinishedTransaction({
        amount: amount,
        productType,
        productId,
        productName: productName,
      });

      // send ga event
      ReactGA.event({ ...EVENTS.package05, label: productName });

      // send ga e-commerce data
      const [type, productId] = merchant_uid.split('_');
      if (window.ga) {
        window.ga('ecommerce:addTransaction', {
          id: merchant_uid,
          revenue: amount,
        });
        window.ga('ecommerce:addItem', {
          id: merchant_uid,
          name: productName,
          sku: `${type}_${productId}`,
          category: type,
          price: amount,
          quantity: 1,
        });
        window.ga('ecommerce:send');
      }

      await api_axios('POST', 'pay/', { imp_uid });
      return onPurchase(merchant_uid);
    }
  };

  return (
    <Button className={className} onClick={handleClickSubmit}>
      {productName} 구매하기
    </Button>
  );
};

export default Purchase;
