import React from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga';
import { Link, withRouter } from 'react-router-dom';
import { EVENTS } from 'block/LinkGA';
import { faFacebook, faInstagram, faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { faBlog } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HeyjoyceLogo from 'images/logo_heyjoyce_w_korean.png';
import ChannelService from 'channeltalk/ChannelService';
import './Footer.scss';

const Wrapper = styled.footer`
  @media screen and (min-width: 935px) {}
`; 

const Footer = (props) => {
  const { location } = props;

  const appstoreUrl = 'https://itunes.apple.com/app/id1492064340';
  const playstoreUrl = 'https://play.google.com/store/apps/details?id=com.heyjoyce.native';
  const blogUrl = `https://${window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native') ? 'm.' : ''}blog.naver.com/heyjoyce_`;
  const webinarPath = '/webinar/';

  if (window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native')) {
    return <></>;
  }

  if (location.pathname.includes(webinarPath)) {
    return <></>;
  }

  const handleClickAppDownload = (e, label) => {
    return ReactGA.event({
      ...EVENTS.main10,
      label,
    });
  };

  const handleClickQuestion = () => {
    ChannelService.showMessenger();
  };

  return (
    <Wrapper>
      <div className="footerWrapper">
        <div>
          <img src={HeyjoyceLogo} alt="" />
          <h3>주식회사 플래너리</h3>
          <ul>
            <li>
              대표 <span>이나리</span>
            </li>
            <li>
              사업자등록번호 <span>820-88-00983</span>
            </li>
            <li>
              통신판매업 신고번호 <span>제 2018-서울강남-02705호</span>
            </li>
            <li>
              주소 <span>서울특별시 강남구 선릉로 533 (역삼동, 연안이씨빌딩), 5층</span>
            </li>
            <li>
              CS∙서비스 문의 <a href="mailto:contact@heyjoyce.com">contact@heyjoyce.com</a>
            </li>
            <li>
              제휴∙마케팅 문의 <a href="mailto:partnership@heyjoyce.com">partnership@heyjoyce.com</a>
            </li>
          </ul>
          <div className="footerLinks">
            <button className="chatQuestion channeltalkButton" onClick={handleClickQuestion}>
              1:1 문의하기
            </button>
            <div>
              <Link to="/policy/termsofuse">이용약관</Link>
              <Link to="/policy/privacy">개인정보 처리방침</Link>
              <Link to="/policy/faq">FAQ</Link>
              <Link to="/newsletter">뉴스레터 구독</Link>
              <Link to="/about">어바웃</Link>
              <Link to="/booking">공간 대관</Link>
              <a href="https://www.notion.so/85b7c21bb70c4001a6a6b962f933a020/" target="recruit">
                채용
              </a>
            </div>
          </div>
          <b className="copyright">Copyright © 2018 PLANNERY. All rights reserved.</b>
        </div>
        <div className="sns">
          <a id="appstore" onClick={() => handleClickAppDownload('앱스토어')} href={appstoreUrl} target="appstore">
            <FontAwesomeIcon icon={faApple} style={{ marginRight: 8 }} />
          </a>
          <a id="playstore" onClick={() => handleClickAppDownload('플레이스토어')} href={playstoreUrl} target="playstore">
            <FontAwesomeIcon icon={faGooglePlay} style={{ marginRight: 8 }} />
          </a>
          <a href={blogUrl} target="blog">
            <FontAwesomeIcon icon={faBlog} style={{ marginRight: 8 }} />
          </a>
          <a href="https://www.instagram.com/heyjoyce__/" target="instagram">
            <FontAwesomeIcon icon={faInstagram} style={{ marginRight: 8 }} />
          </a>
          <a href="https://www.facebook.com/HeyJoyceHeyJoyce/" target="facebook">
            <FontAwesomeIcon icon={faFacebook} style={{ marginRight: 8 }} />
          </a>
        </div>
      </div>
    </Wrapper>
  );
};

export default withRouter(Footer);
