const refund_dict = '\
<p><span class="colour" style="color: rgb(0, 0, 0);" \
data-tomark-pass=""><strong>[ 헤이조이스 환불 규정 ]</strong></span></p>\
<p><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">&nbsp;</span></p><p><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">① 헤이조이스 멤버십 환불 세부 기준은 다음과 같습니다.</span><br>\
<br data-tomark-pass=""></p>\
<ul>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 결제 즉시 멤버십이 발효되며, 모든 멤버십은 가입일로부터 1년 간 유효합니다.</span></li>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">환불 요청 시점 전까지 사용한 혜택 및 서비스의 정가를 기준으로 차감됩니다.</span></li>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">프로그램 할인 쿠폰 및 프로그램 이용권을 사용하여 결제를 진행하고, 100% 환불이 불가한 시점이 지났다면 해당 쿠폰 및 이용권을 사용한 것으로 간주합니다.&nbsp;</span></li>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 시작일로부터 익월의 동일 날짜 전일까지를 1개월로 합니다. (동일 날짜가 없는 경우 익월 마지막 날까지)</span></li>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 서비스 운영료는 1개월 단위로 차감합니다.</span></li>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 결제 후 서비스를 전혀 이용하지 않은 경우, 멤버십 시작 시점으로부터 24시간 이내 환불 요청 시 전액 환불이 가능합니다.&nbsp;</span></li>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 시작일로부터 6개월 이후부터는 환불이 불가합니다.</span></li>\
</ul>\
<p><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">② 각 멤버십 환불 시 차감하는 혜택 및 서비스의 정가는 다음과 같습니다.</span><br>\
<br data-tomark-pass=""></p>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">라이트 멤버십</span></li>\
<figure>\
<table>\
<thead>\
<tr>\
<th><span class="colour" data-tomark-pass=""><strong>항목</strong></span></th>\
<th><span class="colour" data-tomark-pass=""><strong>차감 내용</strong></span></th>\
<th><span class="colour" data-tomark-pass=""><strong>정가</strong></span></th>\
</tr>\
</thead>\
<tbody>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">굿즈</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 가입 시 제공되는 굿즈에 해당하는 금액 및 배송비</span><br data-tomark-pass=""><br data-tomark-pass=""><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">*단, 환불 요청 시점이 굿즈를 회원에게 발송하기 전이라면 차감하지 않음</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">23,000원</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">프로그램 할인 쿠폰</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">사용한 프로그램 할인 쿠폰 금액</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">각 쿠폰 당 가격</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">서비스 운영료</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">헤이조이스 서비스 운영비를 월할 계산</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">5,000원/월</span></td>\
</tr>\
</tbody>\
</table>\
</figure>\
<p><br></p>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">스탠더드 멤버십</span></li>\
<table>\
<thead>\
<tr>\
<th><span class="colour" data-tomark-pass=""><strong>항목</strong></span></th>\
<th><span class="colour" data-tomark-pass=""><strong>차감 내용</strong></span></th>\
<th><span class="colour" data-tomark-pass=""><strong>정가</strong></span></th>\
</tr>\
</thead>\
<tbody>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">굿즈</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 가입 시 제공되는 굿즈에 해당하는 금액 및 배송비</span><br data-tomark-pass=""><br data-tomark-pass=""><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">*단, 환불 요청 시점이 굿즈를 회원에게 발송하기 전이라면 차감하지 않음</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">23,000원</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">스페셜 기프트</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 가입 시 제공되는 스페셜 기프트 정가에 해당하는 금액</span><br data-tomark-pass=""><br data-tomark-pass=""><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">*단, 환불 요청 시점이 스페셜 기프트를 회원에게 발송하기 전이라면 차감하지 않음</span><br data-tomark-pass=""><br data-tomark-pass=""><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">*이 외 멤버십 가입 혜택으로 제공되는 별도의 스페셜 기프트가 있는 경우, 해당 기프트의 정가를 차감</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">20,000원</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">프로그램 할인 쿠폰</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">사용한 프로그램 할인 쿠폰 금액</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">각 쿠폰 당 가격</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">서비스 운영료</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">헤이조이스 서비스 운영비를 월할 계산</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">25,000원/월</span></td>\
</tr>\
</tbody>\
</table>\
<p><br><br></p>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">프리미엄 멤버십</span></li>\
<table>\
<thead>\
<tr>\
<th><span class="colour" data-tomark-pass=""><strong>항목</strong></span></th>\
<th><span class="colour" data-tomark-pass=""><strong>차감 내용</strong></span></th>\
<th><span class="colour" data-tomark-pass=""><strong>정가</strong></span></th>\
</tr>\
</thead>\
<tbody>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">굿즈</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 가입 시 제공되는 굿즈에 해당하는 금액 및 배송비</span><br data-tomark-pass=""><br data-tomark-pass=""><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">*단, 환불 요청 시점이 굿즈를 회원에게 발송하기 전이라면 차감하지 않음</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">23,000원</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">스페셜 기프트</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 가입 시 제공되는 스페셜 기프트 정가에 해당하는 금액</span><br data-tomark-pass=""><br data-tomark-pass=""><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">*단, 환불 요청 시점이 스페셜 기프트를 회원에게 발송하기 전이라면 차감하지 않음</span><br data-tomark-pass=""><br data-tomark-pass=""><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">*이 외 멤버십 가입 혜택으로 제공되는 별도의 스페셜 기프트가 있는 경우, 해당 기프트의 정가를 차감</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">20,000원</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">프로그램 할인 쿠폰</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">사용한 프로그램 할인 쿠폰 금액</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">각 쿠폰 당 가격</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">프로그램 이용권</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 가입 시 제공되는 프로그램 이용권 사용 시, 각 프로그램의 정가만큼 차감</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">각 프로그램 별 상이</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">콘조이스 애프터 파티</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 가입 시 제공되는 ‘콘조이스 애프터 파티’ 무료 쿠폰 사용 시, ‘콘조이스 애프터 파티’의 정가만큼 차감</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">100,000원 (최대 1회)</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">프리미엄 멤버 네트워킹 파티</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">연 2회 제공되는 ‘프리미엄 멤버 네트워킹 파티’ 중, 참여한 횟수만큼 해당 프로그램의 정가를 차감</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">100,000원 (최대 2회)</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">프리미엄 멤버 티타임</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">월 1회 제공되는 ‘프리미엄 멤버 티타임’ 중, 참여한 횟수만큼 해당 프로그램의 정가를 차감</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">30,000원 (최대 12회)</span></td>\
</tr>\
<tr>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">서비스 운영료</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">헤이조이스 서비스 운영비를 월할 계산</span></td>\
<td><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">60,000원/월</span></td>\
</tr>\
</tbody>\
</table>\
<p><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">&nbsp;</span></p>\
<p><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">③ 멤버십 환불로 인한 서비스 이용 중지의 효력</span><br>\
<br data-tomark-pass=""></p>\
<ul>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 서비스를 전혀 이용하지 않고 24시간 이내에 전액 환불할 경우</span>\
<ul>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">환불 요청일 즉시 멤버 자격이 상실됩니다.</span></li>\
</ul>\
</li>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">멤버십 서비스 이용 후 환불 신청할 경우</span>\
<ul>\
<li><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">환불 요청일 즉시 멤버 자격이 상실 되며, 해당 일자 이후 신청되어 있는 모든 프로그램은 자동 취소됩니다.</span></li>\
</ul>\
</li>\
</ul>\
<p><span class="colour" style="color: rgb(0, 0, 0);" data-tomark-pass="">④ 멤버십 결제 가격에서 특정 조건이나 방법을 통해 할인 받은 금액은 환불되지 않습니다.</span></p>';

export {refund_dict};