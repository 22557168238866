import moment from 'moment/moment.js';
import 'moment/locale/ko';
import { ANONYMOUS_NAME } from 'assets/words.js';
import { EVENTS } from 'block/LinkGA';

const STAGE = Object.freeze({
  member: 10,
  fellow: 20,
});

const login_type = Object.freeze({
  apple: 'APPLE',
  email: 'EMAIL',
  kakao: 'KAKAO',
});

const level_info_dict = {
  guest: {
    korean: '게스트',
    level_integer: 0,
  },
  associate_member: {
    korean: '예비 멤버',
    level_integer: 20,
  },
  lite: {
    korean: '라이트',
    level_integer: 30,
  },
  standard: {
    korean: '스탠더드',
    level_integer: 60,
  },
  premium: {
    korean: '프리미엄',
    level_integer: 80,
  },
  inspirer: {
    korean: '인스파이러',
    level_integer: 80,
  },
  staff: {
    korean: '관리자',
    level_integer: 100,
  },
  admin: {
    korean: '관리자',
    level_integer: 100,
  },
  test: {
    korean: '테스트',
    level_integer: 100,
  },
};

const program_dict = {
  moim: {
    korean: '모임',
    title: 'Moim',
    path: 'clubjoyce',
    chatting: true,
    description:
      '헤이조이스에서는 매일 워킹 우먼의 니즈와 취향에 기반한 각종 모임이 열립니다.\n원하는 주제와 분야의 다양한 모임을 즐기며, 일과 삶에 힘이 되어 줄 사회적 친구들을 만나세요.',
    notice: '',
    notice_sub: '',
    notice_url: '',
  },
  event: {
    korean: '라이브',
    title: 'Events',
    path: 'event',
    chatting: false,
    description: '랜선 사수의 노하우를 실시간으로 전수받는 시간! 라이브 이벤트에서 고민의 답을 찾아보세요.',
    notice: '',
    notice_sub: '',
    notice_url: '',
    event_tag: EVENTS.program091,
  },
  digitalcontents: {
    korean: 'VOD',
    title: 'Digital Contents',
    path: 'digitalcontents',
    chatting: false,
    description: '생생한 사례와 실무 노하우가 담긴 동영상 강연! 필요할 때 언제 어디서나 만나 보세요.',
    notice: '',
    notice_sub: '',
    notice_url: '',
    event_tag: EVENTS.program093,
  },
  // deprecated
  clubjoyce: {
    korean: '모임',
    title: 'Moim',
    path: 'clubjoyce',
    chatting: true,
    description: '헤이조이스에서는 일하는 여성들의 니즈와 취향에 기반한 각종 모임이 열립니다.',
    notice: '',
    notice_sub: '',
    notice_url: '',
    event_tag: EVENTS.program092,
  },
  projectjoyce: {
    korean: '여러번모임',
    title: 'Moim',
    path: 'projectjoye',
    chatting: true,
    description: '',
    notice: '',
    notice_sub: '',
    notice_url: '',
  },
};

const status_dict = {
  opened: '신청 가능',
  closed: '마감',
  'sold out': '매진',
};

const list_status_dict = {
  opened: '모집 진행 중',
  ready: '곧 오픈!',
  closed: '닫힘',
  'sold out': '마감',
  end: '종료',
  'register end': '마감',
};

const pay_method_dict = {
  notimp: {
    korean: '쿠폰결제',
    emoji: '🎟',
  },
  card: {
    korean: '신용카드',
    emoji: '💳',
  },
  trans: {
    korean: '실시간 계좌이체',
    emoji: '💵',
  },
  vbank: {
    korean: '가상계좌이체',
    emoji: '💵',
  },
  kakaopay: {
    korean: '카카오페이',
    emoji: '💳',
  },
  kakaomoney: {
    korean: '카카오페이머니',
    emoji: '💰',
  },
};

const BOARDS = Object.freeze({
  ann: {
    name: '익명',
    icon: 'busts_in_silhouette',
    is_anonymous: true,
    placeholder:
      '게시글 작성 시 아래 사항을 준수하여 주세요.\n1. 서로를 존중하는 글 작성하기\n2. 외부 사이트의 글을 가져올 경우, 출처 기재하기\n3. 헤이조이스에 궁금한 사항은 홈페이지 하단 1:1 문의하기, 건의사항은 헤조 소리함에 남겨 주세요\n\n아래 사항을 위반할 경우, 게시물 삭제 및 이용 제한 처리됩니다.\n1. 광고, 홍보 글이나 금전 거래를 유도하는 글인 경우\n2. 타인의 신상 정보를 게시한 경우\n3. 특정 단체 혹은 개인에 관한 허위 사실을 유포한 경우\n4. 범죄, 불법 행위 등 법령을 위반한 경우\n5. 음란물, 성적 수치심을 유발하는 글을 게시한 경우',
  },
  free: {
    name: '수다',
    icon: 'speech_balloon',
    is_anonymous: false,
    placeholder:
      '게시글 작성 시 아래 사항을 준수하여 주세요.\n1. 서로를 존중하는 글 작성하기\n2. 외부 사이트의 글을 가져올 경우, 출처 기재하기\n3. 광고ㆍ홍보 글인 경우 제목에 [홍보] 말머리 달기\n\n아래 사항을 위반할 경우, 게시물 삭제 및 이용 제한 처리됩니다.\n1. 타인의 신상 정보를 게시한 경우\n2. 금전 거래를 유도하는 경우\n3. 특정 단체 혹은 개인에 관한 허위 사실을 유포한 경우\n4. 범죄, 불법 행위 등 법령을 위반한 경우\n5. 음란물, 성적 수치심을 유발하는 글을 게시한 경우',
  },
  info: {
    name: '구인・구직',
    icon: 'mag',
    is_anonymous: false,
    placeholder:
      '게시글 작성 시 아래 사항을 준수하여 주세요.\n1. 서로를 존중하는 글 작성하기\n2. 외부 사이트의 글을 가져올 경우, 출처 기재하기\n\n아래 사항을 위반할 경우, 게시물 삭제 및 이용 제한 처리됩니다.\n1. 타인의 신상 정보를 게시한 경우\n2. 금전 거래를 유도하는 경우\n3. 특정 단체 혹은 개인에 관한 허위 사실을 유포한 경우\n4. 범죄, 불법 행위 등 법령을 위반한 경우\n5. 음란물, 성적 수치심을 유발하는 글을 게시한 경우',
  },
});

function date_diff_helper(start_date, end_date) {
  let sd = moment(start_date);
  let ed = moment(end_date);

  let sd_diff = moment().diff(sd, 'days');
  let ed_diff = ed.diff(moment(), 'days');

  let diff_status;

  if (sd_diff >= 0 && ed_diff >= 0) {
    diff_status = '진행중';
  } else if (sd_diff < 0) {
    diff_status = '진행전';
  } else {
    diff_status = '종료';
  }

  return diff_status;
}

function date_helper(start_date, end_date) {
  moment.locale('ko');

  let sd = moment(start_date);
  let ed = moment(end_date);
  let result_d = '';

  //연도가 같은 경우
  if (moment(sd).format('YYYY') === moment(ed).format('YYYY')) {
    //월도 같은경우
    if (moment(sd).format('MM') === moment(ed).format('MM')) {
      //일도 같은 경우
      if (moment(sd).format('DD') === moment(ed).format('DD')) {
        result_d = moment(sd).format('YYYY.MM.DD (ddd) A hh:mm ~ ') + moment(ed).format('A hh:mm');
      } else {
        result_d = moment(sd).format('YYYY.MM.DD (ddd) A hh:mm ~ ') + moment(ed).format('DD (ddd) A hh:mm');
      }
    } else {
      result_d = moment(sd).format('YYYY.MM.DD (ddd) A hh:mm ~ ') + moment(ed).format('MM.DD (ddd) A hh:mm');
    }
  } else {
    result_d = moment(sd).format('YYYY.MM.DD (ddd) A hh:mm ~ ') + moment(ed).format('YYYY.MM.DD (ddd) A hh:mm');
  }

  return result_d;
}

function hashCodeHelper(str) {
  let hashcode;
  for (let i = 0; i < str.length; i++) hashcode = (Math.imul(31, hashcode) + str.charCodeAt(i)) | 0;

  return hashcode;
}

function createAnonymous(value) {
  let hashnum = Math.abs(hashCodeHelper(value));
  let annlength1 = ANONYMOUS_NAME.adjective.length;
  let annlength2 = ANONYMOUS_NAME.name.length;
  let anonymous_name =
    ANONYMOUS_NAME.adjective[parseInt((hashnum % (annlength1 * annlength2)) / annlength2)] +
    ' ' +
    ANONYMOUS_NAME.name[(hashnum % (annlength1 * annlength2)) % annlength2];
  return anonymous_name;
}

function priceHelper(price) {
  return parseInt(price).toLocaleString(navigator.language, { minimumFractionDigits: 0 });
}

const getDiscountRate = (originPrice, salePrice) => Math.ceil(100 - (salePrice / originPrice) * 100);

function youtubeParser(Description) {
  if (Description) {
    var yt_prefix = '{{youtube:';
    var yt_appendix = '}}';
    var yt_length = Description.match('{{youtube:');

    if (yt_length) {
      yt_length = Description.match(/{{youtube:/g).length || 0;
      for (var i = 0; i < yt_length; i++) {
        var yt_id = Description.split(yt_prefix)[1].split(yt_appendix)[0];
        var yt_target = yt_prefix + yt_id + yt_appendix;
        var yt_iframe =
          '<iframe class="youtube_block" src="https://www.youtube.com/embed/' + yt_id + '" frameborder="0" allowfullscreen></iframe>';
        Description = Description.replace(yt_target, yt_iframe);
      }
    }

    return Description;
  }
}

/**
 * Timestamp 관련 utils
 */

function getCurrentTime(timestamp) {
  var d = new Date(timestamp);
  d.setHours(d.getHours()); // offset from local time
  var h = d.getHours() % 12 || 12; // show midnight & noon as 12
  return (h < 10 ? '0' : '') + h + (d.getMinutes() < 10 ? ':0' : ':') + d.getMinutes() + (d.getHours() < 12 ? ' AM' : ' PM');
}

function getCurrentDateKor(timestamp) {
  var week = ['일', '월', '화', '수', '목', '금', '토'];
  var today = new Date(timestamp);
  var year = today.getFullYear();
  var month = today.getMonth() + 1;
  var day = today.getDate();
  var dayName = week[today.getDay()];
  return `${year}년 ${month}월 ${day}일 ${dayName}요일`;
}

function getStringDate(timestamp) {
  var date = new Date(timestamp);
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var day = date.getDate();

  return `${year}년 ${month}월 ${day}일`;
}

const generateOrderId = ({ productType = 'program', productId, userId }) => {
  return `${productType}_${productId}_U${userId}${moment().format('TYYYYMMDDHHmmss')}`;
};

const parseJwt = (token) => {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let session = {};
  try {
    session = JSON.parse(window.atob(base64));
  } catch (err) {}
  return session;
};

const trackFBEvent = (action, options = {}) => {
  if (window.fbq && typeof window.fbq === 'function') {
    window.fbq('track', action, options);
  }
};

const trackFinishedTransaction = ({ amount, productType, productId, productName, currency = 'KRW' }) => {
  return trackFBEvent('Purchase', {
    currency,
    value: amount,
    content_ids: [`${productType}_${productId}`],
    content_name: productName,
  });
};

function isEmptyObject(param) {
  return Object.keys(param).length === 0 && param.constructor === Object;
}

function toBase62(n) {
  if (n === 0) {
    return '0';
  }
  var digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = '';
  while (n > 0) {
    result = digits[n % digits.length] + result;
    n = parseInt(n / digits.length, 10);
  }

  return result;
}

function fromBase62(s) {
  var digits = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  var result = 0;
  for (var i = 0; i < s.length; i++) {
    var p = digits.indexOf(s[i]);
    if (p < 0) {
      return NaN;
    }
    result += p * Math.pow(digits.length, s.length - i - 1);
  }
  return result;
}

const base64 = {
  encode: function (unencoded) {
    return Buffer.from(unencoded || '', 'utf8').toString('base64');
  },
  decode: function (encoded) {
    return Buffer.from(encoded || '', 'base64').toString('utf8');
  },
  urlEncode: function (unencoded) {
    var encoded = base64.encode(unencoded);
    return encoded.replace('+', '-').replace('/', '_').replace(/=+$/, '');
  },
  urlDecode: function (encoded) {
    encoded = encoded.replace('-', '+').replace('_', '/');
    while (encoded.length % 4) encoded += '=';
    return base64.decode(encoded);
  },
};

const calculateTimeLeft = (datetime) => {
  let difference = +new Date(datetime) - +new Date();
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: (Math.floor((difference / (1000 * 60 * 60)) % 24) < 10 && '0') + Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: (Math.floor((difference / 1000 / 60) % 60) < 10 && '0') + Math.floor((difference / 1000 / 60) % 60),
      seconds: (Math.floor((difference / 1000) % 60) < 10 && '0') + Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

export {
  STAGE,
  // deprecated
  login_type,
  level_info_dict,
  pay_method_dict,
  program_dict,
  status_dict,
  list_status_dict,
  BOARDS,
  date_helper,
  priceHelper,
  getDiscountRate,
  date_diff_helper,
  youtubeParser,
  getCurrentTime,
  getCurrentDateKor,
  getStringDate,
  generateOrderId,
  parseJwt,
  trackFinishedTransaction,
  trackFBEvent,
  isEmptyObject,
  toBase62,
  fromBase62,
  createAnonymous,
  calculateTimeLeft,
  base64,
};
