import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: auto;
  margin-top: 18px;

  .title {
    font-size: 21px;
    font-weight: bold;
    margin: 5px 0px;
  }

  .careerBox {
    padding: 5px;

    .careerList {
      display: flex;
      font-size: 16px;
      margin-bottom: 3px;

      svg {
        margin-right: 6px;
      }

      span:first-child {
        width: 10px;
      }

      span:last-child {
        width: calc(100% - 10px);
        word-break: keep-all;
      }
    }
  }

  @media screen and (max-width: 768px) {
    margin: 10px;
    margin-bottom: 20px;

    .title {
      padding-left: 10px;
    }

    .careerBox {
      padding: 5px 10px;
    }
  }
`;

const CareerCard = ({ careers }) => {
  return (
    <Wrapper>
      <div className="title">커리어 패스</div>
      <div className="careerBox">
        {careers.length > 0 &&
          careers.map((career, index) => {
            return (
              <div className="careerList" key={`career${index}`}>
                <span>-</span>
                <span>{`${career.company} ${career.hasOwnProperty('position') ? career.position : ''}`}</span>
              </div>
            );
          })}
      </div>
    </Wrapper>
  );
};

export default CareerCard;
