import moment from 'moment/moment.js';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { api_axios } from 'api';
import { isMobile } from 'react-device-detect';
import FellowCard from '../../component/FellowPage/FellowCard';
import CareerCard from '../../component/FellowPage/CareerCard';
import CategoryCard from '../../component/FellowPage/CategoryCard';
import FellowQnaCard from '../../component/FellowPage/FellowQnaCard';
import '../Home/Landing.scss';
import './FellowPage.scss';

class FellowPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      userinfo: this.props.userinfo,
      categories: {},
    };
  }

  async fetchCategories() {
    const { results } = await api_axios('GET', 'categories/');
    this.setState({
      categories: results,
    });
  }

  componentWillMount = () => {
    this.fetchCategories();
  };

  componentDidMount() {
    setTimeout(() => {
      if (this.myRef && this.myRef.current && this.props.location.search === '?tab=qna' && isMobile) {
        this.myRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, 700);
  }

  getFormateDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  render() {
    let { userinfo, categories } = this.state;
    let category_names =
      categories.length > 0 && userinfo.category_ids.length > 0
        ? userinfo.category_ids.map((category_id) => {
            return categories.find((category) => category.id === category_id).name;
          })
        : [];

    return (
      <main className="fellowPageWrap">
        <div className="nameTitle" onClick={this.handleClick}>
          <hr className="line"></hr>
          <div className="titleText">{userinfo.username}</div>
        </div>
        <div className="contentBox">
          <div className="fellowBox">
            <FellowCard {...userinfo} />
          </div>
          <div className="infoBox">
            <hr className="contentDevider" />
            <CategoryCard categories={category_names} subCategories={userinfo.data.fellow.sub_categories} />
            <hr className="contentDevider" />
            <CareerCard careers={userinfo.data.fellow.careers} />
            <hr className="contentDevider" />
            <div ref={this.myRef} style={{ scrollMarginTop: 70 }} />
            <FellowQnaCard username={userinfo.username} userid={userinfo.id} />
          </div>
        </div>
      </main>
    );
  }
}

export default withRouter(FellowPageContainer);
