import React from 'react';
import styled from 'styled-components';
import { useModalContext } from 'context/modal';
import { api_axios } from '../../api';
import * as Sentry from '@sentry/react';

const ButtonHj = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  font-weight: 500;
  background-color: ${(props) => {
    return props.type === 'yellow' ? '#fddd00' : '#ffffff';
  }};
  box-sizing: border-box;
  border: ${(props) => {
    return props.type === 'yellow' ? 'none' : '0.3px solid #ccc';
  }};
  border-radius: 4px;
  font-size: 15px;
  margin-top: 10px;
  &:hover {
    cursor: 'pointer';
  }
`;

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 18px;
  border-radius: 10px;
  width: 320px;
  height: auto;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  .section {
    .content {
      h4 {
        margin: 0 0 0.5em 0;
      }
      p {
        font-size: 18px;
        margin: 0px 0px 20px 0px;
      }
      .desc {
        font-size: 15px;

        margin-bottom: 20px;

        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        b {
          color: #444444;
        }
      }
    }
  }
`;

const PushAgreeModal = () => {
  const [modalState, , hide] = useModalContext();
  const { userinfo, setIsPushAgreed, updateUserinfo } = modalState.data;

  const handleDisagree = async () => {
    const DISAGREE_BOOL = false;

    try {
      await api_axios('PATCH', `users/${userinfo.id}/`, { has_agreed_push: DISAGREE_BOOL });
      setIsPushAgreed(DISAGREE_BOOL);
      updateUserinfo({ has_agreed_push: DISAGREE_BOOL });
      hide();
    } catch (err) {
      Sentry.captureException(err);
    }
  };

  const handelCancel = () => {
    hide();
  };

  return (
    <Wrapper>
      <div className="section">
        <div className="content">
          <h4>확인해주세요</h4>
          <p className="desc">
            <div>헤이조이스에서 제공하는 유용하고 재밌는 정보를 받아볼 수 없어요. 😥 알림을 끄시겠어요?</div>
          </p>
        </div>
        <ButtonHj type="yellow" onClick={handelCancel}>
          다시 생각해볼게요
        </ButtonHj>
        <ButtonHj type="white" onClick={handleDisagree}>
          알림을 끌게요
        </ButtonHj>
      </div>
    </Wrapper>
  );
};

export default PushAgreeModal;
