import React from 'react';
import styled from 'styled-components';
import { gql, useQuery } from '@apollo/client';
import { getFeed as getFeedQuery } from 'graphql/queries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStream } from '@fortawesome/free-solid-svg-icons/faStream';
import Skeleton from '@material-ui/lab/Skeleton';
import { Avatar } from '@material-ui/core';
import Header from 'block/Header';

import { Link } from 'react-router-dom';
import { youtubeParser } from 'helpers';

import Quote from '../../images/quote_icon.png';

const Wrapper = styled.div`
  max-width: 935px;
  margin: 0px auto 0px;
  padding: 0;
  min-height: 400px;

  background-color: #fff;
  padding: 0px 0px 20px 0px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 920px) {
    max-width: 100%;
  }
`;
const Section = styled.div`
  background-color: #fff;
  padding: 4px 16px 4px 16px;
  position: relative;
  max-width: 935px;
  display: flex;
  flex-direction: column;
  margin: 30px 0px;
  align-items: center;

  .tag {
    color: #000000;
  }
  .title {
    max-width: 750px;
    margin: 10px 0px;
    text-align: center;
  }

  .info {
    margin: 4px 0px;
    font-weight: lighter;
    color: #a9a9a9;
    display: flex;
    align-items: center;
    p {
      margin: 0px 6px;
    }
  }

  .thumbnailWrapper {
    display: flex;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .thumnailImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .html {
    min-height: 335px;
    font-size: 16px;
    font-weight: 400;
    color: #000;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    line-height: 1.8 !important;

    & > *:first-child {
      margin-top: 1em;
    }

    * {
      letter-spacing: -0.2px;
      word-spacing: 0.5px;
      margin: 0;
      word-break: keep-all;
    }

    h2 {
      font-size: 28px;
      margin: 1em 0;
    }

    h3,
    h4 {
      font-size: 20px;
    }

    h5 {
      font-size: 1em;
    }

    img,
    figure img {
      max-width: 100%;
      margin: 16px auto;
      display: block;
      border-radius: 4px;
    }

    ul {
      list-style: none;
      margin-left: 0;
      padding-left: 0;
      background-color: #f5f5f5;
      border-radius: 8px;
      padding: 12px 15px;
      box-sizing: border-box;
    }

    li {
      padding-left: 1.5em;
      text-indent: -1.5em;
      margin-bottom: 5px;
    }

    li:before {
      content: '✔️';
      padding-right: 8px;
    }

    blockquote {
      position: relative;
      margin-top: 60px;
      text-align: center;
      color: #424242;
      padding: 0px 10px;
    }

    blockquote:before {
      content: '';
      position: absolute;
      background: url(${Quote}); /*url of image*/
      left: calc(50% - 16px);
      top: -42px;
      font-size: 100px;
      width: 32px;
      height: 28px;
    }

    .ql-align-right {
      text-align: right;
    }

    .ql-align-center {
      text-align: center;
    }

    width: 100%;

    .ql-video {
      width: 420px;
      max-width: 90%;
      height: 260px;
    }
    .ql-video.ql-align-center {
      display: flex;
      margin: 0 auto;
    }
    .ql-video.ql-align-right {
      display: flex;
      margin-left: auto;
    }
  }

  .bottomNav {
    font-size: 16px;
    color: #a9a9a9;
  }
`;

const AuthorImage = styled(Avatar)`
  margin-right: 6px;
  border-radius: 50%;
  width: 25px !important;
  height: 25px !important;
`;

const AuthorDesc = styled.span`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  display: flex;
`;
const BlogDetail = ({ match }) => {
  const { params } = match;
  const { blogId } = params;

  const { data, loading, error } = useQuery(
    gql`
      ${getFeedQuery}
    `,
    {
      variables: {
        id: blogId,
      },
    }
  );

  if (error || loading || !data) {
    return (
      <Wrapper>
        <Section>
          <Skeleton width={'100%'} height={250} />
        </Section>
        <Section>
          <Skeleton width={'100%'} variant="rect" height={600} />
        </Section>
      </Wrapper>
    );
  }

  const getHTML = (content) => {
    content = youtubeParser(content);
    return <div className="contentHTML" dangerouslySetInnerHTML={{ __html: content }}></div>;
  };

  const item = data.getFeed;
  const { data: info, reactions, created_at, updated_at } = item;
  const { author, title, body, thumbnail } = info;
  const date = new Date(updated_at);

  const formatDate = (date) => {
    let formatted_date =
      date.getFullYear() + '년 ' + (date.getMonth() + 1) + '월 ' + date.getDate() + '일 ' + date.getHours() + ':' + date.getMinutes();
    return formatted_date;
  };

  return (
    <Wrapper>
      <Header title={`${title} | 헤이조이스`} description={body.replace(/(<([^>]+)>)/gi, '')} image={thumbnail} />
      <Section>
        <Link className="tag" to={'/blog/list'}>
          헤이조이스 블로그
        </Link>
        <h2 className="title">{title}</h2>
        <div className="info">
          <AuthorImage src={author.profile_url} />
          <AuthorDesc>
            <span>{author.username}</span>
          </AuthorDesc>
          <p>|</p>
          <span>{formatDate(date)}</span>
        </div>
      </Section>
      <Section>
        <div className="thumbnailWrapper">
          <img className="thumnailImage" src={thumbnail} alt={title}></img>
        </div>
      </Section>
      <Section>
        <div className="html">{getHTML(body)}</div>
      </Section>
      <Section>
        <Link className="bottomNav" to={'/blog/list'}>
          <FontAwesomeIcon icon={faStream} color="darkgray" /> 목록으로
        </Link>
      </Section>
    </Wrapper>
  );
};

export default BlogDetail;
