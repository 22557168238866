import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: left;
  width: auto;

  .title {
    font-size: 21px;
    font-weight: bold;
    margin: 5px 0px;
  }

  .categoryList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 5px 0 0;
  }

  .chip {
    background: #213087;
    border-radius: 32px;
    font-size: 16px;
    color: white;
    padding: 4px 12px 6px 12px;
    align-items: center;
    list-style: none;
    margin: 0px 8px 6px 0px;
  }

  .subCategoryList {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    margin-top: 3px;
  }

  .subChip {
    list-style: none;
    font-size: 15px;
    margin: 5px;
    color: #3e3e3e;
  }

  @media screen and (max-width: 768px) {
    margin: 10px 15px;
    margin-bottom: 20px;

    .title {
      margin: 5px;
    }

    .categoryList {
      padding-left: 3px;
      margin-bottom: 8px;
    }

    .subChip {
      padding: 0 5px;
      margin: 0px 5px;
    }
  }
`;

const CategoryCard = (fellowInfo) => {
  return (
    <Wrapper>
      <div className="title">강점 카테고리</div>
      <div className="categoryList">
        {fellowInfo.categories.map((category, index) => {
          return (
            <li className="chip" key={`category${index}`}>
              {category}
            </li>
          );
        })}
      </div>
      {fellowInfo.subCategories && (
        <div className="subCategoryList">
          {fellowInfo.subCategories.map((subCategory, index) => {
            return (
              <li className="subChip" key={`subCategory${index}`}>
                {'#'}
                {subCategory}
              </li>
            );
          })}
        </div>
      )}
    </Wrapper>
  );
};

export default CategoryCard;
