import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { CardMedia } from '@material-ui/core';
import { Label, styles } from '@heyjoyce/vibe';
import { EVENTS, ReactGA } from 'block/LinkGA';

const DEFAULT_WIDTH = '270px';
const DEFAULT_HEIGHT = '160px';

const StyledMedia = styled(CardMedia)`
  height: ${(props) => (props.height ? props.height : DEFAULT_HEIGHT)};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
  overflow: hidden;
  transition: transform 0.3s ease;

  @media screen and (min-width: 935px) {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
  }
`;

const StyledActionArea = styled.div`
  width: 100%;
  display: block;
  text-align: inherit;
  background-color: #eee;
  position: relative;
  text-decoration: none;
  align-self: center;
  overflow: hidden;
  border-radius: 4px;

  @media screen and (min-width: 935px) {
    width: ${(props) => (props.width ? props.width : DEFAULT_WIDTH)};
    height: ${(props) => (props.height ? props.height : DEFAULT_HEIGHT)};
  }
`;

const SectionItem = styled.div`
  width: ${(props) => (props.width ? props.width : DEFAULT_WIDTH)};
  border: 0px;
  box-shadow: none;
  overflow: visible;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
  padding-bottom: 17px;

  .description {
    margin: 6px 0px 0px;

    p {
      margin: 4px 0px 0px 0px;
    }

    h3 {
      padding: 2px 0px 3px;
      margin: 0px;
      color: rgb(61, 61, 61);
      letter-spacing: -0.02em;
      font-size: 18px;
      font-weight: 500;
      color: #2d2a26;
      word-break: keep-all;
    }
  }
`;

const SectionCard = ({ item }) => {
  const history = useHistory();
  const { id, type, thumbnail_url, name } = item;

  const handleClick = () => {
    const path = `/program/${item.type}/${id}`;

    ReactGA.event({
      ...EVENTS.program16,
      label: '메인추천섹션',
    });

    if (history.location.pathname === '/') {
      return history.push(path);
    }
    return (window.location.href = path);
  };

  return (
    <SectionItem key={id} onClick={() => handleClick()}>
      <StyledActionArea>
        <StyledMedia component="div" image={thumbnail_url || ''} title={name} />
      </StyledActionArea>
      <div className="description">
        <h3>{name}</h3>
        <p>
          {['event'].includes(type) && (
            <Label size="small" label={`LIVE`} labelColor={styles.color.lightest} backgroundColor={styles.color.secondary} />
          )}
          {['digitalcontents'].includes(type) && (
            <Label size="small" label={`VOD`} labelColor={styles.color.lightest} backgroundColor={styles.color.tertiary} />
          )}
        </p>
      </div>
    </SectionItem>
  );
};

export default SectionCard;
