import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router-dom';
import Flicking from '@egjs/react-flicking';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ROUTES, EVENTS, LinkGA } from 'block/LinkGA';

const Wrap = styled.div`
  min-height: 38px;
`;

const Container = styled.div`
  position: fixed;
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.1) 1px, #fff 1px);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.1) 1px, #fff 1px);
  box-shadow: rgb(0 0 0 / 8%) 0px 0px 14px 0px;
`;

const MenuContainer = styled.div`
  overflow: hidden;
  width: 100%;
  max-width: 975px;
  height: 38px;
  padding: 0 0px 0 11px;
  box-sizing: border-box;
`;

const Tab = styled(LinkGA)`
  vertical-align: top;
  height: 37px;
  padding: 5px 10px 12px 11px;
  box-sizing: border-box;
  line-height: 1.18;
  cursor: pointer;
  font-size: 16px;
  white-space: nowrap;

  i {
    position: absolute;
    top: 4px;
    right: 2px;
    width: 6px;
    height: 6px;
    border: 1.5px solid #fff;
    border-radius: 50%;
    background-color: #ff4e36;
  }

  ${(props) =>
    props['aria-selected']
      ? css`
          span {
            color: rgb(27, 28, 29);
            font-weight: 700;
            &::after {
              content: '';
              position: absolute;
              left: 11px;
              right: 10px;
              bottom: -1px;
              height: 3px;
              background-color: rgb(27, 28, 29);
            }
          }
        `
      : css`
          span {
            color: #666;
          }
          @media (hover: hover) {
            span:hover {
              color: rgb(27, 28, 29);
              font-weight: 700;
            }
          }
        `}
`;

const TopMenu = () => {
  const menuRef = useRef();
  const { pathname } = useLocation();

  const defaultIndex = Object.values(ROUTES).findIndex((value) => {
    if (value === '/') {
      return false;
    }
    return pathname.includes(value);
  });

  const isDesktop = useMediaQuery(`(min-width:640px)`);

  const handleSelect = (e) => {
    const flicking = menuRef.current.flicking;
    const count = flicking.getPanelCount();
    const current = e.index;
    const fit = count / 2;
    if (e.direction === 'NEXT') {
      return menuRef.current.flicking.moveTo(current >= fit ? current - 2 : 0, 500);
    }
    return menuRef.current.flicking.prev(500);
  };

  return (
    <Wrap>
      <Container>
        <MenuContainer>
          <Flicking
            ref={menuRef}
            defaultIndex={defaultIndex > 10 ? defaultIndex - 6 : 0}
            hanger={'0%'}
            anchor={'0%'}
            bound={true}
            onSelect={isDesktop ? () => {} : handleSelect}
            collectStatistics={false}
          >
            <Tab
              path={ROUTES.home}
              aria-selected={
                pathname === ROUTES.home ||
                [ROUTES.member, ROUTES.fellow, ROUTES.board, ROUTES.feed, ROUTES.diconpass].some((route) => pathname.includes(route))
              }
              event={{ ...EVENTS.main00, label: 'nav' }}
            >
              <span>홈</span>
            </Tab>
            <Tab
              path={ROUTES.conjoyce}
              aria-selected={pathname === ROUTES.conjoyce ? true : false}
              event={{
                ...EVENTS.conjoyce01,
                label: 'nav',
              }}
            >
              <span>콘조이스</span>
              {!pathname.includes(ROUTES.conjoyce) && <i />}
            </Tab>
            <Tab
              path={ROUTES.event}
              aria-selected={pathname.includes(ROUTES.event) ? true : false}
              event={{
                ...EVENTS.program01,
                label: 'nav_event',
              }}
            >
              <span>라이브</span>
            </Tab>
            <Tab
              path={ROUTES.digitalcontents}
              aria-selected={pathname.includes(ROUTES.digitalcontents) ? true : false}
              event={{
                ...EVENTS.program01,
                label: 'nav_digitalcontents',
              }}
            >
              <span>VOD</span>
              {!pathname.includes(ROUTES.digitalcontents) && <i />}
            </Tab>
            {/* <Tab
              path={ROUTES.moim}
              aria-selected={pathname.includes(ROUTES.moim) ? true : false}
              event={{
                ...EVENTS.program01,
                label: '모임',
              }}
            >
              <span>모임</span>
            </Tab> */}
            <Tab
              path={ROUTES.package}
              aria-selected={pathname === ROUTES.package ? true : false}
              event={{
                ...EVENTS.package01,
                label: 'nav',
              }}
            >
              <span>성장패키지</span>
            </Tab>
            <Tab
              path={ROUTES.blog}
              aria-selected={pathname === ROUTES.blog ? true : false}
              event={{
                ...EVENTS.blog01,
                label: 'nav',
              }}
            >
              <span>블로그</span>
            </Tab>
          </Flicking>
        </MenuContainer>
      </Container>
    </Wrap>
  );
};

export default TopMenu;
