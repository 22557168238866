
import React, { useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
`;

const WistiaPlayer = ({ hostId, autoPlay = false }) => {
  const className = `wistia_embed wistia_async_${hostId} videoFoam=true autoPlay=${autoPlay} playerColor=fddd00`;

  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = `https://fast.wistia.com/embed/medias/${hostId}.jsonp`;
    script1.async = true;

    const script2 = document.createElement('script');
    script2.src = 'https://fast.wistia.com/assets/external/E-v1.js';
    script2.async = true;

    document.body.appendChild(script1);
    document.body.appendChild(script2);

  }, [hostId]);

  return (
    <Wrapper>
      <div className={className}/>
    </Wrapper>
  )
}

export default WistiaPlayer;