import { Button, TextField, MenuItem, Hidden } from '@material-ui/core/';
import React, { useCallback, useState } from 'react';
import FaQ from '../Faq';
import './PolicyFaq.scss';

const PolicyFaq = () => {
  const [selectedType, setType] = useState('');

  const handleClickType = useCallback(
    (type) => (event) => {
      setType(type);
    },
    []
  );

  const handleClickOption = (e) => {
    let typeCheck = types.find(function (typeItem) {
      return typeItem.type === e.target.value;
    });

    if (e.target.value === '') {
      setType('');
    } else {
      if (typeCheck !== undefined) {
        setType(e.target.value);
      } else {
        setType('');
      }
    }
  };

  const types = [
    { type: 'event', label: '이벤트' },
    { type: 'member', label: '멤버' },
    { type: 'moim', label: '모임' },
    { type: 'digitalcontents', label: '디지털콘텐츠' },
  ];

  const getTypes = () => {
    let typeItems = [];

    types.map((typeItem) => {
      let active = '';

      if (selectedType === typeItem.type) {
        active = 'active';
      }
      typeItems.push(
        <Button key={typeItem.type} onClick={handleClickType(typeItem.type)} className={'tab ' + typeItem.type + ' ' + active}>
          {typeItem.label}
        </Button>
      );
    });

    return typeItems;
  };

  const getTypeOptions = () => {
    let typeItems = [];

    types.map((typeItem) => {
      typeItems.push(
        <MenuItem key={typeItem.type} value={typeItem.type}>
          {typeItem.label}
        </MenuItem>
      );
    });

    return typeItems;
  };

  let allActive = '';
  if (selectedType === '') {
    allActive = 'active';
  }

  return (
    <div className="faqPage">
      <div className="menuHeader">
        <h1>FAQ</h1>
      </div>

      <Hidden only={['xs']}>
        <div style={{ margin: '16px 0 32px', display: 'flex', justifyContent: 'center' }}>
          <Button onClick={handleClickType('')} className={'tab btn_all ' + allActive}>
            전체
          </Button>
          {getTypes()}
        </div>
      </Hidden>

      <Hidden only={['sm', 'md', 'lg', 'xl']}>
        <TextField
          select
          variant="outlined"
          size="large"
          label={'카테고리'}
          name="category"
          className="cateogry"
          fullWidth
          value={selectedType}
          onChange={handleClickOption}
          style={{ margin: '8px auto', width: 200, display: 'block', textDecoration: 'none', border: 0 }}
        >
          <MenuItem key="all" value={''}>
            전체
          </MenuItem>
          {getTypeOptions()}
        </TextField>
      </Hidden>
      <div style={{ maxWidth: 760, margin: '0 auto' }}>
        <FaQ type={selectedType} />
      </div>
    </div>
  );
};

export default PolicyFaq;
