import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { IconButton } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon } from '@material-ui/icons';
import { useSessionContext } from 'context/main';
import { ROUTES, EVENTS, LinkGA } from 'block/LinkGA';
import logoImg from 'images/logo_heyjoyce.png';

const INIT_HISTORY_LENGTH = 2;

const Header = () => {
  const lastLocation = useLastLocation();
  const { pathname } = useLocation();
  const history = useHistory();
  const [, userinfo] = useSessionContext();

  const enableBack = !Object.values(ROUTES).includes(pathname) && INIT_HISTORY_LENGTH < history.length;

  const handleBack = () => {
    // TODO: need to find not covered cases
    if (lastLocation && pathname === lastLocation.pathname) {
      // console.log('case1. lastLocation && pathname === lastLocation.pathname', lastLocation && pathname === lastLocation.pathname);
      // return history.goBack();
    }

    if (!lastLocation) {
      // console.log('case2. !lastLocation', !lastLocation);
      // return history.goBack();
    }

    return history.goBack();
    // console.log('case3. history.push() to', lastLocation);
    // return history.push(lastLocation);
  };

  return enableBack && userinfo.isNative ? (
    <IconButton edge="end" size="medium" aria-label="Back" onClick={handleBack}>
      <ArrowBackIcon />
    </IconButton>
  ) : (
    <LinkGA path="/" event={{ ...EVENTS.main00, label: 'logo' }} className="logoWrapper">
      <img className="logo" src={logoImg} alt="헤이조이스" />
    </LinkGA>
  );
};

export default Header;
