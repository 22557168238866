import React from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import TopMenu from 'container/Home/TopMenu';
import BlogDetail from './BlogDetail';
import BlogList from './BlogList';

const BlogContainer = styled.main`
  header {
    width: 925px;
    margin: 15px auto 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;

    div {
      margin-top: 6px;
      position: relative;

      .title {
        font-size: 18px;
        font-weight: bold;
        z-index: 99;
        color: #2a2a2a;
      }

      .highlight {
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: #fddb0082;
        height: 10px;
        z-index: 98;
      }
    }
  }

  @media screen and (max-width: 768px) {
    header {
      width: 100%;
      align-items: flex-end;
      padding: 16px 16px;
      box-sizing: border-box;
      margin: 0px;
      font-size: 16px;

      div {
        margin: 0px;
        .title {
          font-size: 16px;
        }
      }
    }
  }
`;

const Blog = ({ match }) => {
  return (
    <BlogContainer role="main" aria-label="블로그" style={{ backgroundColor: 'white' }}>
      <Switch>
        <Route
          path={`${match.url}/list`}
          render={(props) => (
            <>
              <TopMenu />
              <BlogList {...props} />
            </>
          )}
        />
        <Route exact path={`${match.url}/:blogId`} render={(props) => <BlogDetail {...props} />} />
      </Switch>
    </BlogContainer>
  );
};

export default Blog;
