import React from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { useSessionContext } from 'context/main';
import { useModalContext, MODALS } from 'context/modal';
import { ReactGA, EVENTS } from 'block/LinkGA';

const QuestionBox = styled.div`
  background-color: #fff;
  box-sizing: border-box;
  padding: 12px 16px 16px 16px;
  position: relative;
  max-width: 935px;
  margin: 0px auto 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.hasBorder &&
    css`
      border-bottom: 1px solid #e5e5e5 !important;
      border-left: 1px solid #e5e5e5 !important;
      border-right: 1px solid #e5e5e5 !important;
    `}

  .questionProfile {
    display: flex;

    .avatar {
      width: 40px;
      height: 40px;
    }

    span {
      margin-left: 2px;
      font-size: 13px;
      color: #939598;
    }
  }

  .questionText {
    width: 100%;
    margin: 0px 0px 0px 8px;
    padding: 8px 20px 10px 14px;
    background-color: #f0f2f5;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    span {
      font-size: 16px;
      font-weight: normal;
      color: #666;
      word-break: break-word;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    background-color: #fff;
    box-sizing: border-box;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
  }
`;

const Button = styled.div`
  padding: 5px 15px;
  display: flex;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #ff5946;
  color: white;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

const QuestionMenu = ({ categories = [], hasBorder = false, label = 'feeds' }) => {
  const history = useHistory();
  const [, userinfo] = useSessionContext();
  const [, dispatch] = useModalContext();

  const { username = '게스트', profile_image } = userinfo;

  const handleClickAskQuestion = () => {
    ReactGA.event({
      ...EVENTS.feed05,
      label,
    });
    if (username === '게스트') {
      return history.push('/login');
    }
    return dispatch({
      key: MODALS.feedWrite,
      data: {
        categories,
        label,
      },
    });
  };

  return (
    <QuestionBox hasBorder={hasBorder} onClick={handleClickAskQuestion}>
      <div className="questionProfile">
        <Avatar className="avatar" alt={username} src={profile_image}>
          {username[0]}
        </Avatar>
      </div>
      <div className="questionText">
        <span>{username}님, 질문이 있으신가요?</span>
      </div>
    </QuestionBox>
  );
};

export const QuestionButton = ({ categories = [], hasBorder = false, label = 'feeds' }) => {
  const [, userinfo] = useSessionContext();
  const [, dispatch] = useModalContext();

  const { username = '게스트' } = userinfo;

  const handleClickAskQuestion = () => {
    ReactGA.event({
      ...EVENTS.fellow03,
      label,
    });
    if (username === '게스트') {
      return dispatch({
        key: MODALS.unlockContent,
        data: {
          type: 'ask',
          label: 'feeds',
        },
      });
    }
    return dispatch({
      key: MODALS.feedWrite,
      data: {
        categories,
        label,
      },
    });
  };

  return (
    <Button hasBorder={hasBorder} onClick={handleClickAskQuestion}>
      질문하기
    </Button>
  );
};

export default QuestionMenu;
