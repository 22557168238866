import React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { useSessionContext } from 'context/main';
import { EVENTS, LinkGA } from 'block/LinkGA';

const Signup = () => {
  const [, userinfo] = useSessionContext();
  const isDesktop = useMediaQuery(`(min-width:640px)`, { noSsr: true });

  return (
    <>
      <LinkGA path="/login" event={{ ...EVENTS.login01, label: 'nav' }} className="loginBtn">
        로그인
      </LinkGA>
      {!isDesktop && !userinfo.isNative ? (
        <LinkGA href="https://heyjoyce.onelink.me/mkp8/app" event={{ ...EVENTS.main10, label: 'nav' }} className="joinBtn" target="_blank">
          앱 사용
        </LinkGA>
      ) : (
        <LinkGA path="/join" event={{ ...EVENTS.signup01, label: 'nav' }} className="joinBtn">
          회원가입
        </LinkGA>
      )}
    </>
  );
};

export default Signup;
