import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button } from '@heyjoyce/vibe';
import { useModalContext } from 'context/modal';
import Certification from '../../component/Certification';
import { api_axios } from '../../api';
import NoIdImg from '../../images/noId.png';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  width: 425px;
  flex-direction: column;

  .container {
    display: flex;
    flex-direction: column;

    h3 {
      margin-top: 0px;
      align-self: center;
    }

    span {
      align-self: center;
    }
    .email {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      align-items: center;
    }
    .desc {
      align-self: center;
      margin-top: 4px;
      color: #5a5a5a;

      a {
        color: #f04e44;
        text-decoration-line: underline;
        margin-left: 8px;
      }
    }
  }

  @media screen and (max-width: 425px) {
    width: 80%;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: 250px;
  align-self: center;
`;

const Image = styled.img`
  max-width: 100%;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
`;

const FindIdModal = () => {
  const [modalState, , hide] = useModalContext();
  const { imp_uid } = modalState.data;
  const [impUid, setImpUid] = useState(imp_uid);
  const [email, setEmail] = useState(null);
  const [isFinish, setIsFinish] = useState(false);

  const handleCloseModal = () => hide();

  const findId = async () => {
    try {
      const { confirm_key } = await api_axios('post', 'confirm_user/', {
        imp_uid: impUid,
      });
      const { email } = await api_axios('post', 'findid/', {
        imp_uid: impUid,
        confirm_key: confirm_key,
      });
      setEmail(email);
      setIsFinish(true);
    } catch {
      setIsFinish(true);
    }
  };

  useEffect(() => {
    findId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isFinish &&
        (email ? (
          <Wrapper>
            <div className="container">
              <h3>본인 확인 완료</h3>
              <div className="email">
                <h4>가입 이메일</h4>
                <span>{email}</span>
              </div>
              <StyledButton onClick={handleCloseModal} appearance="primary" label="닫기" />
            </div>
          </Wrapper>
        ) : (
          <Wrapper>
            <div className="container">
              <h3>아직 헤이조이스 멤버가 아니신가요?</h3>
              <span>해당 번호를 사용하는 멤버를 찾지 못했어요.</span>
              <ImageWrapper>
                <Image src={NoIdImg} alt={NoIdImg} />
              </ImageWrapper>
              <div className="desc">
                <span>헤이조이스 멤버로 함께해요!</span>
                <a href="/join">회원가입하러 가기</a>
              </div>
              <StyledButton onClick={handleCloseModal} appearance="primary" label="닫기" />
            </div>
          </Wrapper>
        ))}
    </>
  );
};

export default FindIdModal;
