
import React from 'react';
import styled from 'styled-components';

const Body1 = styled.p`
  font-size: 14px;
  color: #000000;
  line-height: 18px;

  ${({ gutterBottom }) => gutterBottom && `
    margin: 0px 0px 2px 0px !important;
  `}
`;

const Typography = ({ children, variant = 'body1', gutterBottom = false }) => {
  if (variant === 'body1') {
    return <Body1 gutterBottom={gutterBottom}>{children}</Body1>
  }
  return <Body1 gutterBottom={gutterBottom}>{children}</Body1>
}

export default Typography;