export const createFeed = /* GraphQL */ `
  mutation CreateFeed($input: CreateFeedInput!) {
    createFeed(input: $input) {
      id
    }
  }
`;

export const addReaction = /* GraphQL */ `
  mutation AddReaction($input: AddReactionInput!) {
    addReaction(input: $input)
  }
`;

export const removeReaction = /* GraphQL */ `
  mutation RemoveReaction($input: RemoveReactionInput!) {
    removeReaction(input: $input)
  }
`;