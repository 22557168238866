import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import Privacy from '../../component/Policy/Privacy';
import PrivacyOfUseV2 from '../../component/Policy/PrivacyOfUseV2';
import PolicyFaq from '../../component/Policy/PolicyFaq';
import Refund from '../../component/Policy/Refund';
import '../Home/Landing.scss';
import './Policy.scss';

const TermsOfUseV3 = React.lazy(() => import('../../component/Policy/TermsOfUseV3'));
const TermsOfUseV2 = React.lazy(() => import('../../component/Policy/TermsOfUseV2'));
const TermsOfUse = React.lazy(() => import('../../component/Policy/TermsOfUse'));

const Policy = ({ match }) => {
  return (
    <main className="policyWrap">
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path={`${match.url}/termsofuse/previous/v3`} component={TermsOfUseV3} />
          <Route exact path={`${match.url}/termsofuse/previous/v2`} component={TermsOfUseV2} />
          <Route exact path={`${match.url}/termsofuse`} component={TermsOfUse} />
          <Route exact path={`${match.url}/privacy/previous/v2`} component={PrivacyOfUseV2} />
          <Route exact path={`${match.url}/privacy`} component={Privacy} />
          <Route exact path={`${match.url}/refund`} component={Refund} />
          <Route exact path={`${match.url}/faq`} component={PolicyFaq} />
        </Switch>
      </Suspense>
    </main>
  );
};

export default Policy;
