import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useModalContext } from 'context/modal';
import { Emoji } from 'block/Emoji';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 20px;
  border-radius: 10px;

  .section {
    display: flex;
    flex-direction: column;
    #content {
      display: flex;
      flex-direction: column;
      #title {
        margin-bottom: 10px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        #welcome {
          display: flex;
        }
        .closeBtn {
          margin: 0px;
          padding: 0px;
          color: grey;
          display: inline-flex;
          vertical-align: middle;
          background-color: transparent;
          width: 30px;
          height: 30px;
          flex: 0 0 auto;
        }
      }
    }

    p {
      margin: 0px;
      flex: 1 1 auto;
    }

    #sub {
      color: grey;
      font-size: 13px;
    }

    .desc {
      margin-top: 20px;

      .confirmBtn {
        width: 100%;
        padding: 10px 0px;
        background: #fddd00;

        span {
          font-weight: bold;
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    width: 65%;
  }
`;

const WelcomeModal = () => {
  const history = useHistory();
  const [modalState, , hide] = useModalContext();
  const { goBack } = modalState.data;
  return (
    <Wrapper>
      <div className="section">
        <div id="content">
          <div id="title">
            <p id="welcome">
              <b>
                프로필까지 모두 채우셨네요
                <Emoji emoji={'clap'} size={20} />
              </b>
            </p>
            <IconButton
              edge="end"
              size="medium"
              aria-label="close"
              className="closeBtn"
              onClick={() => {
                history.push(goBack.location ? goBack.location : '/');
                return hide();
              }}
            >
              <Close />
            </IconButton>
          </div>
          <p id="sub">
            본격적으로 헤이조이스를 사용하기 전, <br />
            헤조를 더욱 잘 알고 싶은 분들을 위해 준비했어요!
          </p>
        </div>
        <div className="desc">
          <Button
            onClick={() => {
              ReactGA.event({
                ...EVENTS.profile02,
                label: 'welcome',
              });
              window.open('https://bit.ly/2RGRQRX ', '_blank');
              hide();
            }}
            className="confirmBtn"
          >
            헤이조이스 사용 설명서 확인 하기
          </Button>
        </div>
      </div>
    </Wrapper>
  );
};

export default WelcomeModal;
