import styled from 'styled-components';

const ShowMoreWrapper = styled.div`
  display: flex;
  flex: 1;
  position: relative;
  width: 935px;
  margin: 0 auto;
  border-top: 1px solid #d6d6d6;
  margin-bottom: 30px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: white;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #d6d6d6;
    transition: 0.2s background-color ease-in;

    svg {
      width: 30px;
      height: 30px;
      color: gray;
      margin-top: 2px;
    }

    &:hover {
      cursor: pointer;
      background-color: #f7f7f7;
    }
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    border: none;

    button {
      width: 32px;
      height: 32px;
    }
  }
`;

export default ShowMoreWrapper;