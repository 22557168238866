import React, { useState, useEffect } from 'react';
import { gql, useLazyQuery } from '@apollo/client';
import { Grid } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, VerticalAlignTop as VerticalAlignTopIcon } from '@material-ui/icons';
import { useSessionContext } from 'context/main';

import { listFeeds } from 'graphql/queries';
import { FEED_TYPE } from 'graphql/constants';
import Progress from 'block/Progress';
import BlogCard from './BlogCard';
import ShowMoreWrapper from '../Feed/ShowMoreWrapper';

const DEFAULT_LIMIT = 9;

const BlogCardSection = ({ selectedTypeId }) => {
  const [, userinfo] = useSessionContext();

  const blogTypes = ['전체', '브랜드 스토리', '가이드', '인터뷰'];

  const [page, setPage] = useState(1);

  useEffect(() => {
    setPage(1);
  }, [selectedTypeId]);

  const filter = {
    type: FEED_TYPE.blog,
    ...(selectedTypeId !== 0 ? { tags: [blogTypes[selectedTypeId]] } : {}),
  };

  const [queryListFeed, { loading, data, error, fetchMore }] = useLazyQuery(
    gql`
      ${listFeeds}
    `,
    {
      variables: {
        filter,
        page,
        limit: DEFAULT_LIMIT,
      },
    }
  );

  const handleClickShowMore = async () => {
    const nextPage = page + 1;

    return fetchMore({
      variables: {
        filter,
        page: nextPage,
        limit: DEFAULT_LIMIT,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) {
          return prev;
        }
        setPage(nextPage);
        const { total, items } = fetchMoreResult['listFeeds'];

        return Object.assign({}, prev, {
          listFeeds: {
            total,
            items: [...prev.listFeeds.items, ...items],
          },
        });
      },
    });
  };

  const handleClickScrollTop = () => window.scrollTo(0, 0);

  useEffect(() => {
    queryListFeed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBlogCards = (items) => {
    return items.map((item) => {
      return (
        <Grid item key={item.id}>
          <BlogCard item={item} width={290} />
        </Grid>
      );
    });
  };

  if (error || loading || !data) {
    return <Progress />;
  }

  const hasNext = data['listFeeds'].total > page * DEFAULT_LIMIT;

  return (
    <>
      <Grid container justify="space-around">
        {getBlogCards(data.listFeeds.items)}
      </Grid>
      {hasNext ? (
        <ShowMoreWrapper>
          <hr />
          <button onClick={handleClickShowMore}>
            <ExpandMoreIcon />
          </button>
        </ShowMoreWrapper>
      ) : userinfo.isNative || (data && data['listFeeds'].total > DEFAULT_LIMIT) ? (
        <ShowMoreWrapper>
          <hr />
          <button onClick={handleClickScrollTop}>
            <VerticalAlignTopIcon />
          </button>
        </ShowMoreWrapper>
      ) : (
        <></>
      )}
    </>
  );
};

export default BlogCardSection;
