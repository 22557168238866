import React from 'react';
import { Avatar } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: solid 1px lightgray;
  border-radius: 8px;
  overflow: hidden;
  width: 280px;
  box-sizing: border-box;

  .myProfileImage {
    width: 280px;
    height: 280px;
    background: #e9e9e9;
    border: 0px;
  }

  .urlLinkBtn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 30px);
    margin-top: 10px;
    margin-bottom: 30px;

    a {
      cursor: pointer;
      width: auto;
      margin-right: 8px;

      svg {
        width: 25px;
        height: 25px;
        color: rgb(96, 96, 96);
        transition: color 0.2s ease-in;
      }

      &:hover {
        svg {
          color: black;
        }
      }
    }

    a:last-child {
      margin-right: 0px;
      color: gray;
      font-size: 14px;
      transition: color 0.2s ease-in;

      &:hover {
        color: black;
      }
    }
  }

  .introduce {
    margin: 20px 20px 10px 20px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
  }

  .jobCareer {
    width: 240px;
    margin: 10px 20px 15px 20px;

    .careerTable {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      list-style: none;
      padding: 0px;

      .rowTitle {
        flex-shrink: 0;
        text-align: left;
        font-weight: bold;
        font-size: 15px;
        color: gray;
      }
      .rowValue {
        text-align: right;
        font-size: medium;
        word-break: keep-all;
      }
    }
  }

  .website {
    text-align: right;
    font-size: medium;
  }

  @media screen and (max-width: 768px) {
    border: none;
    border-radius: 0px;
    width: 100%;
    margin: 0 auto;

    .myProfileImage {
      margin-bottom: 6px;
      width: 200px;
      height: 200px;
      border-radius: 100%;
    }

    .introduce {
      width: 100%;
      background-color: #f1f1f1;
      border-radius: 8px;
      font-size: 15px;
      text-align: center;
      white-space: pre-wrap;
      box-sizing: border-box;
      padding: 10px;
    }

    .jobCareer {
      width: 100%;
      padding: 0px 7px;
      box-sizing: border-box;
    }

    .urlLinkBtn {
      width: 100%;
      padding: 0px 7px;
      margin-top: 0px;
      margin-bottom: 10px;
      box-sizing: border-box;
    }
  }
`;

const FellowCard = (fellowInfo) => {
  return (
    <Wrapper>
      {fellowInfo.profile_image ? (
        <img className="myProfileImage" src={fellowInfo.profile_image} alt="내 사진" />
      ) : (
        <Avatar variant={isMobile ? 'circle' : 'square'} className="myProfileImage" src={fellowInfo.profile_image} alt="내 사진" />
      )}
      <div className="introduce">{fellowInfo.introduce}</div>
      <div className="jobCareer">
        <ul className="careerTable">
          <li className="rowTitle">소속</li>
          <li className="rowValue">{fellowInfo.company}</li>
        </ul>
        <ul className="careerTable">
          <li className="rowTitle">직무</li>
          <li className="rowValue">{fellowInfo.position}</li>
        </ul>
        <ul className="careerTable">
          <li className="rowTitle">업종</li>
          <li className="rowValue">{fellowInfo.industry}</li>
        </ul>
        {fellowInfo.job && (
          <ul className="careerTable">
            <li className="rowTitle">직업</li>
            <li className="rowValue">{fellowInfo.job}</li>
          </ul>
        )}
      </div>
      <div className="urlLinkBtn">
        <div>
          {fellowInfo.instagram && (
            <a href={fellowInfo.instagram} target="_blank">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          )}
          {fellowInfo.facebook && (
            <a href={fellowInfo.facebook} target="_blank">
              <FontAwesomeIcon icon={faFacebook} />
            </a>
          )}
          {fellowInfo.linkedin && (
            <a href={fellowInfo.linkedin} target="_blank">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          )}
        </div>
        {fellowInfo.data.fellow.website && (
          <a
            className="website"
            href={fellowInfo.data.fellow.website}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'underline' }}
          >
            개인 웹사이트 보러가기 {'>'}
          </a>
        )}
      </div>
    </Wrapper>
  );
};

export default FellowCard;
