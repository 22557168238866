import React from 'react';
import styled from 'styled-components';
import { EVENTS, ReactGA } from 'block/LinkGA';
import { useHistory, useLocation } from 'react-router-dom';
import { useModalContext } from 'context/modal';
import { api_axios } from '../../api';
import { createAnonymous } from 'helpers';
import moment from 'moment';
import { Button } from '@material-ui/core';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 30px;
  border-radius: 10px;
  min-width: 250px;
  height: auto;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  .section {
    .content {
      h4 {
        margin: 0.5em 0;
        text-align: center;
      }
      p {
        font-size: 18px;
        margin: 0px 0px 20px 0px;
        text-align: center;
      }
      .desc {
        font-size: 15px;

        margin-bottom: 20px;
        word-break: keep-all;
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }
        b {
          color: #444444;
        }
        button {
          border: none;
          background-color: transparent;
          text-decoration: underline;
          color: #2a3384;
          font-weight: bold;
          font-size: 1em;
          float: right;
          &:hover {
            cursor: pointer;
          }
          &:focus {
            outline: none;
          }
        }
      }
      .buttons {
        float: right;
        display: flex;
        flex-direction: row;
        .button {
          background-color: #fddb00;
          font-size: 15px;
          font-weight: bold;
          padding: 8px 20px;
        }
        .button.sub {
          margin-right: 15px;
          background-color: #ffffff;
        }
        .button.main {
        }
      }
    }
  }
`;

const AnonymousPostModal = () => {
  const history = useHistory();
  const [modalState, , hide] = useModalContext();
  const { board_id, title, contents } = modalState.data;
  const userAnonymousName = createAnonymous(moment() + '');

  const handlePost = () => {
    ReactGA.event(EVENTS.community05);
    api_axios('post', 'board/' + board_id + '/posts/', {
      title: title,
      contents: contents,
      user_anonymous_name: userAnonymousName,
      is_anonymous: true,
    })
      .then((response) => {
        history.push('/board/' + board_id + '/post/' + response.id);
      })
      .catch(function (error) {
        console.log(error);
        alert('토픽 작성 실패');
      });

    hide();
  };
  const handelCancel = () => {
    hide();
  };

  return (
    <Wrapper>
      <div className="section">
        <div className="content">
          <p>
            <b>익명 게시글 작성</b>
          </p>
          <p className="desc">
            {!title ? (
              <div> 제목을 입력해주세요 </div>
            ) : !contents ? (
              <div>내용을 입력해주세요</div>
            ) : (
              <div>
                <b>{userAnonymousName}</b>로 게시물을 작성합니다.
              </div>
            )}
          </p>

          {title && contents ? (
            <div className="buttons">
              <Button className="button sub" onClick={handelCancel}>
                취소
              </Button>
              <Button className="button main" onClick={handlePost}>
                확인
              </Button>
            </div>
          ) : (
            <div className="buttons">
              <Button className="button sub" onClick={handelCancel}>
                확인
              </Button>
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default AnonymousPostModal;
