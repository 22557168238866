const job_cates = [
  {
    value: '금융/은행업',
    label: '금융/은행업',
  },
  {
    value: 'IT/정보통신업',
    label: 'IT/정보통신업',
  },
  {
    value: '판매/유통업',
    label: '판매/유통업',
  },
  {
    value: '제조/생산/화학업',
    label: '제조/생산/화학업',
  },
  {
    value: '교육업',
    label: '교육업',
  },
  {
    value: '건설업',
    label: '건설업',
  },
  {
    value: '의료/제약업',
    label: '의료/제약업',
  },
  {
    value: '미디어/광고업',
    label: '미디어/광고업',
  },
  {
    value: '숙박/음식점업',
    label: '숙박/음식점업',
  },
  {
    value: '부동산업',
    label: '부동산업',
  },
  {
    value: '사업 시설 관리 및 임대업',
    label: '사업 시설 관리 및 임대업',
  },
  {
    value: '법무/세무/회계업',
    label: '법무/세무/회계업',
  },
  {
    value: '연구개발/과학기술업',
    label: '연구개발/과학기술업',
  },
  {
    value: '문화/예술/디자인/스포츠/여가서비스업',
    label: '문화/예술/디자인/스포츠/여가서비스업',
  },
  {
    value: '서비스업',
    label: '서비스업',
  },
  {
    value: '공공행정/국방',
    label: '공공행정/국방',
  },
  {
    value: '기관/협회업',
    label: '기관/협회업',
  },
  {
    value: '시장조사/여론조사업',
    label: '시장조사/여론조사업',
  },
  {
    value: '농업/임업/어업/광업',
    label: '농업/임업/어업/광업',
  },
  {
    value: '구분 외 업종',
    label: '구분 외 업종',
  },
];

export default job_cates;
