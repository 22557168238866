import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSessionContext } from 'context/main';
import { LinkGA, EVENTS, ReactGA } from 'block/LinkGA';
import { useHistory, useLocation } from 'react-router-dom';
import { useModalContext } from 'context/modal';

const Wrapper = styled.div`
  background: #ffffff;
  outline: none;
  justify-content: flex-end;
  padding: 40px 30px;
  border-radius: 10px;
  display: flex;
  min-width: 320px;
  flex-direction: column;

  .title {
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
  .body {
    text-align: center;
    margin-top: 5px;
    font-size: 16px;
    color: #505050;
  }
`;

const JoinButton = styled.div`
  margin-top: 20px;
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  height: auto;
  border-radius: 4px;
  background-color: #f04e44;
  padding: 7px 15px;
  font-size: 16px;
  color: white;
  &:visited {
    color: white;
  }
  &:hover {
    cursor: pointer;
  }
`;

const LoginButton = styled.div`
  display: flex;
  width: auto;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: 5px;
  font-size: 14px;
  color: #555555;
  text-decoration: underline;
  &:visited {
    color: #555555;
  }
  &:hover {
    cursor: pointer;
  }
`;

const titleDict = {
  more: {
    text: '헤이조이스 멤버만 볼 수 있어요! 👀',
    eventNum: 'feed03',
  },
  search: {
    text: '헤이조이스 멤버만 쓸 수 있어요! 🔒',
    eventNum: 'feed07',
  },
  ask: {
    text: '헤이조이스 멤버만 쓸 수 있어요! 🔒',
    eventNum: 'feed05',
  },
};

const UnlockContentModal = () => {
  const history = useHistory();
  const [modalState, , hide] = useModalContext();
  const { type, label } = modalState.data;
  const eventNum = titleDict[type].eventNum;

  // userinfo.level === 'guest'
  const handleLogin = () => {
    hide();
  };
  const handleJoin = () => {
    hide();
  };

  return (
    <Wrapper>
      <span className="title">{titleDict[type].text}</span>
      <span className="body">
        1분 만에 회원가입하고
        <br />
        커리어 고민을 해결해 보세요!
      </span>
      <LinkGA
        path={'/join'}
        event={{
          ...EVENTS.signup01,
          label: label,
        }}
        location={{ pathname: '/feed/list' }}
      >
        <JoinButton onClick={handleJoin}>회원가입하기</JoinButton>
      </LinkGA>
      <LinkGA
        path={'/login'}
        event={{
          ...EVENTS.login01,
          label: label,
        }}
        location={{ pathname: '/feed/list' }}
      >
        <LoginButton onClick={handleLogin}>이미 멤버예요!</LoginButton>
      </LinkGA>
    </Wrapper>
  );
};

export default UnlockContentModal;
