import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { useMediaQuery } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Flicking from '@egjs/react-flicking';
import useAxios from 'hooks/useAxios';
import { EVENTS, LinkGA } from 'block/LinkGA';
import { program_dict } from 'helpers';
import ProgramCard from './ProgramCard';

const MIN_WIDTH = 900;

const Wrapper = styled.div`
  background-color: #fff;
  padding: 8px 0px 0px 15px;
  display: relative;

  .flexContainer {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }

  @media screen and (min-width: 935px) {
    padding: 6px 0px 6px 20px;

    .flexContainer {
      padding-right: 20px;
    }

    .flexContainer > div {
      margin-bottom: 20px;
    }
  }
`;

const SliderTitle = styled.h3`
  font-size: 22px;
  font-weight: 700;
  color: rgb(27, 28, 29) !important;
  margin: 20px 0px 20px 0px;

  @media screen and (max-width: 935px) {
    font-size: 18px;
  }
`;

const ProgramFlicking = (props) => {
  const { type, title, limit = 9, target = '_self' } = props;

  const programType = program_dict[type].path;
  const isDesktop = useMediaQuery(`(min-width:${MIN_WIDTH}px)`, {
    noSsr: true,
  });

  const { loading, error, data } = useAxios({
    url: `programs/?type=${programType}`,
    method: 'GET',
  });

  if (error) {
    return (
      <Wrapper>
        <Skeleton variant="rect" width={270} height={160} />
      </Wrapper>
    );
  }

  if (loading) {
    return (
      <Wrapper>
        <Skeleton width={100} />
        <Skeleton variant="rect" width={270} height={160} />
        <Skeleton width={270} />
        <Skeleton width={270} />
      </Wrapper>
    );
  }

  const listItems = data.results
    .slice(0, limit)
    .map((item) => (
      <ProgramCard className="listItem" key={item.id} type={type} item={item} width={isDesktop ? 280 : 270} />
    ));

  return (
    <Wrapper>
      {/** https://fontawesome.com/ */}
      <LinkGA
        target={`${target}`}
        path={`/program/${type}`}
        event={{
          ...EVENTS.program01,
          label: type,
        }}
      >
        <SliderTitle>
          {title} &nbsp;
          <span>
            <FontAwesomeIcon icon={faChevronRight} color="darkgray" />
          </span>
        </SliderTitle>
      </LinkGA>
      {isDesktop ? (
        <div className="flexContainer">{listItems}</div>
      ) : (
        <Flicking moveType={{ type: 'snap', count: 1 }} hanger={'0%'} anchor={'0%'} gap={20} zIndex={0} collectStatistics={false}>
          {listItems}
        </Flicking>
      )}
    </Wrapper>
  );
};

export default ProgramFlicking;
