import React, { Fragment, useState, useEffect, useCallback } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  RadioGroup,
} from '@material-ui/core/';
import { Button, styles } from '@heyjoyce/vibe';
import { Close as CloseIcon, KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { useModalContext } from 'context/modal';
import ReactGA from 'react-ga';
import { EVENTS } from 'block/LinkGA';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import Loading from 'block/Loading';
import { api_axios } from '../../api';
import { priceHelper, getStringDate, trackFinishedTransaction } from '../../helpers';
import noImg from '../../images/no_img.png';
import KakaopayImg from '../../images/pay-kakaopay.png';
import MyIamport from '../Iamport';
import styled, { css } from 'styled-components';
import './Order.scss';
import { useMerchantUid } from 'hooks/useMerchantUid';

const StyledListItem = styled(ListItem)`
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      span:first-child {
        color: #fddb00;
        font-weight: bold;
      }

      span:last-child {
        color: gray;
        font-size: 0.8em;
      }
    }
  }
`;

const StyledButton = styled(Button)`
  width: calc(50% - 6px);
  margin: 4px 0;

  @media screen and (max-width: 430px) {
    width: 50%;
    margin: 0;
  }

  @media screen and (max-width: 320px) {
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
  }

  ${({ isSelected }) =>
    isSelected
      ? css`
        box-shadow: inset 0 0 0 2px ${styles.color.secondary} !important;
        color: ${styles.color.secondary};
        font-weight: bold;
      `
      : css`
      `
  }
`

const KakaopayText = styled.div`
  display: inline;
  vertical-align: middle;

  @media screen and (max-width: 320px) {
    display: none;
  }
`

const StyledCloseIcon = styled(CloseIcon)`
  height: auto !important;
  vertical-align: middle;

  @media screen and (max-width: 430px) {
    width: 24px !important;
  }
`

const Payment = () => {
  const [modalState, , hide] = useModalContext();
  const { program, programDate, userInfo } = modalState.data;

  const [discount_price, setDiscountPrice] = useState(0);
  const [isVisibleCoupon, setIsVisibleCoupon] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [coupons, setCoupons] = useState([]);
  const [selectedCouponName, setSelectedCouponName] = useState('');
  const [payMethod, setPayMethod] = useState('card');
  const [pg, setPg] = useState('danal');
  const [progress, setProgress] = useState(false);
  const [loading, merchantUid] = useMerchantUid({ productId: program.id, productType: program.type });

  if (!loading && !merchantUid) {
    window.alert('결제에 불편을 드려 죄송해요. 잠시 후 다시 시도해주세요.\n문제가 지속되면 contact@heyjoyce.com 으로 문의해주세요.');
    hide();
  }

  // caculate a price
  const userPrice = program.associate_member_price;
  let totalPrice = userPrice - discount_price;
  totalPrice = Math.max(totalPrice, 0);

  const getAvailableCoupons = useCallback(() => {
    api_axios('get', 'availableCoupons/?program_id=' + program.id)
      .then((coupons) => {
        setCoupons(coupons);
      })
      .catch((e) => console.log('ERROR', e));
  }, [program.id]);

  useEffect(() => {
    getAvailableCoupons();
    return () => {};
  }, [getAvailableCoupons]);

  if (progress) {
    return <Loading emoji={'lock'} title={'안전하게 결제 진행 중'} />;
  }

  const handleListItemClick = (value) => {
    setDiscountPrice(value.price);
    setCouponId(value.coupon_id);
    setIsVisibleCoupon(false);
    setSelectedCouponName(value.coupon_name ? value.coupon_name : '');
  };

  const toggleCoupon = () => {
    setIsVisibleCoupon(!isVisibleCoupon);
  };

  return (
    <div className="orderContainer">
      <div className="buttonClose">
        <StyledCloseIcon 
          onClick={() => {
            ReactGA.event(EVENTS.program05);
            return hide();
          }}
          color="action"
          fontSize="large"
        />
      </div>
      <div className="programInfo">
        <h3>결제 프로그램 정보</h3>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            {program.thumbnails.length > 0 ? <img src={program.thumbnails[0].thumbnail} alt="" /> : <img src={noImg} alt="" />}
          </Grid>
          <Grid item xs={9}>
            {program.type !== 'digitalcontents' && <div className="program_date">{programDate}</div>}
            <div className="program_name">{program.name}</div>
          </Grid>
        </Grid>
      </div>
      <div className="priceWrap">
        <h3>결제 정보</h3>
        <div className="priceLine">
          <div className="label">정가</div>
          <div className="price">{priceHelper(userPrice)}</div>
        </div>
        <div className="priceLine noBorderBottom">
          <div className="label">할인금액</div>
          <span
            className="package"
            onClick={() => {
              hide();
              ReactGA.event({ ...EVENTS.package01, label: 'program_payment' });
              window.open('/package');
            }}
          >
            <ConfirmationNumberIcon className="icon"></ConfirmationNumberIcon>성장패키지로 할인 혜택 받기
          </span>
          <div className={userPrice > 0 ? 'discount price' : 'price'}>{priceHelper(discount_price)}</div>
        </div>
        <div className="couponSelectbox">
          <small className="refund_policy coupon_policy">정가보다 쿠폰 금액이 크더라도 반환되지 않습니다.</small>
          <div className={`selectedCoupon ${isVisibleCoupon ? 'show' : ''}`} onClick={toggleCoupon}>
            {selectedCouponName || '쿠폰 선택'}
            {isVisibleCoupon ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </div>
          <List className={`couponListWrapper ${isVisibleCoupon ? 'show' : ''}`}>
            <ListItem button onClick={() => handleListItemClick({ price: 0, coupon_id: null, type: '' })} className="coupon">
              <ListItemText primary="쿠폰 사용 안 함" />
            </ListItem>
            {coupons.map((coupon) => (
              <StyledListItem
                button
                onClick={() =>
                  handleListItemClick({
                    price: coupon.discount_price,
                    coupon_id: coupon.id,
                    coupon_name: coupon.coupon_name,
                  })
                }
                key={coupon.id}
                className="coupon"
              >
                <div className="content">
                  <div>
                    <span>
                      {['projectjoyce', 'digitalcontents', 'conjoyce', 'freepass'].includes(coupon.type) ? '무료' : priceHelper(coupon.discount_price)}
                    </span>
                    <span>{`~ ${getStringDate(coupon.expire_at)}`}</span>
                  </div>
                  <span>{coupon.coupon_name}</span>
                </div>
              </StyledListItem>
            ))}
          </List>
        </div>
        <div className="priceLine">
          <div className="label">총 결제금액</div>
          <div className="price total">{priceHelper(totalPrice)}</div>
        </div>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {totalPrice !== 0 ? (
                <Fragment>
                  <h3>결제 방법</h3>
                  <RadioGroup
                    className="paymentMethods"
                    aria-label="Pay Method"
                    name="payMethod"
                    value={payMethod}
                    row={true}
                  >
                    <StyledButton
                      appearance="outline"
                      label="신용카드"
                      onClick={() => {
                        setPayMethod('card')
                        setPg('danal');
                      }}
                      isSelected={payMethod === 'card'}
                    />
                    <StyledButton
                      appearance="outline"
                      label="실시간 계좌이체"
                      onClick={() => {
                        setPayMethod('trans')
                        setPg('danal');
                      }}
                      isSelected={payMethod === 'trans'}
                    />
                    <StyledButton
                      appearance="outline"
                      label="가상계좌 이체"
                      onClick={() => {
                        setPayMethod('vbank')
                        setPg('danal');
                      }}
                      isSelected={payMethod === 'vbank'}
                    />
                    {program.type === 'event' && (
                      <StyledButton
                      className="kakaopayButton"
                      appearance="outline"
                      label={
                        <Fragment>
                          <KakaopayText>카카오페이</KakaopayText>
                          <img
                            src={KakaopayImg}
                            alt="kakaopay-image"
                            style={{ width: '48px', verticalAlign: 'middle' }}
                          />
                        </Fragment>
                      }
                      onClick={() => {
                        setPayMethod('kakaopay');
                        setPg('kakaopay');
                      }}
                      isSelected={payMethod === 'kakaopay'} />
                    )}
                  </RadioGroup>
                </Fragment>
              ) : (
                ''
              )}
            </Grid>
            <Grid item xs={12} className="buttonContainer">
              {totalPrice > 0 ? (
                <div className="buttons">
                  <MyIamport
                    className="btn_pay"
                    type="program"
                    pg={pg}
                    transactionId={merchantUid}
                    payMethod={payMethod}
                    price={totalPrice}
                    userinfo={userInfo}
                    programInfo={program}
                    coupon_id={couponId}
                    handleClose={() => {
                      ReactGA.event(EVENTS.program05);
                      return hide();
                    }}
                    handlePrev={() => setProgress(true)}
                    handleNext={(muid) => {
                      trackFinishedTransaction({
                        amount: totalPrice,
                        productType: program.type,
                        productId: program.id,
                        productName: program.name,
                      });
                      window.location.href = '/finish-order/program/' + muid + '?type=' + program.type;
                      return hide();
                    }}
                  />
                </div>
              ) : (
                <div className="buttons">
                  <Button
                    className="buttonPay"
                    appearance="secondary"
                    label="결제하기"
                    onClick={async (e) => {
                      setProgress(true);
                      try {
                        await api_axios('POST', 'pay/', {
                          imp_uid: 'notimp',
                          merchant_uid: merchantUid,
                          coupon_id: couponId,
                        });
                        trackFinishedTransaction({
                          amount: totalPrice,
                          productType: program.type,
                          productId: program.id,
                          productName: program.name,
                        });
                        window.location.href = '/finish-order/program/' + merchantUid + '?type=' + program.type;
                      } catch (e) {
                        window.alert('결제에 불편을 드려 죄송해요 contact@heyjoyce.com 으로 문의해주세요.');
                      }
                      return hide();
                    }}
                  />
                </div>
              )}
              <small className="refund_policy rules">
                프로그램 환불 규정은{' '}
                <a href="/policy/refund" target="refund">
                  이용 규정
                </a>
                을 참고해주세요.
              </small>
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default Payment;
