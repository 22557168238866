import React, { useEffect, useCallback } from 'react';
import { Route, Switch, useLocation, withRouter } from 'react-router-dom';
import loadable from '@loadable/component';
import { hot } from 'react-hot-loader/root';
import Modal from '@material-ui/core/Modal';
import SendBird from 'sendbird';
import { withSession, useSessionContext } from 'context/main';
import { MODALS, useModalContext, withModalProvider } from 'context/modal';
import Nav from 'component/Nav';
import Home from 'container/Home';
import InvitationRouter from 'container/Invitation/InvitationRouter';
import CouponPackage from 'container/Membership/CouponPackage';
import component404 from './component/404';
import Footer from './component/Footer';
import About from './container/About/About';
import Booking from './container/Booking';
import EmbedForm from './container/EmbedForm';
import FinishOrder from './container/FinishOrder/FinishOrder';
import FellowPage from './container/FellowPage';
import Notice from './container/Notice/Notice';
import Policy from './container/Policy/Policy';
import Calendar from './container/Program/Calendar';
import Space from './container/Space/Space';
import Chat from './container/Chat/ChatList';
import SendBirdWidget from './sendbird/js/widget';
import { AVAILABLE_CHAT_LEVELS } from './sendbird/js/consts';
import NewsLetter from './container/NewsLetter/NewsLetter';
import Flag from './container/Flag';
import FeedWriteModal from './container/Modal/FeedWriteModal';
import MemberProfile from './container/MemberProfile';
import Payment from './component/Program/Payment';
import ShareModal from './container/Modal/ShareModal';
import UnlockContentModal from './container/Modal/UnlockContentModal';
import AnonymousPostModal from './container/Modal/AnonymousPostModal';
import FirstProgramLikeModal from './container/Modal/FirstProgramLikeModal';
import ResetPasswordModal from './container/Modal/ResetPasswordModal';
import FindIdModal from './container/Modal/FindIdModal';
import MarketingModal from './container/Modal/MarketingModal';
import WebinarInModal from './container/Modal/WebinarInModal';
import MarketingUpdateInfoModal from './container/Modal/MarketingUpdateInfoModal';
import PackageOrderModal from './container/Modal/PackageOrderModal';
import ImageDetailModal from './component/Chat/ImageDetailModal';
import PromotionModal from './container/Modal/PromotionModal';
import InvitationModal from './container/Modal/InvitationModal';
import MoimPreSurveyModal from './container/Modal/MoimPreSurveyModal';
import MyProgramReceiptModal from 'container/Modal/MyProgramReceiptModal';
import ActionModal from './container/Modal/ActionModal';
import JoinModal from './container/Modal/JoinModal';
import { postMessage } from './ReactNativeWebView';
import MyCertificatesModal from 'container/Modal/MyCertificatesModal';
import DiconInfoModal from 'container/Modal/DiconInfoModal';
import ChannelService from 'channeltalk/ChannelService';
import WelcomeModal from 'container/Modal/WelcomeModal';
import ComeBackModal from 'container/Modal/ComeBackModal';
import LecturePreviewModal from 'container/Modal/LecturePreviewModal';
import SearchResult from 'container/Search/SearchResult';
import { SearchBarProvider } from 'context/searchBar';
import { base64 } from 'helpers';
import Blog from './container/Blog';
import PushAgreeModal from 'container/Modal/PushAgreeModal';
import LoadingModal from 'container/Modal/LoadingModal';
import SignInModal from 'container/Modal/SignInModal';

const Auth = loadable(() => import('container/Auth'));
const SignIn = loadable(() => import('container/SignIn/SignIn'));
const SignUp = loadable(() => import('container/SignIn/SignUp'));
const MyPageRouter = loadable(() => import('container/MyPage/MyPageRouter'));
const Program = loadable(() => import('container/Program/Program'));
const Board = loadable(() => import('container/Board/Board'));
const Community = loadable(() => import('container/Community/Community'));
const Feed = loadable(() => import('container/Feed'));
const Promotion = loadable(() => import('container/Promotion/ConJoyceRouter'));
const WebinarRouter = loadable(() => import('container/Webinar/WebinarRouter'));

const CHANNELTALK_HIDE_PAGES = [
  '/community',
  '/feed',
  '/profile',
  '/board',
  '/program/event/',
  '/program/moim/',
  '/program/digitalcontents/',
  '/mypage',
  '/invitation',
  '/notice',
  '/webinar',
  '/booking',
  '/mypage/edit-profile',
  ...(window.navigator && window.navigator.userAgent && window.navigator.userAgent.toLowerCase().includes('com.heyjoyce.native')
    ? ['/']
    : []),
];

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};

const RoutedScrollToTop = withRouter(ScrollToTop);

const MySendBird = () => {
  const [, userinfo] = useSessionContext();

  useEffect(() => {
    if (!userinfo.id) {
      return;
    }
    if (AVAILABLE_CHAT_LEVELS.includes(userinfo.level)) {
      window.SendBird = SendBird;
      SendBirdWidget.startWithConnect(process.env.REACT_APP_SENDBIRD_APPID, userinfo);
    }
  }, [userinfo]);

  if (userinfo.level === 'guest') {
    return <div />;
  }

  // return <div id="sb_widget" />;
  return <div />;
};

function AppRouter() {
  const location = useLocation();

  const [modalState, , hide] = useModalContext();
  const [, userinfo] = useSessionContext();

  useEffect(() => {
    const { id, username = '', phone_number = '', email = '' } = userinfo;
    const { pathname } = location;

    const isDebug = process.env.NODE_ENV === 'development';

    postMessage({ didMount: true });

    // Boot Channel as an user
    ChannelService.boot({
      pluginKey: 'e2f5b792-aa19-4f67-a265-2bb360360985',
      customLauncherSelector: '.channeltalkButton',
      zIndex: 99997,
      ...(!isDebug &&
        id && {
          memberId: base64.urlEncode(id.toString()),
          profile: {
            userId: id.toString(), // Add user id to profile data
            email: email,
            name: username,
            mobileNumber: phone_number,
          },
        }),
    });

    renderChannelButton(pathname);

    return () => {
      // Shutdown Channel
      ChannelService.shutdown();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userinfo]);

  useEffect(() => {
    const { pathname } = location;
    renderChannelButton(pathname);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const renderChannelButton = useCallback((pathname) => {
    if (CHANNELTALK_HIDE_PAGES.some((url) => pathname.includes(url))) {
      ChannelService.hideChannelButton();
    } else {
      ChannelService.showChannelButton();
    }
  }, []);

  // ref. https://mui.com/api/modal/
  const handleCloseModal = (event, reason) => {
    if (modalState.key === MODALS.comeBack && reason === 'backdropClick') {
      return;
    }
    hide();
  };

  return (
    <RoutedScrollToTop>
      {/* Header */}
      <Nav />
      {/* Contents */}
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/login/" component={SignIn} />
        <Route path="/join/" component={SignIn} />
        <Route path="/signin/" component={SignIn} />
        <Route path="/signup/" component={SignUp} />
        <Route path="/mypage/" component={MyPageRouter} />
        <Route path="/program/" component={Program} />
        <Route path="/board/" component={Board} />
        <Route path="/community/" component={Community} />
        <Route path="/feed/" component={Feed} />
        <Route path="/webinar/" component={WebinarRouter} />
        <Route path="/calendar/" component={Calendar} />
        <Route path="/booking/" component={Booking} />
        <Route path="/about/" component={About} />
        <Route path="/space/" component={Space} />
        <Route path="/profile/:fellow_id" component={FellowPage} />
        <Route path="/notice/" component={Notice} />
        <Route path="/policy/" component={Policy} />
        <Route path="/finish-order/" component={FinishOrder} />
        <Route path="/newsletter/" component={NewsLetter} />
        <Route path="/chat-flag/" component={Flag} />
        <Route path="/invitation/" component={InvitationRouter} />
        <Route path="/promotion/" component={Promotion} />
        <Route path="/conjoyce/" component={Promotion} />
        <Route path="/package/" component={CouponPackage} />
        <Route path="/blog/" component={Blog} />
        <Route path="/discovery/" component={SearchResult} />
        <Route exact path="/chat/" component={Chat} />
        <Route
          path="/pages/form/"
          component={() => (
            <EmbedForm src="https://docs.google.com/forms/d/e/1FAIpQLSfLaUxZBZtTw1-NsRwB47hMT-Y0H_-GQme3YKNM0o2vbeTzCg/viewform?embedded=true" />
          )}
        />
        <Route component={component404} />
      </Switch>
      {/* Footer and Modal Components */}
      <Footer />
      <MySendBird />
      {/* <NewChat /> */}
      <Modal className="modalContainer" open={!!modalState.key && !modalState.hasParent} onClose={handleCloseModal}>
        <>
          {modalState.key === MODALS.action && <ActionModal />}
          {modalState.key === MODALS.join && <JoinModal />}
          {modalState.key === MODALS.profile && <MemberProfile />}
          {modalState.key === MODALS.feedWrite && <FeedWriteModal />}
          {modalState.key === MODALS.payment && <Payment />}
          {modalState.key === MODALS.share && <ShareModal />}
          {modalState.key === MODALS.imageDetail && <ImageDetailModal />}
          {modalState.key === MODALS.packageOrder && <PackageOrderModal />}
          {modalState.key === MODALS.promotion && <PromotionModal />}
          {modalState.key === MODALS.invitation && <InvitationModal />}
          {modalState.key === MODALS.moimPreSurvey && <MoimPreSurveyModal />}
          {modalState.key === MODALS.unlockContent && <UnlockContentModal />}
          {modalState.key === MODALS.anonymousPost && <AnonymousPostModal />}
          {modalState.key === MODALS.firstProgramLike && <FirstProgramLikeModal />}
          {modalState.key === MODALS.marketingAgree && <MarketingModal />}
          {modalState.key === MODALS.pushAgree && <PushAgreeModal />}
          {modalState.key === MODALS.webinarIn && <WebinarInModal />}
          {modalState.key === MODALS.marketingUpdate && <MarketingUpdateInfoModal />}
          {modalState.key === MODALS.myProgramReceipt && <MyProgramReceiptModal />}
          {modalState.key === MODALS.myCertificates && <MyCertificatesModal />}
          {modalState.key === MODALS.diconInfo && <DiconInfoModal />}
          {modalState.key === MODALS.welcome && <WelcomeModal />}
          {modalState.key === MODALS.comeBack && <ComeBackModal />}
          {modalState.key === MODALS.lecturePreview && <LecturePreviewModal />}
          {modalState.key === MODALS.resetPassword && <ResetPasswordModal />}
          {modalState.key === MODALS.findId && <FindIdModal />}
          {modalState.key === MODALS.loading && <LoadingModal />}
          {modalState.key === MODALS.signIn && <SignInModal />}
        </>
      </Modal>
    </RoutedScrollToTop>
  );
}

const withUserContext = SearchBarProvider(withSession(AppRouter));

export default process.env.NODE_ENV === 'development' ? hot(withModalProvider(withUserContext)) : withModalProvider(withUserContext);
