import React from 'react';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';
import { CardMedia } from '@material-ui/core';
import { getDiscountRate } from '../../helpers';
import { Label, styles } from '@heyjoyce/vibe';
import { EVENTS, ReactGA } from 'block/LinkGA';

const DEFAULT_WIDTH = '270px';
const DEFAULT_HEIGHT = '160px';

const StyledMedia = styled(CardMedia)`
  height: ${(props) => (props.height ? props.height : DEFAULT_HEIGHT)};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
  overflow: hidden;
  transition: transform 0.3s ease;

  ${(props) =>
    props.shade === 'true' &&
    `
    &:after {
      content: '';
      background: #00000077;
      width: 100%;
      padding-bottom: 100%;
      border-radius: 0px;
      position: absolute;
      left: 0;
      top: 0;
    }
  `}

  @media screen and (min-width: 935px) {
    width: 100%;
    height: 100%;
    background-position: 50% 50%;
  }
`;

const StyledDiscountLabel = styled(Label)`
  position: absolute;
  left: 8px;
  top: 8px;
  font-size: 14px;
  font-weight: bold;
  min-height: 25px;
`;

const StyledTextLabel = styled(Label)`
  min-height: 25px;
  background-color: transparent;
  padding: 0px;
  font-size: 12px;
  font-family: 'Noto Sans KR', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Helvetica Neue', 'Helvetica', 'Arial', 'Noto', 'Apple Gothic',
    'MalgunGothic', sans-serif;
`;

const StyledActionArea = styled.div`
  width: 100%;
  display: block;
  text-align: inherit;
  background-color: #eee;
  position: relative;
  text-decoration: none;
  align-self: center;
  overflow: hidden;
  border-radius: 4px;

  @media screen and (min-width: 935px) {
    width: ${(props) => (props.width ? props.width : DEFAULT_WIDTH)};
    height: ${(props) => (props.height ? props.height : DEFAULT_HEIGHT)};
  }

  &:hover {
    ${StyledMedia} {
      transform: scale(1.05);
    }
  }

  span {
    margin-right: 6px;
    padding: 2px 6px;
    font-size: 14px;
    text-align: center;
    vertical-align: top;
    border-radius: 3px;
    font-weight: 700;
    white-space: nowrap;
    position: absolute;
    left: 8px;
    bottom: 8px;
  }
`;

const FlickerItem = styled.div`
  width: ${(props) => (props.width ? props.width : DEFAULT_WIDTH)};
  border: 0px;
  box-shadow: none;
  overflow: visible;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 4px;
  padding-bottom: 17px;

  .description {
    margin: 6px 0px 0px;

    h3 {
      padding: 2px 0px 3px;
      margin: 0px;
      color: rgb(61, 61, 61);
      letter-spacing: -0.02em;
      font-size: 18px;
      font-weight: 500;
      color: #2d2a26;
      word-break: keep-all;
    }

    p {
      padding: 0px 2px;
      margin: 2px 0px;
      font-size: 13px;
      color: rgb(133, 138, 141);
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .tag {
      margin-right: 4px;
      margin-top: -3px;
      font-size: 12px;
      &::after {
        content: '|';
        margin-left: 4px;
        color: rgb(133, 138, 141);
        font-weight: normal;
      }
    }

    .tag:last-child {
      &::after {
        content: '';
        margin-left: 0px;
      }
    }
  }

  .price {
    margin: -2px 0px 10px;

    span {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .date {
    span {
      margin-right: 6px;
      padding: 0px 8px 4px 6px;
      font-size: 13px;
      text-align: center;
      vertical-align: top;
      border-radius: 3px;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 935px) {
    padding-bottom: 17px;

    .price,
    .description,
    .date {
      width: ${(props) => (props.width ? props.width : DEFAULT_WIDTH)};
      align-self: flex-start;
    }
  }
`;

const Program = (props) => {
  const history = useHistory();

  const { type, item: programDetail, width } = props;
  const { id, thumbnails, name, associate_member_register_status, register_end_at, meets = [], tags = [], test } = programDetail;
  const terminated = associate_member_register_status === 'end';

  const responsiveWidth = width + 'px';
  const responsiveHeight = Math.floor(width * 0.59) + 'px';

  if (history.location.pathname.includes(id)) {
    // 프로그램 상세페이지에서 동일한 ID의 프로그램카드는 노출하지 않는다
    return <div />;
  }

  const handleClick = () => {
    const path = `/program/${type}/${id}`;

    const pathname = history.location.pathname;
    if (pathname === '/') {
      // 메인 영역
      ReactGA.event({ ...EVENTS.program16, label: type });
      return history.push(path);
    } else if (pathname === '/package') {
      // 성장패키지 영역
      ReactGA.event({ ...EVENTS.program16, label: `${type}_package` });
      return window.open(path);
    } else if (['/program/event', '/program/digitalcontents', '/program/moim'].includes(pathname)) {
      // 프로그램 리스트 영역
      ReactGA.event({ ...EVENTS.program16, label: `${type}_list` });
      return (window.location.href = path);
    }
    // 프로그램 상세 영역
    ReactGA.event({ ...EVENTS.program16, label: '상세추천섹션' });
    return history.push(path);
  };

  const days = moment(register_end_at).diff(new Date(), 'days');
  const discountRate = Math.max(0, getDiscountRate(programDetail.member_price, programDetail.associate_member_price));
  const discountString = programDetail.associate_member_price === 0 || discountRate >= 100 ? '무료!' : discountRate + '% 할인';

  return (
    <FlickerItem key={id} width={responsiveWidth} onClick={() => handleClick()}>
      <StyledActionArea width={responsiveWidth} height={responsiveHeight}>
        <StyledMedia
          component="div"
          image={thumbnails[0] ? thumbnails[0].thumbnail : ''}
          title={name}
          height={responsiveHeight}
          shade={terminated.toString()}
        />
        {(discountRate > 0 || programDetail.associate_member_price === 0) &&
          (['event', 'moim', 'digitalcontents'].includes(type) && associate_member_register_status === 'opened' ? (
            <>
              <StyledDiscountLabel label={discountString} size="small" labelColor="white" backgroundColor="rgb(240, 78, 68)" />
            </>
          ) : (
            <StyledDiscountLabel label={discountString} size="small" labelColor="white" backgroundColor={styles.color.dark} />
          ))}
      </StyledActionArea>

      <div className="description">
        <h3>{name}</h3>

        {tags && (
          <p>
            {['digitalcontents'].includes(type) && (
              <StyledTextLabel className="tag" size="small" label={`VOD`} labelColor={'#2A3384'} backgroundColor={'#f3f4ff'} />
            )}
            {['event', 'moim'].includes(type) && associate_member_register_status === 'opened' && (
              <>
                {[0, 1].includes(days) && (
                  <StyledTextLabel
                    className="tag"
                    size="small"
                    label="마감 임박"
                    labelColor="rgb(240, 78, 68)"
                    backgroundColor={'transparent'}
                  />
                )}
                {1 < days && days <= 7 && (
                  <StyledTextLabel
                    className="tag"
                    size="small"
                    label={moment(register_end_at).fromNow().replace('후', '후 마감')}
                    labelColor="rgb(240, 78, 68)"
                    backgroundColor={'transparent'}
                  />
                )}
                {meets.map((meet, index) => (
                  <StyledTextLabel
                    className="tag"
                    key={index}
                    size="small"
                    label={`${moment(meet.start_at).format('MM/DD (ddd)')}`}
                    labelColor="#005950"
                    backgroundColor={'transparent'}
                  />
                ))}
              </>
            )}
            {tags.map(({ tag }, index) => (
              <span key={index} className="tag" role="img" aria-label="">
                {tag}
              </span>
            ))}
            {['event', 'moim'].includes(type) && associate_member_register_status !== 'opened' && (
              <>
                {associate_member_register_status === 'register end' && (
                  <StyledTextLabel className="tag" label="마감" backgroundColor={'transparent'} labelColor="#666" />
                )}
                {terminated && <StyledTextLabel className="tag" label="종료되었습니다" backgroundColor={'transparent'} labelColor="#666" />}
              </>
            )}
          </p>
        )}
      </div>
      <div className="date">{test && <StyledTextLabel label="👀 관리자 전용" backgroundColor="transparent" labelColor="#666" />}</div>
    </FlickerItem>
  );
};

export default Program;
