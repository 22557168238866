import React, { useRef, useCallback, useState } from 'react';
import COLORS from 'theme';
import styled, { css } from 'styled-components';
import throttle from 'lodash/throttle';
import { SendBirdAction } from './SendBirdAction';
import SendIcon from '@material-ui/icons/Send';
import AttachmentIcon from '@material-ui/icons/Attachment';

const INPUT_PLACEHOLDER = '메세지를 입력해 주세요';
const NOT_SUPPORTED_FILE = ['heic'];

const MobileSendStyle = css`
  display: flex;
  min-width: unset;
  margin-left: 2px;
  background-color: transparent;
  padding: 0px 1px 0px 12px;
  color: #b7b7b7;
`;

const MobileHasValueButtonStyle = css`
  color: #fddb00;
  svg {
    transform: rotate(-45deg);
  }
`;

const FileImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: contain;
`;

const AddFileButton = styled.div`
  border: none;
  background-color: transparent;
  transform: rotate(-45deg);
  color: #464646;
  padding-top: 5px;
  padding-right: 12px;

  svg {
    width: 1.1em;
    height: 1.1em;
  }

  &:focus {
    outline: none;
  }

  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    padding-right: 8px;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    padding-right: 8px;
  }
`;

const SendButton = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.PRIMARY_COLOR};
  min-width: 100px;
  border-radius: 7px;
  margin-left: 12px;
  transition: background-color 0.1s ease-in-out, color 0.2s ease-in-out;
  padding: 0px 10px;

  svg {
    margin-bottom: 5px;
    transition: transform 0.2s ease-in-out;
  }

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    ${MobileSendStyle}
    ${({ hasValue }) => hasValue && MobileHasValueButtonStyle}
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    ${MobileSendStyle}
    ${({ hasValue }) => hasValue && MobileHasValueButtonStyle}
  }

  &:hover {
    cursor: pointer;
  }

  &:active {
    background-color: #ffe639;
  }
`;

const TextInput = styled.textarea`
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  width: 100%;
  font-size: 1em;
  border-radius: 7px;
  border: 2px solid transparent; /* this */
  padding: 12px 13px 10px 13px;
  resize: none;
  box-sizing: border-box;
  background-color: #ededed;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  transition: background-color 0.1s ease-in-out, border 0.2s ease-in-out;

  &:focus {
    outline: none;
    border: 2px solid #fddb00;
    background-color: white;
  }

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    padding: 12px 8px 10px;
    margin: 0px 3px;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    padding: 12px 8px 10px;
    margin: 0px 3px;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  position: sticky;
  justify-content: space-between;
  bottom: 0;
  background-color: white;
  height: 45px;
  padding: 10px 18px 10px 15px;

  @media screen and (max-width: 375px) {
    font-size: 0.9em;
  }

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    height: 50px;
    padding: 6px 12px 0px 11px;
    border-top: 1px solid #f0f0f0;
  }

  @media screen and (max-width: 450px) and (max-height: 850px) and (orientation: portrait) {
    height: 45px;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    height: 45px;
    padding: 6px 12px 0px 11px;
    border-top: 1px solid #f0f0f0;
  }
`;

const ChatInput = ({ channel, addMessage, disabled, showChatAlert, isMobileMode, setIsMobileInputFocused }) => {
  const channelRef = useRef();

  channelRef.current = channel;

  const [inputValue, setInputValue] = useState('');

  const sendCurrentMessage = useCallback(
    throttle(async () => {
      if (inputValue) {
        try {
          setIsMobileInputFocused(false);
          const tempMessage = SendBirdAction.getInstance().sendUserMessage({
            channel: channelRef.current,
            message: inputValue,
            handler: (message, error) => {
              if (error) {
                // muted client
                if (error.code === 900041) {
                  addMessage(message, false, true);
                  setInputValue('');
                }
                console.log('=== SendMessageError', error);
                return;
              }
              addMessage(message, false, true);
              setInputValue('');
            },
          });
        } catch (error) {
          console.log('=== sendCurrentMessage error : ', error);
        }
      }
    }, 500),
    [channel, inputValue, setInputValue]
  );

  const sendSelectedFile = async () => {
    const fileInput = document.getElementById('chat-file-input');
    const sendFile = fileInput.files[0];

    if (!channel) {
      alert('채팅이 연결되지 않았습니다.');
      fileInput.value = '';
      return;
    }

    if (NOT_SUPPORTED_FILE.some((v) => sendFile.type.includes(v))) {
      showChatAlert(`${sendFile.type} 타입 파일은 전송할 수 없습니다`);
      fileInput.value = '';
      return;
    }

    if (sendFile && channel) {
      const tempMessage = SendBirdAction.getInstance().sendFileMessage({
        channel: channel,
        file: sendFile,
        handler: (message, error) => {
          if (error) {
            console.log('=== SendMessageError', error);
            return;
          }

          addMessage(message, false, true);
        },
      });
    }
  };

  const handleBrowserKeydown = (e) => {
    if (e.keyCode === 13) {
      // 내용이 없을 때 enter를 눌러도 반응이 없도록
      if (!e.target.value) {
        e.preventDefault();
        return false;
      }

      // shift+enter 시 줄바꿈
      if (e.shiftKey) {
        return false;
      }

      e.preventDefault();
      sendCurrentMessage();
    }
  };

  const handleKeyDown = (e) => {
    if (!isMobileMode) {
      handleBrowserKeydown(e);
    }
  };

  const handleClickAddFile = () => {
    document.querySelector('input[type="file"]').click();
  };

  const handleFocusInput = () => {
    setIsMobileInputFocused(true);
  };

  const handleBlurInput = () => {
    if (inputValue === '') {
      setIsMobileInputFocused(false);
    }
  };

  return (
    <InputWrapper>
      <AddFileButton onClick={handleClickAddFile}>
        <AttachmentIcon />
        {/* <FileImage src={plusIcon} alt="plus-file" /> */}
        <input type="file" id="chat-file-input" style={{ display: 'none' }} onChange={sendSelectedFile} />
      </AddFileButton>
      <TextInput
        value={inputValue}
        disabled={disabled}
        placeholder={INPUT_PLACEHOLDER}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyDown={handleKeyDown}
        onFocus={handleFocusInput}
        onBlur={handleBlurInput}
      />
      <SendButton onClick={sendCurrentMessage} hasValue={inputValue !== ''}>
        <SendIcon />
      </SendButton>
    </InputWrapper>
  );
};

export default ChatInput;
