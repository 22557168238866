import React, { Component, useState, useEffect, useRef, useCallback } from 'react';
import { useContext } from 'context/main';
import { SendBirdAction } from './SendBirdAction';
import { SendBirdEvent } from './SendBirdEvent';
import { SendBirdConnection } from './SendBirdConnection';
import { SendBirdChatEvent } from './SendBirdChatEvent';
import { debounce } from 'lodash';
import ChatInput from './ChatInput';
import ChatMessage from 'component/Chat/ChatMessage';
import styled from 'styled-components';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import CloseIcon from '@material-ui/icons/Close';
import { useOpenChannel } from 'container/Webinar/OpenChannelContext';

const sb = new SendBirdAction();

const StyledArrowIcon = styled(ArrowDownwardIcon)`
  font-size: 18px;
  margin-right: 5px;
  margin-top: 1px;
`;

const StyledCloseIcon = styled(CloseIcon)`
  font-size: 18px;
  margin-left: 4px;

  &:hover {
    cursor: pointer;
  }
`;

const UnreadCountWrapper = styled.div`
  position: -webkit-sticky; /* Safari */
  position: absolute;
  width: fit-content;
  top: -16px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  padding: 4px 20px 4px 15px;
  background-color: white;
  border: 2px solid #fddb00;
  color: black;
  font-size: 0.9em;

  &:hover {
    cursor: pointer;
    background-color: #fddb00;
  }
`;

const AlertWrapper = styled(UnreadCountWrapper)`
  background-color: #f04e44;
  color: white;
  z-index: 10000;
  padding-right: 10px;

  &:hover {
    cursor: unset;
  }

  &.fadeOut {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &.fadeIn {
    opacity: 1;
    transition: opacity 0.2s ease-in-out;
  }
`;

const ChatInputWrapper = styled.div`
  position: relative;
`;

const MessageWrapper = styled.div`
  display: block;
  flex-direction: column;
  height: calc(100% - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 13px 18px 0px 18px;

  @media screen and (max-width: 768px) {
    height: calc(100% - 50px);
    padding: 0px 13px 0px 13px;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    height: calc(100% - 50px);
    padding: 0px 13px 0px 13px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  bottom: 0px;
  font-size: 0.95em;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    height: 50%;
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    height: 50%;
  }
`;

export const LoadingSpinner = styled.div`
  display: inline-block;
  position: absolute;
  width: 50px;
  height: 50px;
  top: calc(50% - 20px);
  left: calc(50% - 20px);

  &:after {
    content: ' ';
    display: block;
    width: 25px;
    height: 25px;
    margin: 9px;
    border-radius: 50%;
    border: 6px solid #fddb00;
    border-color: #fddb00 transparent #fddb00 transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media screen and (max-width: 1024px) and (max-height: 1366px) and (orientation: portrait) {
    width: 45px;
    height: 45px;

    &:after {
      width: 17px;
      height: 17px;
      border-width: 4px;
      margin: 10px;
    }
  }

  @media screen and (max-width: 850px) and (max-height: 450px) {
    width: 45px;
    height: 45px;

    &:after {
      width: 17px;
      height: 17px;
      border-width: 4px;
      margin: 10px;
    }
  }
`;

const createConnectionHandler = (channel) => {
  const connectionManager = new SendBirdConnection();
  connectionManager.onReconnectStarted = () => {
    console.log('[SendBird JS SDK] Reconnect : Started');
    connectionManager.channel = channel;
  };
  connectionManager.onReconnectSucceeded = () => {
    console.log('[SendBird JS SDK] Reconnect : Succeeded');
  };
  connectionManager.onReconnectFailed = () => {
    console.log('[SendBird JS SDK] Reconnect : Failed');
    connectionManager.remove();
  };
};

const SendBirdChat = ({
  userId,
  nickName,
  userMetaData,
  isMobileMode,
  accessToken,
  channelUrl,
  isSelected,
  isChannelLoaded,
  handleChangeParticipantCount,
  handleChangeParticipantList,
  setIsMobileInputFocused,
  setIsChannelLoaded,
}) => {
  const [channel, setChannel] = useState({});
  const [, , setOpenChannel] = useOpenChannel();
  const [unreadMessageCount, _setUnreadMessageCount] = useState(0);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const messageEndRef = useRef();
  const messageListRef = useRef();
  const messageWrapperRef = useRef();
  const chatItemListRef = useRef();
  const unreadMessageCountRef = React.useRef(unreadMessageCount);
  const debounceTrackingScroll = useRef(
    debounce((scrollTop, scrollHeight, clientHeight) => {
      trackScrolling(scrollTop, scrollHeight, clientHeight);
    }, 200)
  ).current;

  // 메세지 리스트와 채팅에 렌더링 되는 아이템들을 구분함
  const [messageList, _setMessageList] = useState([]);
  const [chatItemList, _setChatItemList] = useState([]);

  const addMessage = useCallback((message, isPastMessage = false, isUserEvent = false) => {
    let currentScrollTop = messageWrapperRef.current.scrollHeight;

    if (isPastMessage) {
      setMessageList([...message, ...messageListRef.current]);
      setChatItemList([...message, ...chatItemListRef.current]);
      messageWrapperRef.current.scrollTop = messageWrapperRef.current.scrollHeight - currentScrollTop;
    } else {
      setMessageList([...messageListRef.current, message]);
      setChatItemList([...chatItemListRef.current, message]);
      setTimeout(() => {
        scrollToBottom(isUserEvent);
      }, 100);
    }
  }, []);

  const addEventHandler = useCallback(() => {
    const channelEvent = new SendBirdChatEvent();
    channelEvent.onMessageReceived = (eventChannel, message) => {
      if (channelUrl === eventChannel.url) {
        addMessage(message, false, false);

        if (
          messageWrapperRef.current.scrollHeight > messageWrapperRef.current.clientHeight &&
          messageWrapperRef.current.scrollTop <= messageWrapperRef.current.scrollHeight - 2 * messageWrapperRef.current.clientHeight
        ) {
          setUnreadMessageCount(unreadMessageCountRef.current + 1);
        } else {
          setUnreadMessageCount(0);
        }

        if (eventChannel && eventChannel.hasOwnProperty('participantCount')) {
          handleChangeParticipantCount(eventChannel.participantCount);
        }
      }
    };
    channelEvent.onMessageUpdated = (eventChannel, message) => {
      if (channelUrl === eventChannel.url) {
      }
    };
    channelEvent.onMessageDeleted = (eventChannel, messageId) => {
      if (channelUrl === eventChannel.url) {
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelUrl, addMessage]);

  const createChannelEvent = useCallback(async () => {
    const channelEvent = new SendBirdEvent();
    channelEvent.onChannelChanged = (channel) => {
      if (channel._autoMarkAsRead) {
        channel.markAsRead();
      }
    };
    channelEvent.onUserEntered = (openChannel, user) => {
      if (openChannel && openChannel.hasOwnProperty('participantCount')) {
        handleChangeParticipantCount(openChannel.participantCount);
        handleChangeParticipantList('entered', user);
      }
    };
    channelEvent.onUserExited = (openChannel, user) => {
      if (openChannel && openChannel.hasOwnProperty('participantCount')) {
        handleChangeParticipantCount(openChannel.participantCount);
        handleChangeParticipantList('exited', user);
      }
    };
    // openChannel as context
    const sbChannel = await sb.getChannel(channelUrl, true);
    setOpenChannel({ channel: sbChannel, eventHandler: channelEvent });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const connect = useCallback(async () => {
    try {
      var metaData = {
        job: userMetaData.job,
        company: userMetaData.company,
        position: userMetaData.position,
        industry: userMetaData.industry,
      };
      await sb.connect(userId, nickName, accessToken);
      // await sb.createUserMetaData(metaData);
      createConnectionHandler();
      createChannelEvent();
      addEventHandler();

      const sbChannel = await sb.getChannel(channelUrl, true);
      setChannel(sbChannel);
      await sb.enter(channelUrl);

      const messageList = await sb.getMessageList(sbChannel, true);
      setMessageList(messageList);
      setChatItemList(messageList);
      setIsChannelLoaded(true);

      scrollToBottom(false, true);
    } catch (error) {
      console.log(error);
    }
  }, [
    userId,
    nickName,
    userMetaData.job,
    userMetaData.company,
    userMetaData.position,
    userMetaData.industry,
    channelUrl,
    accessToken,
    setIsChannelLoaded,
    createChannelEvent,
    addEventHandler,
  ]);

  const disconnect = useCallback(async () => {
    const sbChannel = await sb.getChannel(channelUrl, true);
    sbChannel.exit(async function (response, error) {
      if (error) {
        console.log('=== disconnect error', error);
        return;
      }
    });
  }, [channelUrl]);

  useEffect(() => {
    if (isSelected) {
      scrollToBottom(false, true);
    }
    return () => {};
  }, [isSelected]);

  useEffect(() => {
    if (userId) {
      connect();
    }
  }, [userId, connect]);

  useEffect(() => {
    return () => {
      disconnect();
    };
  }, [disconnect]);

  const setMessageList = (data) => {
    messageListRef.current = data;
    _setMessageList(data);
  };

  const setChatItemList = (data) => {
    chatItemListRef.current = data;
    _setChatItemList(data);
  };

  const setUnreadMessageCount = (data) => {
    unreadMessageCountRef.current = data;
    _setUnreadMessageCount(data);
  };

  const showChatAlert = (message) => {
    setAlertMessage(message);
    setIsShowAlert(true);

    setTimeout(() => {
      setIsShowAlert(false);
    }, 3000);
  };

  const scrollToBottom = (isUserEvent = false, isSelected = false) => {
    const wrapperElement = messageWrapperRef.current;

    if (
      isUserEvent ||
      isSelected ||
      wrapperElement.scrollTop > wrapperElement.scrollHeight - 2 * wrapperElement.clientHeight // - HEIGHT_FROM_BOTTOM
    ) {
      messageEndRef.current.scrollIntoView(false);
    }
  };

  const trackScrolling = async (scrollTop, scrollHeight, clientHeight) => {
    // TODO: Javascript float point 이슈
    const SCROLL_DIFF = 2;

    // 채팅창 스크롤이 좀 생겼을 때 + 스크롤이 맨 위로 올라갔을 때
    if (scrollTop === 0 && scrollHeight > clientHeight * 2) {
      const messageList = await sb.getMessageList(channel);
      addMessage(messageList, messageList.length > 0, false);
    }

    // 스크롤이 맨 밑으로 갔을 때
    if (scrollHeight < clientHeight || scrollTop + clientHeight + SCROLL_DIFF > scrollHeight) {
      setUnreadMessageCount(0);
    }
  };

  return (
    <Wrapper>
      <MessageWrapper
        ref={messageWrapperRef}
        onScroll={(e) => debounceTrackingScroll(e.target.scrollTop, e.target.scrollHeight, e.target.clientHeight)}
      >
        {!isChannelLoaded && <LoadingSpinner />}
        {chatItemList &&
          chatItemList.length > 0 &&
          chatItemList.map(
            (item, index) =>
              item.hasOwnProperty('messageType') && <ChatMessage isMobileMode={isMobileMode} message={item} key={index} index={index} />
          )}
        <div ref={messageEndRef}></div>
      </MessageWrapper>
      <ChatInputWrapper>
        {unreadMessageCount > 0 && (
          <UnreadCountWrapper onClick={() => messageEndRef.current.scrollIntoView(false)}>
            <StyledArrowIcon /> {unreadMessageCount}개의 새 메세지가 있습니다
          </UnreadCountWrapper>
        )}
        <AlertWrapper className={isShowAlert ? 'fadeIn' : 'fadeOut'}>
          {alertMessage} <StyledCloseIcon onClick={() => setIsShowAlert(false)} />
        </AlertWrapper>
        <ChatInput
          channel={channel}
          channelUrl={channelUrl}
          addMessage={addMessage}
          showChatAlert={showChatAlert}
          setIsMobileInputFocused={setIsMobileInputFocused}
          isMobileMode={isMobileMode}
        />
      </ChatInputWrapper>
    </Wrapper>
  );
};
class WebinarChat extends Component {
  render() {
    const {
      userinfo,
      channelUrl,
      isSelected,
      isChannelLoaded,
      setIsMobileInputFocused,
      setIsChannelLoaded,
      handleChangeParticipantCount,
      handleChangeParticipantList,
      isMobileMode,
    } = this.props;
    const { id, username, sendbird_access_token, company, job, position, industry } = userinfo;

    if (!id) {
      return <></>;
    }

    const userId = id.toString();

    return (
      <SendBirdChat
        userId={userId}
        nickName={username}
        accessToken={sendbird_access_token}
        channelUrl={channelUrl}
        isSelected={isSelected}
        isMobileMode={isMobileMode}
        isChannelLoaded={isChannelLoaded}
        setIsMobileInputFocused={setIsMobileInputFocused}
        setIsChannelLoaded={setIsChannelLoaded}
        handleChangeParticipantCount={handleChangeParticipantCount}
        handleChangeParticipantList={handleChangeParticipantList}
        userMetaData={{ job, company, position, industry }}
      />
    );
  }
}

export default useContext(WebinarChat);
