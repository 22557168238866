import React, { useRef, useEffect, useState, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';
import CancelIcon from '@material-ui/icons/Cancel';
import qs from 'query-string';
import { SearchBarContext } from 'context/searchBar';
import { styles } from '@heyjoyce/vibe';
import { v4 as uuid } from 'uuid';
import SearchRecord from './SearchRecord';

const fadeAnimation = css`
  &.fadeOut {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 300ms, opacity 100ms;
  }

  &.fadeIn {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 100ms;
  }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 99998;
  ${fadeAnimation}
`;

const Wrapper = styled.div`
  position: fixed;
  width: calc(100%);

  display: flex;
  flex-direction: column;
  z-index: 99999;
  background-color: white;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  border-bottom: 1px solid #ebebeb;
  ${fadeAnimation}

  @media screen and (max-width: 925px) {
    width: 100%;
    padding: 0px 15px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 925px;
  height: 60px;
  margin: 0 auto;
  border-bottom: 1px solid ${styles.color.mediumlight};
  @media screen and (max-width: 925px) {
    width: 100%;
  }
`;

const SearchInput = styled.input`
  width: 100%;
  font-size: 16px;
  border: none;
  color: black;
  padding: 10px 0px;
  margin-top: 1px;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: gray;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  margin-right: 15px;

  @media screen and (max-width: 768px) {
    margin-right: 10px;
  }
`;

const StyledCancelIcon = styled(CancelIcon)`
  font-size: 1.5em;
  color: #bbbbbb;
`;

const ResetButton = styled.button`
  background-color: transparent;
  border: none;
  padding: 10px;
  margin-top: 4px;

  @media screen and (max-width: 768px) {
    padding: 3px;
  }
`;

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  word-break: keep-all;
  padding: 10px 15px 10px 10px;
  font-weight: bold;
  font-size: 0.9em;
  margin-top: 1px;
  color: gray;
  margin-right: -15px;

  @media screen and (max-width: 768px) {
    padding: 10px 7px 10px 12px;
    margin-top: 1px;
    margin-right: 0px;
  }
`;

const SearchBar = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { query } = qs.parse(location.search);

  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState('');
  const [isResultPage, setIsResultPage] = useState(false);
  const [record, setRecord] = useState([]);

  const { isShowSearchBar, setIsShowSearchBarHandler } = useContext(SearchBarContext);

  useEffect(() => {
    if (localStorage.getItem('searchRecord') !== null) {
      setRecord(JSON.parse(localStorage.getItem('searchRecord')));
    }
  }, [isShowSearchBar]);

  useEffect(() => {
    if (isShowSearchBar === true) {
      inputRef.current.focus();
    }
  }, [isShowSearchBar]);

  useEffect(() => {
    setIsShowSearchBarHandler(false);

    if (location.pathname.includes('discovery')) {
      setIsResultPage(true);
      setInputValue(query);
    } else {
      setIsResultPage(false);
      setInputValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleSearch = () => {
    if (inputValue === '') {
      return;
    }

    const searchRecord = JSON.parse(localStorage.getItem('searchRecord'));
    if (searchRecord === null || searchRecord.length === 0) {
      const newSearchRecord = [{ searchKeyword: inputValue, id: uuid() }];
      localStorage.setItem('searchRecord', JSON.stringify(newSearchRecord));
    } else {
      const newSearchRecord = [
        ...searchRecord.filter((item) => item.searchKeyword !== inputValue),
        { searchKeyword: inputValue, id: uuid() },
      ];
      if (newSearchRecord?.length > 5) {
        newSearchRecord.shift();
      }
      localStorage.setItem('searchRecord', JSON.stringify(newSearchRecord));
    }
    setRecord(JSON.parse(localStorage.getItem('searchRecord')));
    history.push(`/discovery?query=${inputValue}`);
  };

  const handleChangeInput = (value) => {
    setInputValue(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    } else if (e.key === 'Escape') {
      handleClickClose();
    }
  };

  const handleClickReset = () => {
    setInputValue('');
  };

  const handleClickClose = () => {
    if (!location.pathname.includes('discovery')) {
      handleClickReset();
    }
    setIsShowSearchBarHandler(false);
  };

  return (
    <>
      <Wrapper className={isShowSearchBar ? 'fadeIn' : 'fadeOut'}>
        <Content>
          <StyledSearchIcon />
          <SearchInput
            ref={inputRef}
            value={inputValue}
            type="text"
            onChange={(e) => handleChangeInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="검색어를 입력해주세요"
          />
          {inputValue !== '' && (
            <ResetButton onClick={handleClickReset}>
              <StyledCancelIcon />
            </ResetButton>
          )}
          <CloseButton onClick={handleClickClose}>취소</CloseButton>
        </Content>
        <SearchRecord record={record} setRecord={setRecord} />
      </Wrapper>

      <Background onClick={handleClickClose} className={isShowSearchBar ? 'fadeIn' : 'fadeOut'} />
    </>
  );
};

export default SearchBar;
