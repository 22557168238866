import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid, Paper, Typography } from '@material-ui/core/';
import AccessTime from '@material-ui/icons/AccessTime';
import AssignmentTurnedIn from '@material-ui/icons/AssignmentTurnedIn';
import Header from 'block/Header';
import { EVENTS, LinkGA } from 'block/LinkGA';
import LoungeImg from '../../images/loungeImg.jpg';
import LoungeImg2 from '../../images/loungeImg2.jpg';
import LibraryImg from '../../images/libraryImg.jpg';
import ImgMeetingRoom from '../../images/meeting-room.jpg';
import ImgYogaRoom from '../../images/yoga-room.jpg';
import './Space.scss';

function Space() {
  return (
    <main className="sectionSpace">
      <Header
        title="헤이조이스 - 아지트"
        description="리모트 워크, 휴식, 독서, 요가까지. 나만의 시간을 선물해 줄 멤버들만을 위한 공간입니다."
      />
      <div className="menuHeader" justify="center">
        <h1>아지트</h1>
        <Typography variant="body1" align="center">
          리모트 워크, 휴식, 독서, 요가까지. <br />
          나만의 시간을 선물해 줄 멤버들만을 위한 공간입니다.
        </Typography>
      </div>

      <LinkGA
        path="/membership"
        event={{
          ...EVENTS.membership01,
          label: '아지트',
        }}
      ></LinkGA>

      <Paper className="imgWrap">
        <Paper className="timeSection">
          <Grid container>
            <Grid item xs={12} md={7}>
              <img src={LoungeImg} alt="" />
            </Grid>
            <Grid item xs={12} md={5}>
              <div className="timeContent">
                <h2>멤버스 라운지</h2>

                <div className="worktime">
                  {' '}
                  <AccessTime />
                  운영 시간
                </div>
                <ul>
                  <li>
                    <b>화 - 금</b> <br />
                    11:00 AM – 11:00 PM
                  </li>
                  <li>
                    <b>토 - 일</b> <br />
                    11:00 AM – 07:00 PM
                  </li>
                  <li>
                    <b>월</b> <br />
                    휴무
                  </li>
                </ul>
                <div>
                  <small>
                    월요일 대관을 원할 경우 <Link to="/policy/faq">FAQ</Link>를 참고해주세요.
                  </small>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <img src={LoungeImg2} alt="" />
            <div>아지트</div>
          </Grid>
          <Grid item xs={12} md={6}>
            <img src={LibraryImg} alt="" />
            <div>라이브러리</div>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <img src={ImgMeetingRoom} alt="미팅 룸" />
            <div>미팅 룸</div>
          </Grid>
          <Grid item xs={12} md={6} lg={6}>
            <img src={ImgYogaRoom} alt="요가 스튜디오" />
            <div>요가 스튜디오</div>
          </Grid>
        </Grid>
        {/* Map */}
        <iframe
          title="azit_map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12660.177675302652!2d127.0467831!3d37.5068705!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x2d1508e84fe567bf!2z7Zek7J207KGw7J207IqkIEhleUpveWNl!5e0!3m2!1sko!2skr!4v1571060515073!5m2!1sko!2skr"
          width="100%"
          height="450"
          frameBorder="0"
          allowFullScreen=""
        ></iframe>
      </Paper>
    </main>
  );
}
export default Space;
