import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { USER_LEVELS } from 'context/main';
import useCategory from 'hooks/useCategory';
import { EVENTS } from 'block/LinkGA';
import { Newsletter, BannerButton, BannerInvitation } from 'block/Banner';
import { usePromotionModal } from 'hooks/usePromotionModal';
import ProgramFlicking from 'container/Program/ProgramFlicking';
import QnaFlicking from 'container/QnA/QnaFlicking';
import MainBannerContainer from './MainBannerContainer';
import WelcomeThread from './WelcomeThread';
import TopMenu from './TopMenu';
import VerticalMenu from './VerticalMenu';
import Profile from './Profile';
import { SectionListFreeContainer, SectionListScoredContainer, SectionListInterestedContainer } from './Section';
import './Main.scss';

const Main = ({ loading, userinfo }) => {
  const categories = useCategory();
  const [isNewMember, setIsNewMember] = useState(false);
  const { level = USER_LEVELS.guest } = userinfo;
  const history = useHistory();

  const [isLoggedIn, isNewbie] = usePromotionModal();

  useEffect(() => {
    // welcome thread
    if (isLoggedIn && isNewbie && !localStorage.getItem('@main-welcome-thread')) {
      setIsNewMember(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleClickCloseWelcomeThread = () => {
    localStorage.setItem('@main-welcome-thread', true);
    setIsNewMember(false);
  };

  return (
    <main role="main" aria-label="헤이조이스 메인">
      <TopMenu />
      <section className="homeContainer">
        <div className="scrollComponent">
          <MainBannerContainer type={'MAIN_TOP'} level={level} />
          <div>
            <VerticalMenu />
            {/** 신규유입 **/}
            {!loading && USER_LEVELS.guest === level && (
              <div className="guestContainer">
                <>
                  <Newsletter
                    handleSubscribe={() => {
                      ReactGA.event(EVENTS.main03);
                      return history.push('/join');
                    }}
                    handlePastHistory={() => {
                      ReactGA.event(EVENTS.main04);
                      return history.push('/blog/11b4a5be-5789-4f50-93b8-c170fc582503');
                    }}
                    title="나답게 사는 여자들의 이야기!"
                    desc="한 달에 2번, 헤이조이스 뉴스레터에서 만나 보세요."
                  >
                    구독신청
                  </Newsletter>
                </>
              </div>
            )}
            {/** 신규멤버 **/}
            {!loading && USER_LEVELS.guest !== level && isNewMember && <WelcomeThread handleClickClose={handleClickCloseWelcomeThread} />}
            {/** 가입 후 30일이 지난 멤버 **/}
            {!loading && USER_LEVELS.guest !== level && !isNewMember && <Profile />}
          </div>
          {categories && <QnaFlicking categories={categories} title={`커리어상담`} desc="멤버들의 커리어상담 구경하기" type="event" />}
          {/** ------------------------------- 프로그램 영역 ------------------------------- **/}
          {/** 로그인하지 않은 신규 유저를 대상으로 한다 **/}
          {!loading && USER_LEVELS.guest === level && <SectionListFreeContainer />}
          {/** 로그인한 멤버 대상으로 상품을 추천한다 **/}
          {!loading && USER_LEVELS.guest !== level && <SectionListScoredContainer />}
          {!loading && <MainBannerContainer type={'MAIN_BOTTOM'} level={level} />}
          <ProgramFlicking title="라이브" desc="퇴근 후, 경험의 폭을 한 뼘 더 넓히는 시간" type="event" />
          <ProgramFlicking title="VOD" desc="놓쳐서 아쉬웠던 프로그램! 언제든 다시보기" type="digitalcontents" />
          {/** 로그인한 멤버 대상으로 상품을 추천한다 **/}
          {!loading && categories && USER_LEVELS.guest !== level && <SectionListInterestedContainer categories={categories} />}
          {/*<ProgramFlicking title="모임" desc="스터디와 네트워킹, 우리는 모일수록 잘한다!" type="moim" />*/}
          <div className="lastContent">
            {[USER_LEVELS.guest].includes(level) ? (
              <BannerButton
                onClick={() => {
                  ReactGA.event({
                    ...EVENTS.signup01,
                    label: 'banner',
                  });
                  return history.push('/join');
                }}
                title="홈페이지 회원가입만 해도 1만원 할인!"
                desc="1분 만에 할인 쿠폰 받으러 가기"
              >
                쿠폰 받기
              </BannerButton>
            ) : (
              <BannerInvitation />
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Main;
