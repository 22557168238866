import React from 'react';
import { Document, Page, Text, View, Image, Font } from '@react-pdf/renderer';
import styled from '@react-pdf/styled-components';
import SpoqaHanSansNeo from '../../assets/fonts/SpoqaHanSansNeo-Light.ttf';
import SpoqaHanSansNeoRegular from '../../assets/fonts/SpoqaHanSansNeo-Regular.ttf';
import SpoqaHanSansNeoMedium from '../../assets/fonts/SpoqaHanSansNeo-Medium.ttf';
import LogoImage from '../../images/logo_heyjoyce_yellow.png';
import StampImage from '../../images/heyjoyce_stamp.JPG';
import { getStringDate, priceHelper } from 'helpers';

Font.register({
  family: 'SpoqaHanSansNeo',
  src: SpoqaHanSansNeo,
});

Font.register({
  family: 'SpoqaHanSansNeoRegular',
  src: SpoqaHanSansNeoRegular,
});

Font.register({
  family: 'SpoqaHanSansNeoMedium',
  src: SpoqaHanSansNeoMedium,
});

const Wrapper = styled.Page`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const Card = styled.View`
  display: flex;
  align-items: center;
  width: 90%;
  height: 50%;
  padding: 30px;
  border: 3px solid #fddb00;
  font-family: 'SpoqaHanSansNeo';
  position: relative;
`;

const HeaderText = styled.Text`
  font-size: 21px;
  font-weight: bold;
  width: 100%;
  font-family: 'SpoqaHanSansNeoMedium';
  padding: 4px 5px;
  color: black;
`;

const HeaderImage = styled.Image`
  position: absolute;
  width: 110px;
  height: 30px;
  top: 29px;
  right: 40px;
`;

const Table = styled.View`
  display: flex;
  width: 100%;
  border: 1px solid #dedede;
  margin-top: 15px;
  font-size: 14px;
`;

const Tr = styled.View`
  display: flex;
  flex-direction: row;
  border: 1px solid #dedede;
  border-left: none;
  border-top: none;
  border-right: none;
`;

const Th = styled.View`
  display: flex;
  width: 90px;
  background-color: #fffef7;
  text-align: center;
  padding: 10px;
  border: 1px solid #dedede;
  border-bottom: none;
  border-top: none;
`;

const TitleText = styled.Text`
  color: #404040;
  font-weight: bold;
`;

const SignatureWrapper = styled.View`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
`;

const SignatureText = styled.Text`
  position: absolute;
  font-size: 14px;
  font-family: 'SpoqaHanSansNeoRegular';
`;

const SignatureImage = styled.Image`
  position: absolute;
  width: 50px;
  height: 40px;
  z-index: -1;
  bottom: -10px;
  right: -15px;
`;

const FooterText = styled.Text`
  margin-top: 20px;
  font-size: 14px;
  color: gray;
  font-family: 'SpoqaHanSansNeoRegular';
`;

const DateText = styled.Text`
  margin-top: 20px;
  font-size: 16px;
`;

function makeTimeDouble(number) {
  return (number < 10 ? '0' : '') + number;
}

function getMeetDatetime(startAt, endAt) {
  var startDate = new Date(startAt);
  var endDate = new Date(endAt);
  var year = startDate.getFullYear();
  var month = startDate.getMonth() + 1;
  var day = startDate.getDate();
  var startHour = makeTimeDouble(startDate.getHours());
  var startMinute = makeTimeDouble(startDate.getMinutes());
  var endHour = makeTimeDouble(endDate.getHours());
  var endMinute = makeTimeDouble(endDate.getMinutes());

  return `${year}년 ${month}월 ${day}일 ${startHour}시 ${startMinute}분 ~ ${endHour}시 ${endMinute}분`;
}

// Create Document Component
const ProgramCertificate = ({ userinfo, item }) => {
  const { username, email } = userinfo;
  const { program_name, meets, order } = item;

  const today = new Date();

  return (
    <Document title="프로그램 참여증" author="헤이조이스" keywords="" subject="">
      <Wrapper size="A4">
        <Card>
          <HeaderText>헤이조이스 프로그램 참여증</HeaderText>
          <HeaderImage src={LogoImage} />
          <Table>
            <Tr>
              <Th>
                <TitleText>이름</TitleText>
              </Th>
              <View
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px 8px' }}
                render={() => <Text>{username}</Text>}
              />
              <Th>
                <TitleText>홈페이지 ID</TitleText>
              </Th>
              <View
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px 8px' }}
                render={() => <Text>{email}</Text>}
              />
            </Tr>
            <Tr>
              <Th>
                <TitleText>프로그램</TitleText>
              </Th>
              <View
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'center',
                  padding: '0px 8px',
                  width: '80%',
                }}
                render={() => <Text>{program_name}</Text>}
              />
            </Tr>
            <Tr>
              <Th>
                <TitleText>일시</TitleText>
              </Th>
              <View
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px 8px' }}
                render={() => (
                  <View>
                    {meets.map((meet) => {
                      const endDate = new Date(meet.end_at);
                      return today > endDate ? <Text>{getMeetDatetime(meet.start_at, meet.end_at)}</Text> : <></>;
                    })}
                  </View>
                )}
              />
            </Tr>
            {/* <Tr>
              <Th>
                <TitleText>장소</TitleText>
              </Th>
              <Td></Td>
            </Tr> */}
            <Tr style={{ borderBottom: 'none' }}>
              <Th>
                <TitleText>금액</TitleText>
              </Th>
              <View
                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0px 8px' }}
                render={() => <Text>{priceHelper(order.price)} 원</Text>}
              />
            </Tr>
          </Table>
          <FooterText>위 멤버는 (주)플래너리가 운영하는 헤이조이스 프로그램에 참여하였음을 확인합니다.</FooterText>
          <View
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 30 }}
            render={() => <Text style={{ fontSize: 15, fontFamily: 'SpoqaHanSansNeoRegular' }}>{getStringDate(today)}</Text>}
          />
          <SignatureWrapper>
            <SignatureImage src={StampImage} />
            <SignatureText>(주)플래너리 (인)</SignatureText>
          </SignatureWrapper>
        </Card>
      </Wrapper>
    </Document>
  );
};

export default ProgramCertificate;
