
import React, { useState} from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { CardActions, IconButton } from '@material-ui/core';
import { Favorite as FavoriteIcon, FavoriteBorder as FavoriteBorderIcon } from '@material-ui/icons';
import Typography from 'block/Typography';
import { useSessionContext } from 'context/main';
import { REACTION_CONTENT, SUBJECT_TYPE, useReaction } from 'hooks/useReaction';

const Wrapper = styled(CardActions)`
  margin-top: 6px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 3px 0px 0px !important;
`;

const StyledIconButton = styled(IconButton)`
  padding: 8px 8px 8px 2px !important;
`;

const FeedActions = ({ id, reactions }) => {
  const history = useHistory();
  const [, userinfo] = useSessionContext();
  const { addReaction, removeReaction } = useReaction(SUBJECT_TYPE.feed, id);

  const [reaction, setReaction] = useState({
    viewerHasReacted: reactions.viewer_has_reacted,
    total: reactions.total,
  });

  const handleToggleLike = async () => {
    if (userinfo.level === 'guest') {
      return history.push('/login');
    }
    const toggle = reaction.viewerHasReacted ? removeReaction : addReaction;
    setReaction(prev => ({
      ...prev,
      total: prev.viewerHasReacted ? prev.total - 1 : prev.total + 1,
      viewerHasReacted: !prev.viewerHasReacted,
    }));
    return toggle({ id, content: REACTION_CONTENT.heart });
  };

  return (
    <Wrapper>
      <StyledIconButton onClick={() => handleToggleLike({ id, content: REACTION_CONTENT.heart })} aria-label="좋아요">
        {reaction.viewerHasReacted ? <FavoriteIcon style={{ fill: '#f04e44' }} /> : <FavoriteBorderIcon style={{ fill: '#999'}} />}
      </StyledIconButton>
      <Typography variant='body1' gutterBottom><b>{reaction.total}명</b>이 좋아합니다</Typography>
    </Wrapper>
  )
}

export default FeedActions;